import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectSettings
} from 'store/user';
import {
    UserSettings
} from 'model/index';
import {
    SettingTwoTone
} from '@ant-design/icons';

import { Button } from 'antd';
import { useAppState } from 'components/layout/AppStateProvider';

export const TimeZone: React.FC = () => {
    const userSettings: UserSettings = useAppSelector(selectSettings);
    const { showSettings } = useAppState();
    return (
        <Button onClick={showSettings} type="link"><SettingTwoTone /> {userSettings.timeZoneLabel}</Button>
    );
};
