import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MailTwoTone, MessageTwoTone, InfoCircleTwoTone, ClockCircleTwoTone, ShoppingTwoTone, ShopTwoTone } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import {
    Vendor,
    Broker,
    VirtualBooth,
    Company,
    AnalyticsProps,
    MessengerSession,
    Customer,
    AnalyticsType,
    OrderOption,
    User,
} from 'model';
import { useLocation } from 'react-router-dom'
import {
    selectOrderOptions,
    selectCustomer,
    filterVendor,
    filterBroker,
    selectVirtualBooths,
    filterBooth,
    resetAllFilters
} from 'store/order';
import {
    selectUser
} from 'store/user';
import {
    createSessionAsync
} from 'store/messenger';
import { useAppState } from 'components/layout/AppStateProvider';
import history from 'store/history';

interface Props {
    manufacturer: Vendor | Broker,
    showInfo: boolean
}

const CommunicationComponent: React.FC<Props> = (props) => {

    //console.log(props.manufacturer);

    const dispatch = useAppDispatch();

    const { showCompanyInfo, email, logAnalytics } = useAppState();

    const virtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const customer: Customer = useAppSelector(selectCustomer);

    const user: User = useAppSelector(selectUser);

    const location = useLocation();

    const activeSessionWarning = (messengerSession: MessengerSession) => {
        return Modal.confirm({
            title: 'Confirmation',
            content: 'You are leaving the video chat, please confirm.',
            onOk: () => {
                //console.log("OK");
                window.dispatchEvent(new Event('disconnectFromRoom'));
                dispatch(createSessionAsync(user.role, messengerSession));
            },
            onCancel: () => {
                //console.log("CANCEL");
            }
        });
    }

    const messageOnClick = () => {

        let analyticsId: string = "";

        let messengerSession: MessengerSession = null;

        let vendor = props.manufacturer as Vendor;
        if (vendor.vendorId) {
            messengerSession = {
                customerId: customer.customerId,
                vendorId: vendor.vendorId
            };
        } else {
            let broker = props.manufacturer as Broker;
            messengerSession = {
                customerId: customer.customerId,
                vendorId: broker.brokerId
            };
        }
        if (globalThis.twilioRoom != undefined) {
            activeSessionWarning(messengerSession);
        } else {
            window.dispatchEvent(new Event('disconnectFromRoom'));
            dispatch(createSessionAsync(user.role, messengerSession));
        }

        let analytics: AnalyticsProps = {
            id: messengerSession.vendorId,
            analyticsType: AnalyticsType.Message
        };
        logAnalytics(analytics);
    };

    const emailOnClick = () => {
        const sender: Company = {
            id: user.id,
            name: user.company
        };
        let recipient: Company = {};
        let vendor = props.manufacturer as Vendor;
        if (vendor.vendorId) {
            recipient = {
                id: vendor.vendorId,
                name: vendor.vendorName
            }
        } else {
            let broker = props.manufacturer as Broker;
            recipient = {
                id: broker.brokerId,
                name: broker.brokerName
            }
        }
        email(sender, recipient);
    };

    const meetingOnClick = () => {
        let vendor = props.manufacturer as Vendor;
        if (vendor.vendorId) {
            const vendorBooth = virtualBooths.find((booth: VirtualBooth) => {
                return booth.entityId === vendor.vendorId;
            });
            if (vendorBooth) {
                dispatch(filterBooth(vendorBooth));
                history.push('/schedule');
            } else {
                const brokerBooth = virtualBooths.find((booth: VirtualBooth) => {
                    return booth.entityId === vendor.brokerId;
                });
                if (brokerBooth) {
                    dispatch(filterBooth(brokerBooth));
                    history.push('/schedule');
                }
            }
        } else {
            let broker = props.manufacturer as Broker;
            dispatch(filterBroker(broker));
            history.push('/schedule');
        }
    };

    const informationOnClick = () => {
        let vendor = props.manufacturer as Vendor;
        if (vendor.vendorId) {
            showCompanyInfo(vendor.vendorId);
        } else {
            let broker = props.manufacturer as Broker;
            showCompanyInfo(broker.brokerId);
        }
    };

    const boothOnClick = () => {
        let vendor = props.manufacturer as Vendor;
        if (vendor.vendorId) {

            if (orderOptions.hostVendorId == vendor.vendorId) {
                history.push("/host-booth");
            } else {
                const vendorBooth = virtualBooths.find((booth: VirtualBooth) => {
                    return booth.entityId === vendor.vendorId;
                });

                if (vendorBooth) {
                    //dispatch(filterVendor(vendor));
                    history.push(`/booth/${vendorBooth.entityId}`);
                } else {
                    const brokerBooth = virtualBooths.find((booth: VirtualBooth) => {
                        return booth.entityId === vendor.brokerId;
                    });
                    if (brokerBooth) {
                        //dispatch(filterBroker(vendor));
                        history.push(`/booth/${brokerBooth.entityId}`);
                    }
                }
            }
        } else {
            let broker = props.manufacturer as Broker;
            dispatch(filterBroker(broker));

            if (orderOptions.hostVendorId == broker.brokerId) {
                history.push("/host-booth");
            } else {
                history.push(`/booth/${broker.brokerId}`);
            }
        }
    };

    const orderOnClick = () => {
        dispatch(resetAllFilters());

        let vendor = props.manufacturer as Vendor;
        if (vendor.vendorId) {
            dispatch(filterVendor(vendor));
        } else {
            let broker = props.manufacturer as Broker;
            dispatch(filterBroker(broker));
        }

        history.push('/order');
    };

    const virtualBooth = (): boolean => {
        if (orderOptions.scheduleEnabled) {
            let vendor = props.manufacturer as Vendor;
            if (vendor.vendorId) {
                if (virtualBooths.some(booth => booth.entityId === vendor.vendorId || booth.entityId === vendor.brokerId)) {
                    return true;
                }
            } else {
                let broker = props.manufacturer as Broker;
                if (virtualBooths.some(booth => booth.entityId === broker.brokerId)) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <div className="vendor-controls">
            <Button type="link" icon={<ShoppingTwoTone style={{ fontSize: 24 }} />} onClick={orderOnClick}> Order</Button>
            {orderOptions.messageEnabled &&
                <Button type="link" icon={<MessageTwoTone style={{ fontSize: 24 }} />} onClick={messageOnClick}> Send Message</Button>
            }

            {orderOptions.customerEmailToVendorEnabled &&
                <Button type="link" icon={<MailTwoTone style={{ fontSize: 24 }} />} onClick={emailOnClick}> Email</Button>
            }
            {orderOptions.customerVendorInfoEnabled &&
                <Button type="link" icon={<InfoCircleTwoTone style={{ fontSize: 24 }} />} onClick={informationOnClick}> Info</Button>
            }

            {virtualBooth() &&
                <React.Fragment>
                    {location.pathname != "/schedule" &&
                        <Button type="link" icon={<ClockCircleTwoTone style={{ fontSize: 24 }} />} onClick={meetingOnClick}> Schedule Meeting</Button>
                    }
                    <Button type="link" icon={<ShopTwoTone style={{ fontSize: 24 }} />} onClick={boothOnClick}> View Booth</Button>
                </React.Fragment>
            }
        </div>
    );
}

export const Communication = React.memo(CommunicationComponent);


//import whyDidYouRender from "@welldone-software/why-did-you-render";
//whyDidYouRender(React, {
//    onlyLogs: true,
//    titleColor: "green",
//    diffNameColor: "aqua"
//});
