import React, { useEffect, useState } from 'react';
import {
    ColumnSettings,
    CatalogOrder,
    OrderMode,
    OrderItem
} from 'model/index';
interface Props {
    //items: any[],
    item: any,
    //index: number,
    onClick(item: any): void,
    orderColumns: ColumnSettings[],
    currentItemData: any,
    isScrolling: boolean,
    isAlternate: boolean,
    orderMode: OrderMode,
    submitOrder(orderItems: CatalogOrder[]): void,
    currentItemStatus: OrderItem
}
import clsx from 'clsx';
import styles from './ItemRow.module.css';
import { OrderRow } from './OrderRow';
//import { formatMoney } from 'utility/utility';
import { Column } from './Column';
//import { AxiosPromise } from 'axios';

export const ItemRow: React.FC<Props> = (props) => {

    const [totalState, setTotalState] = useState<number>(props.item.TotalQuantity);

    const [orderItemState, setOrderItemState] = useState<OrderItem>(null);

    //useEffect(() => {
    //    return function cleanup() {
    //        //console.log("CLEANUP Index:", props.index, " Item:", props.item);
    //    }
    //}, []);

    const onDoneClick = () => {
        props.onClick(props.item);
    };

    const rowCss = clsx({
        [styles.even]: props.isAlternate,
        [styles.row]: true,
        [styles.selected]: props.orderMode == OrderMode.Store && props.currentItemData && props.currentItemData.CatalogId == props.item.CatalogId
    });

    useEffect(() => {

        setTotalState(props.item.TotalQuantity);


    }, [props.item]);

    useEffect(() => {

        if (props.currentItemStatus && props.currentItemStatus.catalogId == props.item.CatalogId) {
            //console.log("currentItemStatus:", props.currentItemStatus);
            let orderItem: OrderItem = props.currentItemStatus;
            if (orderItem.quantity && orderItem.quantity.length > 0) {
                let total: number = 0;
                orderItem.quantity.forEach(row => {
                    for (const [key, value] of Object.entries(row)) {
                        if (key != "CustomerId" && value) {
                            total += Number(value);
                        }
                    };
                });
                setTotalState(total);
            }
            setOrderItemState(orderItem);
        } else {
            //setOrderItemState(null);
        }
    }, [props.currentItemStatus]);

    const ShipperRow = () => {
        if (props.item.ShipperContent && props.item.ShipperContent.length > 0) {
            const shipperCss = clsx({
                [styles.shipper]: true,
                [styles.even]: props.isAlternate,
                [styles.row]: true,
                [styles.selected]: props.orderMode == OrderMode.Store && props.currentItemData && props.currentItemData.CatalogId == props.item.CatalogId
            });
            return <div className={shipperCss} >
                {props.item.ShipperContent.split("\n").map(function (item, idx) {
                    return <span key={idx}>{item}<br /></span>;
                })
                }
            </div>;
        }
        return null;
    }

    return <div className={rowCss}>
        <div className={styles.columns} >
            <div className={styles.quantity} onClick={() => props.onClick(props.item)}>
                <div className={styles.orderquantity}>
                    {totalState > 0 ? totalState : ''}
                </div>
            </div>
            {
                props.orderColumns.map((column, index) => {
                    const css = clsx({
                        [styles.x2]: column.data == "Description",
                        [styles.itemno]: column.data == "ItemNo",
                        [styles.history]: column.data == "History",
                        [styles.center]: column.data == "HistoryAverage",
                        [styles.generic]: true
                    });
                    //title={`${column.title}:${columnValue}`}
                    return <Column
                        key={index}
                        css={css}
                        column={column}
                        item={props.item}
                    />;

                })
            }
        </div>
        {(props.orderMode == OrderMode.Store && props.currentItemData && props.currentItemData.CatalogId == props.item.CatalogId && orderItemState) &&
            <OrderRow
                item={props.item}
                onDoneClick={onDoneClick}
                submitOrder={props.submitOrder}
                orderItem={orderItemState}
            />
        }
        {ShipperRow()}
    </div>;
}

