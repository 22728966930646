import 'utility/config';
import React, { Suspense, lazy } from 'react';
import { Route } from "react-router-dom";

//import { TestPage } from 'components/test/Test';
import './style/custom.css'
import CacheBuster from 'CacheBuster';
import { useIdleTimer } from 'react-idle-timer';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
    LoginRequest,
    User
} from 'model';
import {
    selectUserAuthenticated,
    logout,
    selectUser,
    setRedirect,
    selectRedirect,
    getUser
} from 'store/user';
import {
    heartbeat,
    init,
    selectInitialized,
} from 'store/messenger';
import { message } from 'antd';

import history from 'store/history';

import Login from 'components/login/Login';

import { OrderLayout } from 'components/layout';
import { PrivateRoute } from './PrivateRoute';


import Lobby from 'components/lobby/Index';
//const Lobby = lazy(() => import('components/lobby/Index'));

import LobbyPage from 'components/lobby/Lobby';
//const LobbyPage = lazy(() => import('components/lobby/Lobby'));

import OrderPage from 'components/order/Index';
//const OrderPage = lazy(() => import('components/order/Index'));

import OrderSummaryPage from 'components/order/Summary';
//const OrderSummaryPage = lazy(() => import('components/order/Summary'));

import SchedulePage from 'components/schedule/Index';
//const SchedulePage = lazy(() => import('components/schedule/Index'));

import MessageCenterPage from 'components/messagecenter/Index';
//const MessageCenterPage = lazy(() => import('components/messagecenter/Index'));

import HostBoothPage from 'components/hostbooth/Index';
//const HostBoothPage = lazy(() => import('components/hostbooth/Index'));

import BoothPage from 'components/booth/Booth';
//const BoothPage = lazy(() => import('components/booth/Booth'));

import BoothsPage from 'components/booth/Index';
//const BoothsPage = lazy(() => import('components/booth/Index'));

import InstructionsPage from 'components/instructions/Index';
//const InstructionsPage = lazy(() => import('components/instructions/Index'));

import SupportPage from 'components/support/Index';
//const SupportPage = lazy(() => import('components/support/Index'));

import MeetingSchedulePage from 'components/meetingschedule/Index';
//const MeetingSchedulePage = lazy(() => import('components/meetingschedule/Index'));

import AnnouncementsPage from 'components/announcements/Index';
//const AnnouncementsPage = lazy(() => import('components/announcements/Index'));


//import { ShowInfoPage } from 'components/showinfo/Index';
const ShowInfoPage = lazy(() => import('components/showinfo/Index'));


const App = () => {
    const dispatch = useAppDispatch();
    const userAuthenticated: boolean = useAppSelector(selectUserAuthenticated);
    const initialized: boolean = useAppSelector(selectInitialized);
    const user: User = useAppSelector(selectUser);

    //let location = useLocation();

    const redirect: boolean = useAppSelector(selectRedirect);

    React.useEffect(() => {
        //console.log("USER", user);
        if (user == null) {
            dispatch(getUser());
        }
        const timer = setInterval(() => {
            dispatch(heartbeat());
        }, 30000);
        return () => clearInterval(timer);
    }, []);


    message.config({
        duration: 3,
        maxCount: 1
    });

    const handleOnIdle = () => {
        //console.log('user is idle');
        //console.log('last active', getLastActiveTime());
        if (globalThis.twilioRoom != undefined) {
            window.dispatchEvent(new Event('disconnectFromRoom'));
        }
        setTimeout(() => {
            dispatch(logout());
        }, 1000);
    }

    const handleOnActive = (event) => {
        //console.log('user is active', event)
        //console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = (e) => {
        //console.log('user did something', e)
    }

    //3600000 = 1 hour
    //43200000 = 12 hours
    //1000 * 60 * 60
    //43200000
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 43200000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    });

    React.useEffect(() => {
        //console.log("userAuthenticated:", userAuthenticated, "redirect:", redirect, "user:", user, "location:", location);
        if (userAuthenticated) {
            if (!initialized) {
                dispatch(init());
            }
        }
        if (userAuthenticated && redirect && user.page && user.page.length > 0) {
            dispatch(setRedirect());
            history.push(user.page);
        }
    }, [userAuthenticated, redirect, user]);

    //React.useEffect(() => {
    //    console.log(location);
    //}, []);

    return (
        <CacheBuster>
            {({ loading }) => {
                if (loading) {
                    return null;
                }
                //console.log(userAuthenticated);
                if (userAuthenticated) {
                    return (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Route exact path='/login' component={Login} />
                            <OrderLayout>

                                <PrivateRoute exact path='/' component={Lobby} />
                                <PrivateRoute exact path='/lobby' component={LobbyPage} />
                                <PrivateRoute exact path='/host-booth' component={HostBoothPage} />

                                <PrivateRoute exact path='/order' component={OrderPage} />
                                <PrivateRoute exact path='/order-summary' component={OrderSummaryPage} />

                                <PrivateRoute exact path='/schedule' component={SchedulePage} />
                                <PrivateRoute exact path='/message-center' component={MessageCenterPage} />

                                <PrivateRoute exact path='/booths/' component={BoothsPage} />
                                <PrivateRoute path='/booth/:id' component={BoothPage} />

                                <PrivateRoute exact path='/meeting-schedule' component={MeetingSchedulePage} />
                                <PrivateRoute exact path='/instructions' component={InstructionsPage} />
                                <PrivateRoute exact path='/announcements' component={AnnouncementsPage} />
                                <PrivateRoute exact path='/show-info' component={ShowInfoPage} />
                                <PrivateRoute exact path='/support' component={SupportPage} />

                            </OrderLayout>
                        </Suspense>
                    )
                } else {
                    return (<Login />);
                }
            }}
        </CacheBuster>
    );
};

export default App;

                                //<PrivateRoute exact path='/test' component={TestPage} roles={[""]} />
//