import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Vendor } from 'model/index';

//import 'swiper/css/bundle';
//import 'swiper/css';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Lazy } from 'swiper';
SwiperCore.use([Autoplay, Lazy]);


import {
    selectVendors,
    filterPrimeVendor
} from 'store/order';
export const VendorSliderComponent: React.FC = () => {
    const dispatch = useAppDispatch();

    const vendors = useAppSelector(selectVendors);

    const vendorsWithLogos = vendors.filter((vendor: Vendor) => {
        return vendor.logo != null && vendor.primeVendor && vendor.logo.length > 0;
    });

    const onVendorSelect = (vendor: Vendor) => {
        dispatch(filterPrimeVendor(vendor));
    };

    const params = {
        centeredSlides: true,
        loop: true,
        freeMode: true,
        slidesPerView: 5,
        autoplay: {
            delay: 5000
        },
        preloadImages: false,
        lazy: {
            loadPrevNext: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 10
            },
            1024: {
                slidesPerView: 10,
                spaceBetween: 10
            },
            1600: {
                slidesPerView: 16,
                spaceBetween: 10
            }
        }
    };

    if (vendorsWithLogos.length > 0) {
        return (
            <div className="vendor-slider">
                <Swiper  {...params}>
                    {vendorsWithLogos.map((vendor: Vendor) => {
                        return <SwiperSlide key={`div-${vendor.vendorId}`}>
                            <div data-background={`${vendor.logo}`} className="slider-img swiper-lazy" title={vendor.vendorName} onClick={() => onVendorSelect(vendor)}>
                                <div className="swiper-lazy-preloader"></div>
                            </div>
                        </SwiperSlide>;
                    })}
                </Swiper>
            </div >
        );
    } else {
        return null;
    }
}
export const VendorSlider = React.memo(VendorSliderComponent);
