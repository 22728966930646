import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel,
    IHttpConnectionOptions
} from '@microsoft/signalr';

import {
    status,
    recieveMessage,
    updateUserStatusAsync,
    updateVideoStatus,
    getSessionsAsync
} from './messenger';

import {
    refreshOrder,
    updateSettings,
    setReloadItems
} from './order';

import {
    logout,
    showNotification,
    getAnnouncementsAsync,
    reloadApp
} from './user';

import {
    OrderItemRefresh,
    AppUpdate,
    MessengerUserStatus,
    OrderSettings,
    Message,
    UserRole,
    AppVersion
} from 'model/index';

import { setTimeout } from 'timers';
import { MessengerVideoSession } from '../model';
import { DefaultGuid } from '../utility';

const isDev: boolean = process.env.NODE_ENV === 'development';

//const startSignalRConnection = async connection => {
//    try {
//        await connection.start();
//        console.assert(connection.state === HubConnectionState.Connected);
//        //console.log('SignalR connection established');
//        console.log("Connected, transport=" + connection.transport.name);
//    } catch (err) {
//        console.assert(connection.state === HubConnectionState.Disconnected);
//        //console.error('SignalR Connection Error: ', err);
//        setTimeout(() => startSignalRConnection(connection), 5000);
//    }
//};

const options: IHttpConnectionOptions = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    accessTokenFactory: () => {
        //console.log("GET TOKEN:", localStorage.getItem(tokenName));
        return localStorage.getItem(globalThis.app.tokenName);
    }
};

const hubUrl: string = `/r/messenger?appversion=${globalThis.appVersion}`;
//version: globalThis.appVersion,
const connection = new HubConnectionBuilder()
    .withUrl(hubUrl, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build();

globalThis.hubConnection = connection;

let signalRSetup: boolean = false;

let currentConnectionId: string = null;

export const setupSignalRConnection = store => next => action => {

    let result = next(action);

    //console.log("setupSignalRConnection:", signalRSetup);

    if (action.type === "user/logout") {
        console.log("SIGNALR STOP");
        if (globalThis.twilioRoom != undefined) {
            window.dispatchEvent(new Event('disconnectFromRoom'));
        }
        if (connection.state === HubConnectionState.Connected) {
            connection.stop();
        }
    } else {

        const state = store.getState();
        //console.log(state.user.currentUser, state.user.authenticated, state.messenger.initialized);
        //console.log("USER authenticated:", state.user.authenticated);
        //console.log("SIGNALR Init:", state.messenger.initialized);

        if (state.user.authenticated) {
            //INITIAL SETUP 
            if (!signalRSetup) {

                //console.log("INIT SignalR NOW");

                connection.serverTimeoutInMilliseconds = 60000;

                // re-establish the connection if connection dropped
                connection.onclose(error => {
                    //store.dispatch(status(connection.state));
                    //console.assert(connection.state === HubConnectionState.Disconnected);
                    console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
                });

                connection.onreconnecting(error => {
                    store.dispatch(status(connection.state));
                    //console.assert(connection.state === HubConnectionState.Reconnecting);
                    //console.log('Connection lost due to error. Reconnecting.', error);
                });

                connection.onreconnected(connectionId => {
                    store.dispatch(status(connection.state));
                    //console.assert(connection.state === HubConnectionState.Connected);
                    currentConnectionId = connectionId;
                    console.log('Connection reestablished. Connected with connectionId', connectionId);
                });

                connection.on("recieveMessage", (message: Message) => {
                    //console.log("----SignalR recieveMessage:", message);
                    store.dispatch(recieveMessage(message));
                });

                connection.on("updateApp", (appUpdate: AppUpdate) => {
                    //console.log("----SignalR updateApp:", appUpdate);
                    //console.log(appUpdate, state.user);
                    let process: boolean = appUpdate.showId == 0 || globalThis.showId == appUpdate.showId;
                    if (process) {
                        //Check for audience
                        if (appUpdate.audience && appUpdate.audience.length > 0 && state.user && state.user.currentUser) {
                            if (state.user.currentUser.role == UserRole.Customer) {
                                if (appUpdate.audience != UserRole.Customer) {
                                    process = false
                                }
                            } else {
                                if (appUpdate.audience == UserRole.Customer) {
                                    process = false
                                }
                            }
                        }

                        //Check for user
                        if (appUpdate.userId && appUpdate.userId.length > 0 && state.user && state.user.currentUser) {
                            //console.log(appUpdate.userId, state.user.currentUser.id);
                            if (appUpdate.userId != state.user.currentUser.id) {
                                process = false
                            }
                        }
                        if (process) {
                            if (appUpdate.logout) {
                                store.dispatch(logout());
                            }
                            if (appUpdate.reloadItems) {
                                store.dispatch(setReloadItems(true));
                            }
                            if (appUpdate.reloadApp) {
                                //store.dispatch(showNotification(appUpdate.message));
                                store.dispatch(reloadApp());
                            }

                            if (appUpdate.reloadAnnouncements) {
                                store.dispatch(getAnnouncementsAsync(false));
                            }

                            if (appUpdate.newAnnouncement) {
                                store.dispatch(getAnnouncementsAsync(true));
                            }
                            if (appUpdate.message && appUpdate.message.length > 0) {
                                store.dispatch(showNotification(appUpdate.message));
                            }
                            if (appUpdate.reloadSessions) {
                                store.dispatch(getSessionsAsync());
                            }
                        }
                    }
                });

                connection.on("refreshOrder", (message: OrderItemRefresh) => {
                    //console.log("----SignalR refreshOrder message:", message, " connectionId:", currentConnectionId);
                    //Only refresh orders if it is done by somebody else
                    if (currentConnectionId != message.connectionId) {

                        let orderItemRefresh: OrderItemRefresh = { ...message };

                        orderItemRefresh.index = new Date().getTime();

                        store.dispatch(refreshOrder(orderItemRefresh));
                    }
                    //console.log("----SignalR refreshOrder Current Customer:", state.order.customer.customerId, " target:", message.customerId);
                });

                connection.on("userStatus", (message: MessengerUserStatus) => {
                    //console.log("SignalR userStatus:", message);
                    store.dispatch(updateUserStatusAsync(message))
                });

                connection.on("notifyVideo", (message: MessengerVideoSession) => {
                    ////console.log("SignalR userStatus:", message);
                    store.dispatch(updateVideoStatus(message))
                });

                connection.on("appStatus", (message: OrderSettings) => {
                    //console.log("SignalR appStatus:", message);
                    //store.dispatch(updateVideoStatus(message))
                    store.dispatch(updateSettings(message));
                });

                signalRSetup = true;
            }
            //console.log(action.type, connection.state);

            if (connection.state == HubConnectionState.Connected) {
                switch (action.type) {
                    case "messenger/sendMessage":
                        //console.log("SignalR SEND MESSAGE", action.payload);
                        connection.invoke("SendMessage", action.payload);
                        break;
                    case "messenger/videoConnected":
                        //console.log("HUB VIDEO CONNECTED:", state.messenger.videoSessionId);
                        let videoConnectedSession: MessengerVideoSession = {
                            sessionId: state.messenger.videoSessionId,
                            connected: true
                        }
                        connection.invoke("NotifyVideo", videoConnectedSession);
                        break;
                    case "messenger/videoDisconnected":
                        //console.log("HUB VIDEO DISCONNECTED:", state.messenger.videoSessionId);
                        let videoDisconnectedSession: MessengerVideoSession = {
                            sessionId: state.messenger.videoSessionId,
                            connected: false
                        }
                        connection.invoke("NotifyVideo", videoDisconnectedSession);
                        break;
                    case "messenger/heartbeat":
                        //console.log("signalRHub heartbeat");
                        let appVersion: AppVersion = {
                            version: globalThis.appVersion
                        };
                        connection.invoke("Heartbeat", appVersion);
                        break;
                    case "order/updateOrder":
                        const orderNotify = {
                            customerId: state.order.customer.customerId,
                            catalogId: state.order.currentItem ? state.order.currentItem.catalogId : DefaultGuid
                        }
                        connection.invoke("NotifyOrder", orderNotify);
                        break;
                    case "order/analytic":
                        //const orderNotify = {
                        //    customerId: state.order.customer.customerId
                        //}
                        break;
                    default:
                        break;
                }
            }

            if (connection.state == HubConnectionState.Disconnected) {
                if (state.user.authenticated && state.messenger.initialized) {
                    if (isDev) {
                        console.log("SignalR STARTING");
                    }
                    connection.start().then(a => {
                        if (connection.connectionId) {
                            store.dispatch(status(connection.state));
                            currentConnectionId = connection.connectionId;
                            if (isDev) {
                                console.log("SignalR STARTED", connection.connectionId);
                            }
                            //let appVersion: AppVersion = {
                            //    version: globalThis.appVersion
                            //};
                            //connection.invoke("Heartbeat", appVersion);
                        }
                    });
                }
            }
        }
    }
    return result;
};

