import React, { useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    MessengerSession,
    SessionFilterType,
    UserSettings
} from 'model/index';

import { Radio, Button } from 'antd';
import {
    MenuFoldOutlined,
} from '@ant-design/icons';

import {
    selectSessions,
    selectSidebarCollapsed,
    setSidebarCollapsed,
    selectSessionFilter,
    setSessionFilter
} from 'store/messenger';

import { TimeZone } from 'components/common/TimeZone';

interface State {
    sessionCount: number,
    unReadCount: number
}

export const Filters: React.FC = () => {
    const dispatch = useAppDispatch();

    const sidebarCollapsed: boolean = useAppSelector(selectSidebarCollapsed);

    const sessionFilter: SessionFilterType = useAppSelector(selectSessionFilter)

    const sessions: MessengerSession[] = useAppSelector(selectSessions);


    const [state, setState] = useState<State>({
        sessionCount: 0,
        unReadCount: 0,
    });

    const filterOnChange = (e) => {
        //console.log(e);
        const filterType: SessionFilterType = SessionFilterType[e.target.value];
        dispatch(setSessionFilter(filterType));
    };

    const toggleCollapsed = () => {
        dispatch(setSidebarCollapsed(!sidebarCollapsed));
    }

    React.useEffect(() => {
        if (sessions) {
            let totalUnread: number = 0;
            if (sessions.length > 0) {
                totalUnread = sessions.filter((session: MessengerSession) => session.hasUnread).length;
            }
            setState(prevState => {
                return {
                    ...prevState,
                    unReadCount: totalUnread,
                    sessionCount: sessions.length
                }
            });
        }
    }, [sessions]);

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <Radio.Group defaultValue={sessionFilter} buttonStyle="outline" onChange={filterOnChange}>
                    <Radio.Button value={SessionFilterType.All} style={{ height: 30, width: 100, textAlign: 'center' }} type="default" >All ({state.sessionCount})</Radio.Button>
                    <Radio.Button value={SessionFilterType.Unread} style={{ height: 30, width: 100, textAlign: 'center' }} type="default">Unread ({state.unReadCount})</Radio.Button>
                </Radio.Group>
                <div style={{
                    marginTop: 5,
                    marginLeft: 'auto'
                }}>
                    <MenuFoldOutlined onClick={toggleCollapsed} style={{ fontSize: 16, color: '#1890ff' }} title="Hide Sidebar" />
                </div>
            </div>
            <div className="center-content" style={{ height: 30, fontSize: 12 }}>
                <TimeZone />
            </div>
        </div>

    );
}
