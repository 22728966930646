import * as React from 'react';
import { Input, InputNumber, Button, Table, Spin, Empty, Row, Col } from 'antd';
import { CatalogOrder, CatalogItem, DeliverySchedule, Customer, CatalogItemDeliverySchedule, OrderMode, OrderOption, CatalogHistory } from 'model';
import { QuantityWarning } from './QuantityWarning';
import * as log from 'loglevel';

import { message } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import { useAppState } from 'components/layout/AppStateProvider';

import { DefaultGuid, checkMinMaxQuantity } from 'utility/utility';

import {
    selectCustomer,
} from 'store/order';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ColumnsType } from 'antd/lib/table';


interface Props {
    orderOptions: OrderOption,
    catalogItem: CatalogItem,
    deliverySchedule: DeliverySchedule[],
    locationGroups: string[],
    locations: Customer[],
    readOnly: boolean,
    onOk: (data: any) => any,
    onCancel: () => any
}

enum RowType {
    Group,
    Customer
}

const ChainOrderWindow: React.FC<Props> = (props) => {

    const [rows, setRows] = React.useState([]);

    const [loading, setLoading] = React.useState<boolean>(true);

    const deliveryWeekCount: number = props.deliverySchedule.length;

    const { showNotes } = useAppState();

    const customer: Customer = useAppSelector(selectCustomer);

    React.useEffect(() => {
        setLoading(true);
        //Log.info(props.catalogItem);
        setupRows();

    }, [props.catalogItem]);

    const updateOrder = () => {
        let proceed: boolean = true;

        setLoading(true);

        let orderDetails: CatalogOrder[] = [];

        rows.forEach(row => {
            if (row.rowType === RowType.Customer) {
                if (!orderDetails.some(x => x.CustomerId === row.customerId)) {
                    let customerOrder: CatalogOrder = {
                        CustomerId: row.customerId
                    };
                    for (let i = 1; i <= deliveryWeekCount; i++) {
                        const title: string = `W${i.toString()}`;
                        customerOrder[title] = row[title] == "" ? 0 : Number(row[title]);
                    }
                    orderDetails.push(customerOrder);
                    let status: boolean = checkMinMaxQuantity(props.catalogItem.itemDeliverySchedule, props.deliverySchedule, customerOrder);
                    //log.debug("Max Quantity Validation Status:", status);
                    if (!status) {
                        proceed = false;
                    }
                }
            }
        });
        //log.debug("Proceed Status:", proceed);
        //log.debug(orderDetails);
        if (proceed) {
            props.onOk(orderDetails);
            //props.onCancel();
            setRows([]);
        } else {
            setLoading(false);
            message.error('Please check your quantities.');
        }
    };

    const customerRow = (customer: Customer, group: string) => {
        const values: any = props.catalogItem.quantity.find((order) => order.CustomerId === customer.customerId);

        let row: any = {
            key: `${customer.customerId}-${group}`,
            customerId: customer.customerId,
            customerAccountId: customer.customerAccountId,
            customerName: customer.customerName,
            group: group,
            rowType: RowType.Customer
        };
        for (let i = 1; i <= deliveryWeekCount; i++) {
            const title: string = `W${i.toString()}`;
            if (values) {
                row[title] = values[title] === 0 ? "" : values[title];
            } else {
                row[title] = "";
            }
        }

        if (props.orderOptions.history) {
            const historyValue: CatalogHistory = props.catalogItem.history.find((order) => order.CustomerId === customer.customerId);

            if (historyValue) {
                if (props.orderOptions.historyAverage) {
                    if (historyValue.quantityAverage && historyValue.quantityAverage > 0) {
                        row["historyAverage"] = historyValue.quantityAverage;
                    } else {
                        row["historyAverage"] = "";
                    }
                }
                if (historyValue.quantity && historyValue.quantity > 0) {
                    //console.log(historyValue.quantity);
                    row["history"] = historyValue.quantity;
                } else {
                    row["history"] = "";
                }
            }
        }

        return row;
    }

    const handleQuantityChange = (value, row, deliveryWeek) => {

        //log.debug(row);
        const data = [...rows];
        data.filter((customer) => customer.customerId === row.customerId).forEach(locationCustomer => {
            locationCustomer[deliveryWeek] = value;
        });
        if (row.rowType === RowType.Group) {
            let locations = [];
            if (row.group === "_all_") {
                locations = data.filter((customer) => customer.rowType === RowType.Customer);
            } else {
                let locationsCustomerIds = data.filter((customer) => customer.group === row.group && customer.rowType === RowType.Customer).map((customer: Customer) => {
                    return customer.customerId;
                });
                locations = data.filter((customer) => locationsCustomerIds.includes(customer.customerId));
            }
            locations.forEach(locationCustomer => {
                locationCustomer[deliveryWeek] = value;
            });
        }
        setRows(data);
    }

    const handleClear = (e, row, deliveryWeek) => {
        const data = [...rows];
        let locations = [];

        //Clear everything
        if (row.group === "_all_") {
            locations = data.filter((customer) => customer.rowType === RowType.Customer);
        } else {
            let locationsCustomerIds = data.filter((customer) => customer.group === row.group && customer.rowType === RowType.Customer).map((customer: Customer) => {
                return customer.customerId;
            });
            locations = data.filter((customer) => locationsCustomerIds.includes(customer.customerId));
        }
        if (deliveryWeek === "") {
            locations.forEach(locationCustomer => {
                for (let i = 1; i <= deliveryWeekCount; i++) {
                    const title: string = `W${i.toString()}`;
                    const deliveryWeekStatus: DeliverySchedule = props.deliverySchedule.find((item) => item.weekNo === i);
                    if (!deliveryWeekStatus.disabled) {
                        locationCustomer[title] = "";
                    }
                }
            });
        } else {
            locations.forEach(locationCustomer => {
                locationCustomer[deliveryWeek] = "";
            });
        }
        setRows(data);
    }

    const handleGroupBlur = (e, row, deliveryWeek) => {
        if (e.target.value.length > 0) {
            const data = [...rows];
            let location = data.find((customer) => customer.key === row.key);
            location[deliveryWeek] = "";


            setRows(data);
        }
    }

    const groupDeleteButtonCss: string = props.readOnly ? "hidden" : "";

    const onNotesClick = () => {
        showNotes(props.catalogItem.catalogId, customer.customerId);
    }


    //const sharedOnCell = (record, rowIndex) => {
    //    if (index === 4) {
    //        return { colSpan: 0 };
    //    }
    //};


    const columns = () => {
        let colspan: number = 2;

        if (props.orderOptions.history) {
            colspan = 3;

            if (props.orderOptions.historyAverage) {
                colspan = 4;
            }
        }

        let notes = null;

        if (props.orderOptions.notesEnabled && customer.customerId != DefaultGuid && !props.readOnly) {
            notes = <Button type="link" onClick={onNotesClick}><FormOutlined /> Notes</Button>;
        }

        const columnsData: any[] = [
            {
                title: 'Account #',
                width: 100,
                dataIndex: 'customerAccountId',
                onCell: (record, rowIndex) => {
                    if (record.rowType === RowType.Group) {
                        return {
                            colSpan: colspan,
                            className: "bold"
                        };
                    }
                },
                render: (text, row, index) => {
                    if (row.rowType === RowType.Group) {
                        return <div>
                            <strong>{row.customerName}</strong>
                            <Button type="link"
                                className={groupDeleteButtonCss}
                                onClick={(x) => handleClear(x, row, "")}
                                tabIndex={-1}>
                                Clear
                            </Button>
                            {notes}
                        </div>;
                    } else {
                        return <div>{text}</div>;
                    }
                },
            },
            {
                title: 'Customer',
                dataIndex: 'customerName',
                width: 200,
                onCell: (record, rowIndex) => {
                    if (record.rowType === RowType.Group) {
                        return {
                            colSpan: 0
                        };
                    }
                },
                render: (text, row, index) => {
                    return <div>{text}</div>;
                },
            }
        ];

        if (props.orderOptions.history) {
            if (props.orderOptions.historyAverage) {
                columnsData.push({
                    title: props.orderOptions.historyAverageTitle,
                    dataIndex: 'historyAverage',
                    width: 50,
                    onCell: (record, rowIndex) => {
                        return {
                            colSpan: record.rowType === RowType.Group ? 0 : 1,
                            className: "center-align history-column"
                        };
                    },
                    onHeaderCell: (column) => {
                        return {
                            className: "center-align"
                        };
                    },
                    render: (text, row, index) => {
                        return <div title={text}>{text}</div>;
                    }
                });
            }
            columnsData.push({
                title: props.orderOptions.historyTitle,
                dataIndex: 'history',
                width: 50,
                onCell: (record, rowIndex) => {
                    return {
                        colSpan: record.rowType === RowType.Group ? 0 : 1,
                        className: "center-align history-column"
                    };
                },
                onHeaderCell: (column) => {
                    return {
                        className: "center-align"
                    };
                }
                , render: (text, row, index) => {
                    return <div title={text}>{text}</div>;
                }
            });
        }

        const deliveryWeekCount: number = props.deliverySchedule.length;

        for (let i = 1; i <= deliveryWeekCount; i++) {
            const deliveryWeek: string = `W${i.toString()}`;
            const status: any = props.catalogItem.itemDeliverySchedule[deliveryWeek as keyof CatalogItemDeliverySchedule];

            if (status) {
                const columnTitle: DeliverySchedule = props.deliverySchedule.find((item) => item.weekNo === i);

                const clearButtonCss: string = props.readOnly ? "hidden" : "";

                const cleardisabled: boolean = columnTitle.disabled;

                const column: any = {
                    title: columnTitle.title,
                    width: 60,
                    onCell: (record, rowIndex) => {
                        return {
                            className: record.rowType === RowType.Group ? "bold center-align" : "center-align"
                        };
                    },
                    onHeaderCell: (column) => {
                        return {
                            className: "center-align"
                        };
                    },
                    render: (text, row, index) => {
                        let inputProps: any = {
                            style: {
                                width: 60
                            },
                            size: "large",
                            key: `${row.key}-W-${i.toString()}`,
                            maxLength: 4,
                            min: 0,
                            max: 9999,
                            value: row[deliveryWeek],
                            onChange: (value) => handleQuantityChange(value, row, deliveryWeek),
                            disabled: props.readOnly || columnTitle.disabled
                        };
                        //props.readOnly
                        if (row.rowType === RowType.Group) {
                            inputProps.onBlur = (x) => handleGroupBlur(x, row, deliveryWeek);
                            return <div>
                                <div>
                                    <InputNumber {...inputProps} />
                                </div>
                                <div>
                                    <Button
                                        type="link"
                                        className={clearButtonCss}
                                        disabled={cleardisabled}
                                        onClick={(x) => handleClear(x, row, deliveryWeek)}
                                        tabIndex={-1}>
                                        Clear
                                    </Button>
                                </div>
                            </div>;
                        } else {
                            return <InputNumber {...inputProps} />;
                        }
                    }
                };
                columnsData.push(column);
            }
        }
        return columnsData;
    }

    const setupRows = () => {
        let tableData: any = [];
        let allGroupRow: any = {
            key: "group_all",
            customerId: "ALL",
            customerAccountId: "ALL",
            customerName: "ALL",
            group: "_all_",
            rowType: RowType.Group
        };
        for (let i = 1; i <= deliveryWeekCount; i++) {
            const title: string = `W${i.toString()}`;
            allGroupRow[title] = "";
        }
        tableData.push(allGroupRow);

        //Customer has groups
        if (props.locationGroups.length > 0) {

            props.locationGroups.forEach(group => {
                //Do not show header for no group members
                if (group != "000000") {
                    let groupRow: any = {
                        key: `group-${group}`,
                        customerId: group,
                        customerAccountId: group,
                        customerName: group,
                        group: group,
                        rowType: RowType.Group
                    };
                    for (let i = 1; i <= deliveryWeekCount; i++) {
                        const title: string = `W${i.toString()}`;
                        groupRow[title] = "";
                    }
                    tableData.push(groupRow);
                }
                const groupLocations = props.locations.filter(customer => customer.groups.includes(group));
                //log.debug(group, groupLocations);
                groupLocations.forEach(location => {
                    const row = customerRow(location, group);
                    tableData.push(row);
                });

            });
        } else {
            props.locations.forEach(location => {
                const row = customerRow(location, "");
                tableData.push(row);
            });
        }
        setRows(tableData);
        setLoading(false);
    }

    let height = window.innerHeight - 165;

    if (props.catalogItem.itemDeliverySchedule.MaxQuantity) {
        height = height - 45;
    }

    if (props.catalogItem.itemDeliverySchedule.MinimumQuantity) {
        height = height - 45;
    }

    const cancelOrder = () => {
        //Log.info("CLOSE");

        setLoading(true);

        setRows([]);

        props.onCancel();

    };

    const controlButtons = () => {
        return (
            <Row>
                <Col span={12}>
                    <Button block={true} onClick={cancelOrder}>Cancel</Button>
                </Col>
                <Col span={12}>
                    <Button type="primary" block={true} onClick={updateOrder}>OK</Button>
                </Col>
            </Row>
        );
    };
    return (
        <div>
            <Spin spinning={loading} tip="Loading..." style={{ height: '100%' }}>
                <QuantityWarning data={props.catalogItem.itemDeliverySchedule} />
                {controlButtons()}
                <Table
                    columns={columns()}
                    dataSource={rows}
                    pagination={false}
                    size="small"
                    scroll={{ y: height }}
                    locale={{ emptyText: <Empty description={false} image={null} imageStyle={{ height: 200 }} /> }}
                />
                {controlButtons()}
            </Spin>
        </div>
    );
}
export default ChainOrderWindow;

