import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MessengerSession, UserRole, Customer } from 'model/index';
import { DefaultGuid } from 'utility/utility';
import {
    selectActiveSession,
    updateWindowExpandedStatus
} from 'store/messenger';
import {
    selectCustomer, setCustomerAsync,
} from 'store/order';
import { selectUser } from 'store/user';
import { ShoppingFilled } from '@ant-design/icons';
import { Button } from 'antd';

export default function OrderButton() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const customer = useAppSelector(selectCustomer);
    const activeSession: MessengerSession = useAppSelector(selectActiveSession);
    const onSelectCustomer = () => {
        const customer: Customer = {
            customerId: activeSession.customerId
        };
        dispatch(setCustomerAsync(customer));
        dispatch(updateWindowExpandedStatus(false));
    }
    if (customer.customerId === DefaultGuid && user.role != UserRole.Customer) {
        return (
            <React.Fragment>
                <Button type="link"
                    onClick={onSelectCustomer}
                    title="Start an order" icon={<ShoppingFilled />}>
                    Order
                </Button>
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }
}
