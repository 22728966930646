import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';

interface ParticipantProps {
    participant: IParticipant;
    disableAudio?: boolean;
    enableScreenShare?: boolean;
    onClick: () => void;
    isSelected: boolean;
}

export default function Participant({
    participant,
    disableAudio,
    enableScreenShare,
    onClick,
    isSelected,
}: ParticipantProps) {


    return (
        <div className="participant">
            <ParticipantInfo participant={participant}
                onClick={onClick}
                isSelected={isSelected}>
                <ParticipantTracks
                    participant={participant}
                    disableAudio={disableAudio}
                    enableScreenShare={enableScreenShare} />
            </ParticipantInfo>
        </div>
    );
}
