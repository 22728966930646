import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import axios from 'axios';

import {
    selectUser,
} from 'store/user';

import { UserRole, ShowField } from 'model';

const InstructionsPage: React.FC = () => {

    const user = useAppSelector(selectUser);

    const [state, setState] = React.useState<string>("<div>Insctructions</div>");

    const createMarkup = (content: string) => {
        //console.log(content);
        return {
            __html: content
        };
    }

    React.useEffect(() => {
        document.title = `Instructions | ${globalThis.showTitle}`;
    }, []);

    React.useEffect(() => {
        if (user) {
            let instructions: ShowField = {
                title: "Customer.OrderInstructions"
            };
            switch (user.role) {
                case UserRole.Vendor:
                case UserRole.Broker:
                    instructions.title = "Vendor.OrderInstructions";
                    break;
                case UserRole.Customer:
                    instructions.title = "Customer.OrderInstructions";
                    break;
                default:
                    break;
            }

            let showFields: ShowField[] = [instructions];
            axios.post<ShowField>("/order/content", showFields).then(response => {
                setState(response.data[0].content);
            });
        }
    }, [user]);

    return (
        <div className="page-content">
            <h1>
                Instructions
            </h1>
            <div className="content-html" dangerouslySetInnerHTML={createMarkup(state)}>
            </div>
        </div>
    );
};

export default InstructionsPage;