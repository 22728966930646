import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button } from 'antd';
import {
    Vendor,
} from 'model';
import history from 'store/history';
import {
    filterVendor
} from 'store/order';
interface Props {
    vendors: Vendor[]
}

const BrokerVendorsComponent: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch();

    const vendorClickHandler = (vendor: Vendor) => {
        dispatch(filterVendor(vendor));
        history.push('/order');
    };

    return (
        <div style={{ paddingRight: 50 }}>
            <span style={{ paddingRight: 4 }}>Representing: </span>
            {props.vendors && props.vendors.map((vendor: Vendor, index: number) => {
                const total = props.vendors.length - 1;
                return (<React.Fragment key={index}>
                    <Button type="link" style={{ padding: 0 }}
                        title={vendor.vendorName}
                        onClick={() => vendorClickHandler(vendor)}>
                        {vendor.vendorName}
                    </Button>
                    {index < total && <span>, </span>}
                </React.Fragment>);
            })}
        </div>
    );
}

export const BrokerVendors = React.memo(BrokerVendorsComponent);


