import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ErrorStatus } from 'model/index';

import { Spin, Button } from 'antd';

import { selectError } from 'store/user';

export const Loading: React.FC = () => {

    //selectError

    const error: ErrorStatus = useAppSelector(selectError);

    const [visible, setVisible] = useState(false);

    const reload = () => {
        console.log("reload");
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) {
                    caches.delete(name);
                }
            });
        }
        // delete browser cache and hard reload
        window.location.reload();
    };

    React.useEffect(() => {
        if (error && error.message.length > 0) {
            setVisible(true);
        }

    }, [error]);

    return (
        <div className="loading">
            <div>
                <Spin spinning={true}
                    size="large"
                    tip="TradeShowEasy is loading..." />
            </div>
            {visible &&
                <div className="error">
                    <Button type="link" onClick={reload}>
                        Something went wrong. Try reloading the app.
                    </Button>
                </div>
            }
        </div>
    );
};

