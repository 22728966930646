import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Form, Input, Button, Checkbox, Row, Col, Alert } from 'antd';
import {
    LoginRequest,
    User,
    ErrorStatus
} from 'model';
import {
    getUser,
    loginAsync,
    selectUser,
    selectUserAuthenticated,
    selectError
} from 'store/user';
import history from 'store/history';
import jwt_decode from 'jwt-decode';

const layoutSettings = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

import queryString from 'query-string';

const Login: React.FC = () => {

    const dispatch = useAppDispatch();
    const user: User = useAppSelector(selectUser);

    const userAuthenticated: boolean = useAppSelector(selectUserAuthenticated);

    const error: ErrorStatus = useAppSelector(selectError);

    React.useEffect(() => {
        document.title = "Login";
    }, []);

    React.useEffect(() => {

        //console.log("Login userAuthenticated:", userAuthenticated, " user:", user);

        if (history.location.search.length > 0) {
            const parsed = queryString.parse(history.location.search);
            //console.log("parsed", parsed);
            
            if (parsed && parsed.token) {
                const token = parsed.token as string;
                try {
                    let userToken = jwt_decode(token);
                    if (userToken) {
                        console.log(userToken);
                        localStorage.removeItem(globalThis.app.tokenName);
                        localStorage.setItem(globalThis.app.tokenName, token);
                        if (parsed.booth) {
                            window.location.href = `/booth/${parsed.booth}`;
                        } else {
                            window.location.href = '/';
                        }
                    }
                } catch (e) {
                    window.location.href = "/login";
                }
            }
        }
        else {
            if (userAuthenticated && user) {
                //console.log("User", user);
                if (user.page && user.page.length > 0) {
                    history.push(user.page);
                } else {
                    history.push('/');
                }
            } else {
                //console.log("getUser", user);
                dispatch(getUser());
            }
        }
    }, [userAuthenticated, user]);

    const onFinishFailed = () => {

    };

    const onFinish = (values: LoginRequest) => {
        dispatch(loginAsync(values));
    };

    return (
        <div>
            <Row justify="center" align="middle" >
                <Col span={12} >
                    <div style={{ display: 'flex', width: '100%', padding: '20px' }}>
                        <div style={{ width: '33%' }} >
                            <a href="https://www.i-showmanager.com/" target="_blank">
                                <img src="https://www.tradeshoweasy.com/asset/img/ishow-manager.png"
                                    alt="ishow-manager"
                                    style={{ maxHeight: '60px' }}
                                />
                            </a>
                        </div>
                        <div style={{ width: '33%' }} >
                            <img src="https://www.tradeshoweasy.com/asset/img/tradeshoweasy.com.png"
                                alt="tradeshoweasy.com"
                                style={{ maxHeight: '60px' }} />
                        </div>
                        <div style={{ width: '33%' }} >
                            <a href="https://www.i-showmanager.com/e-xpo" target="_blank">
                                <img src="https://www.tradeshoweasy.com/asset/img/virtual-trade-show.png"
                                    alt="Virtual Tradeshow"
                                    style={{ maxHeight: '60px' }} />
                            </a>
                        </div>
                    </div>
                </Col>

            </Row>

            <Row justify="center" align="middle" >
                <Col span={12} >
                    <div>
                        <h1> Please Login</h1>
                    </div>
                    <Form
                        {...layoutSettings}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Username"
                            name="userName"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                        {error && <Alert message={error.message} type="error" />}
                    </Form>
                </Col>
            </Row>
            <Row justify="center" align="middle" >
                <Col span={12} >

                    <div>
                        <h2> CUSTOMER SUPPORT</h2>
                    </div>
                    <h3>
                        Please use <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> for optimal performance when using the Virtual Trade Show.
                    </h3>
                    <p>
                        Need help?  In order to efficiently assist you please provide the name of your company, account number if you have it and the
                        name of the company & program you are trying to access.
                    </p>
                    <p>
                        Please call <a href="tel:1-630-656-1133">630-656-1133</a>  or
                        email <a href="mailto:support@tmaplus.com">support@tmaplus.com </a>
                    </p>
                </Col>

            </Row>
        </div>
    );
};
export default Login;
