import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './user';
import messengerReducer from './messenger';
import orderReducer from './order';
import meetingReducer from './meeting';

const appReducer = combineReducers({
    user: userReducer,
    order: orderReducer,
    messenger: messengerReducer,
    meeting: meetingReducer
});

const rootReducer = (state, action) => {
    //console.log(state, action);
    if (action.type == "user/logout") {
        console.log("RESET STATE");
        state = undefined;
    }
    return appReducer(state, action);
}



export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;