import React from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useRoomState from './hooks/useRoomState/useRoomState';
import useVideoContext from './hooks/useVideoContext/useVideoContext';

import { MessengerSession, User, VideoStatus } from 'model/index';

import {
    selectUser
} from 'store/user';

import {
    selectVideoStatus,
    selectActiveSession,
} from 'store/messenger';

import useLocalVideoToggle from './hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useAppState } from './state';

export default function App() {


    const roomState = useRoomState();

    const { getToken, isFetching } = useAppState();

    const { connect } = useVideoContext();

    const activeSession: MessengerSession = useAppSelector(selectActiveSession);

    const user: User = useAppSelector(selectUser);

    const videoStatus = useAppSelector(selectVideoStatus);

    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

    React.useEffect(() => {
        //console.log("isVideoEnabled:", isVideoEnabled, " videoStatus:", videoStatus);
        if (isVideoEnabled && videoStatus === VideoStatus.Connected) {
            const roomName = `TSE-${user.showId}-${activeSession.sessionId}`;
            const userName = `${user.role}-${user.id}`;
            //console.log("ROOM:", roomName, " USER:", userName);
            getToken(userName, roomName).then(token => {
                connect(token);
            });
        }
        return () => {
            //console.log("VIDEO CLEANUP");
        };
    }, [isVideoEnabled]);

    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.
    //const height = useHeight();
    //style={{ height }}
    // <MenuBar />
    return (
        <div className="messenger-video">
            <React.Fragment>
                {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
                {videoStatus === VideoStatus.Connected && <Controls />}
            </React.Fragment>
            <ReconnectingNotification />
        </div>
    );
}
