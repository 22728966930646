import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import clsx from 'clsx';
import {
    Vendor,
    VirtualBooth
} from 'model';

import {
    selectVendors,
    filterVendor,
    selectVendor,
    selectVirtualBooths,
    filterBooth,
    selectBooth
} from 'store/order';

export const AvailableVendors: React.FC = () => {

    const dispatch = useAppDispatch();

    const vendors = useAppSelector(selectVendors);

    const virtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);

    const selectedBooth: VirtualBooth = useAppSelector(selectBooth);

    const onCompanySelect = (booth: VirtualBooth) => {
        dispatch(filterBooth(booth));
    };

    const availableCompanies = virtualBooths.map((booth: VirtualBooth) => {
        const css = clsx({
            'active': selectedBooth.entityId == booth.entityId
        });

        return (
            <li key={booth.entityId} onClick={() => onCompanySelect(booth)} className={css}>
                {booth.logo &&
                    <img src={booth.logo} />
                }
                <div>
                    {booth.name}
                </div>
            </li>
        );
    });

    return (
        <ul className="schedule-list">
            {availableCompanies}
        </ul>
    );
};

