import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Layout, Spin } from 'antd';
import {
    selectSummary
} from 'store/order';
import { Status } from './Status';
import { Filters as OrderFilters } from './Filters';
import { Vendors } from './Vendors';
import { OrderGrid } from './grid/OrderGrid';
import { ItemCount } from './ItemCount';
const { Header, Content, Sider, Footer } = Layout;

const OrderPage: React.FC = () => {


    const orderSummary = useAppSelector(selectSummary);

    React.useEffect(() => {
        document.title = `Order | ${globalThis.showTitle}`;
    }, []);

    React.useEffect(() => {
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 1000);

    }, [orderSummary]);

    const [notification, setNotification] = React.useState(false);

    return (
        <React.Fragment>
            <Sider width={250} theme="light"
                style={{
                    overflowY: 'scroll',
                    borderRight: '1px solid #d9d9d9'
                }}
            >
                <Vendors />
            </Sider>
            <Layout>
                <Header className="order-header">
                    <OrderFilters />
                </Header>
                <Content id="order-grid">
                    <OrderGrid />
                </Content>
                <Footer>
                    <div className="item-count">
                        <ItemCount />
                    </div>
                    <div className="order-status">
                        <Status />
                    </div>
                    <div className="order-notification">
                        <div className={notification ? 'show' : ''}>Order Saved<span>.</span><span>.</span><span>.</span></div>
                    </div>
                </Footer>
            </Layout>
        </React.Fragment >
    );
}
export default OrderPage;
