import React from 'react';
import { Layout } from 'antd';
export default function NoMessages() {
    return (
        <div className="messenger-window">
            <div className="messenger-header">
                <div className="messenger-header-title">Messages </div>
            </div>
            <Layout>
                <div className="loading" style={{ padding: '10px', backgroundColor: '#EBEFF2' }}>No Messages</div>
            </Layout>
        </div>
    );
}
