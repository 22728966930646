import { Route, Redirect } from 'react-router-dom';
import { selectUserAuthenticated } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const userAuthenticated = useAppSelector(selectUserAuthenticated);
    //console.log(userAuthenticated);
    return (
        <Route {...rest} render={props => {
            //console.log("SHOW COMPONENT", userAuthenticated);
            //const currentUser = authenticationService.currentUserValue;
            if (!userAuthenticated) {
                //const tokenExpirationDate = user.exp;
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            //console.log(Component);
            // check if route is restricted by role
            //if (roles && roles.indexOf(currentUser.role) === -1) {
            //    // role not authorised so redirect to home page
            //    return <Redirect to={{ pathname: '/' }} />
            //}
            // authorised so return component
            //console.log("SHOW COMPONENT");
            return <Component {...props} />
        }} />
    )
};
 