import React from 'react';
import { Schedule } from 'components/schedule/Schedule';
const SchedulePage: React.FC = () => {
    React.useEffect(() => {
        document.title = `Schedule Meeting | ${globalThis.showTitle}`;
    }, []);
    return (
        <React.Fragment>
            <Schedule />
        </React.Fragment>
    );
};

export default SchedulePage;