import React from 'react';
import { FileTwoTone, FilePdfTwoTone, VideoCameraTwoTone } from '@ant-design/icons';
import styles from './Rows.module.css';
import clsx from 'clsx';
import { useAppState } from 'components/layout/AppStateProvider';
import {

    MediaProps,
    MediaType,
    AnalyticsProps,
    AnalyticsType
} from 'model/index';


import Linkify from 'linkify-react';

interface Props {
    item: any,
    isScrolling: boolean,
    isAlternate: boolean
}
export const VideoRow: React.FC<Props> = (props) => {
    const { showMedia } = useAppState();
    const onClick = () => {
        let media: MediaProps = {
            mediaType: MediaType.Video,
            url: props.item.VideoUrl,
            title: `${props.item.VendorName}: ${props.item.Description}`,
            vendorAccountId: props.item.VendorAccountId
        };
        showMedia(media);
    };
    const css = clsx({
        [styles.row]: true,
        [styles.video]: true,
        [styles.even]: props.isAlternate
    });
    return <div className={css} onClick={onClick} title={`Video: ${props.item.Description}`}>
        <VideoCameraTwoTone style={{ fontSize: 24, paddingRight: 10, paddingLeft: 5 }} />
        <img src={props.item.AdURL} loading="lazy" className={styles.image} />
        <em>{props.item.Description}</em>
    </div>;
}
//{ props.isScrolling ? <div className={styles.loading}></div> : }

export const ImageRow: React.FC<Props> = (props) => {
    const { showMedia } = useAppState();
    const onClick = () => {
        let media: MediaProps = {
            mediaType: MediaType.Image,
            url: props.item.AdURL,
            title: `${props.item.VendorName} ${props.item.ItemNo}: ${props.item.Description}`,
            vendorAccountId: props.item.VendorAccountId
        };
        showMedia(media);
    };
    const css = clsx({
        [styles.row]: true,
        [styles.image]: true,
        [styles.even]: props.isAlternate
    });
    return <div className={css} onClick={onClick} title={`Image: ${props.item.Description}`}>
        <img src={props.item.AdURL} loading="lazy" className={styles.image} />
        <em>{props.item.Description}</em>
    </div>;
}

export const HeadlineRow: React.FC<Props> = (props) => {
    const css = clsx({
        [styles.row]: true,
        [styles.headline]: true,
        [styles.even]: props.isAlternate
    });
    return <div className={css}>
        <Linkify options={{ target: '_blank' }}>
            {props.item.Description}
        </Linkify>
    </div>;
}

export const CommentRow: React.FC<Props> = (props) => {

    const css = clsx({
        [styles.row]: true,
        [styles.comment]: true,
        [styles.even]: props.isAlternate
    });

    return <div className={css}>
        <Linkify options={{ target: '_blank' }}>
            {props.item.Description}
        </Linkify>
    </div>
}

export const ContractRow: React.FC<Props> = (props) => {
    const { showMedia } = useAppState();
    const onClick = () => {
        let media: MediaProps = {
            mediaType: MediaType.Contract,
            item: props.item.CatalogId,
            title: `${props.item.VendorName} ${props.item.ItemNo}: ${props.item.Description}`,
        };
        showMedia(media);
    };
    const css = clsx({
        [styles.row]: true,
        [styles.contract]: true,
        [styles.even]: props.isAlternate
    });
    return <div className={css} onClick={onClick} title={`Contract: ${props.item.Description}`}>
        <FilePdfTwoTone style={{ fontSize: 24, paddingRight: 10, paddingLeft: 5 }} />
        CONTRACT: <em style={{ paddingLeft: 5 }}>{props.item.Description}</em>
    </div>;
}

export const DocumentRow: React.FC<Props> = (props) => {
    const { logAnalytics } = useAppState();
    const onClick = () => {
        window.open(props.item.SpecificationSheet);
        const analytics: AnalyticsProps = {
            id: props.item.CatalogId,
            analyticsType: AnalyticsType.Document
        };
        logAnalytics(analytics);
    };
    const css = clsx({
        [styles.row]: true,
        [styles.document]: true,
        [styles.even]: props.isAlternate
    });
    return <div className={css} onClick={onClick} title={`Document: ${props.item.Description}`}>
        <FileTwoTone style={{ fontSize: 24, paddingRight: 10, paddingLeft: 5 }} />
        <em>{props.item.Description}</em>
    </div>;
}


