import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import history from './store/history';
import { Router } from 'react-router-dom';
import * as log from 'loglevel';
import { StyleProvider ,legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

window.addEventListener('error', e => {
    if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
    }
})

if (process.env.NODE_ENV === 'development') {
    log.setDefaultLevel("trace");
} else {
}

const theme = {
    token: {
        borderRadius: 0
    }
};

root.render(
    <Provider store={store}>
        <Router history={history}>
            <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
                <ConfigProvider theme={theme}>
                    <App />
                </ConfigProvider>
            </StyleProvider>
        </Router>
    </Provider>
);


