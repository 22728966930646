import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { MailTwoTone, MessageTwoTone, InfoCircleTwoTone, ClockCircleTwoTone, ShopTwoTone } from '@ant-design/icons';
import {
    Vendor,
    AnalyticsProps,
    MessengerSession,
    Customer,
    AnalyticsType,
    OrderOption,
    User,
    VirtualBooth,
    Company
} from 'model';
import {
    selectOrderOptions,
    selectCustomer,
    filterVendor,
    filterBooth,
    selectVirtualBooths,
    analytic
} from 'store/order';
import {
    createSessionAsync
} from 'store/messenger';
import {
    selectUser
} from 'store/user';
import { useAppState } from 'components/layout/AppStateProvider';
import history from 'store/history';
import styles from './Communication.module.css'


interface Props {
    vendor: Vendor,
    showIcons: boolean
}
//import whyDidYouRender from "@welldone-software/why-did-you-render";
//whyDidYouRender(React, {
//    onlyLogs: true,
//    titleColor: "green",
//    diffNameColor: "aqua"
//});

export const GridVendor: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);
    //const customer: Customer = useAppSelector(selectCustomer);

    const user: User = useAppSelector(selectUser);

    const { showCompanyInfo, email, activeSessionWarning } = useAppState();
    const virtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);

    const onInfo = () => {
        showCompanyInfo(props.vendor.vendorId);
    };

    const onSchedule = () => {
        const vendorBooth = virtualBooths.find((booth: VirtualBooth) => {
            return booth.entityId === props.vendor.vendorId;
        });
        if (vendorBooth) {
            dispatch(filterBooth(vendorBooth));
            history.push('/schedule');
        } else {
            const brokerBooth = virtualBooths.find((booth: VirtualBooth) => {
                return booth.entityId === props.vendor.brokerId;
            });
            if (brokerBooth) {
                dispatch(filterBooth(brokerBooth));
                history.push('/schedule');
            }
        }
    };

    const onEmail = () => {
        const sender: Company = {
            id: user.id,
            name: user.company
        };
        const recipient: Company = {
            id: props.vendor.vendorId,
            name: props.vendor.vendorName
        };
        email(sender, recipient);
    };

    const onViewBooth = () => {
        const vendorBooth = virtualBooths.find((booth: VirtualBooth) => {
            return booth.entityId === props.vendor.vendorId;
        });
        if (vendorBooth) {
            if (orderOptions.hostVendorId == vendorBooth.entityId) {
                history.push("/host-booth");
            } else {
                dispatch(filterVendor(props.vendor));
                history.push(`/booth/${vendorBooth.entityId}`);
            }
        } else {
            const brokerBooth = virtualBooths.find((booth: VirtualBooth) => {
                return booth.entityId === props.vendor.brokerId;
            });
            if (brokerBooth) {
                //dispatch(filterBroker(vendor));
                if (orderOptions.hostVendorId == brokerBooth.entityId) {
                    history.push("/host-booth");
                } else {
                    history.push(`/booth/${brokerBooth.entityId}`);
                }
            }
        }
    };

    const onMessage = () => {
        let messengerSession: MessengerSession = {
            customerId: user.id,
            vendorId: props.vendor.vendorId
        };
        if (globalThis.twilioRoom != undefined) {
            activeSessionWarning(messengerSession);
        } else {
            window.dispatchEvent(new Event('disconnectFromRoom'));
            dispatch(createSessionAsync(user.role, messengerSession));
        }
        let analytics: AnalyticsProps = {
            id: props.vendor.vendorId,
            analyticsType: AnalyticsType.Message
        };
        dispatch(analytic(analytics));
    };

    const virtualBooth = (): boolean => {
        if (orderOptions.scheduleEnabled && virtualBooths.some(booth => booth.entityId === props.vendor.vendorId || booth.entityId === props.vendor.brokerId)) {
            return true;
        }
        return false;
    };

    return (
        <div className={styles.header}>
            {(props.vendor.logo && props.vendor.logo.length > 0) &&
                <img src={props.vendor.logo} className={styles.img} title={props.vendor.vendorName} />
            }
            <span className={styles.name}>
                {props.vendor.vendorName}
            </span>
            {orderOptions.customerVendorInfoEnabled &&
                <span title="View vendor information" onClick={onInfo}>
                    <InfoCircleTwoTone style={{ fontSize: 24 }} />
                </span>
            }
            {props.showIcons &&
                <React.Fragment>
                    {orderOptions.messageEnabled &&
                        <span title="Send vendor a message" onClick={onMessage}>
                            <MessageTwoTone style={{ fontSize: 24 }} />
                        </span>
                    }
                    {orderOptions.customerEmailToVendorEnabled &&
                        <span title="Send vendor an email" onClick={onEmail}>
                            <MailTwoTone style={{ fontSize: 24 }} />
                        </span>
                    }
                    {virtualBooth() &&
                        <React.Fragment>
                            <span title="Schedule a meeting" onClick={onSchedule}>
                                <ClockCircleTwoTone style={{ fontSize: 24 }} />
                            </span>
                            <span title="View Booth" onClick={onViewBooth}>
                                <ShopTwoTone style={{ fontSize: 24 }} />
                            </span>
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </div>
    );
}
export const OrderGridVendor = React.memo(GridVendor);
