import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            margin: theme.spacing(1),
        },
    })
);

export default function EndCallButton() {
    //const dispatch = useAppDispatch();

    const classes = useStyles();
    const { room } = useVideoContext();

    const onEndCall = () => {
        //if (videoVisible) {
        room.disconnect();

        //dispatch(disableVideo());
        //}
    }

    return (
        <Tooltip title={'End Call'} onClick={onEndCall} placement="top" PopperProps={{ disablePortal: true }}>
            <Fab className={classes.fab} color="primary">
                <CallEnd />
            </Fab>
        </Tooltip>
    );
}
