import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import {
    selectMessages,
    selectActiveSession,
    sendMessage
} from 'store/messenger';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { selectUser, selectSettings } from 'store/user';
import { Message, MessageTypes, UserRole, UserSettings } from 'model/index';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';

import { getDateInMyTimeZone } from 'utility/utility';

import Linkify from 'linkify-react';

JavascriptTimeAgo.addLocale(en);

interface MessageGroup {
    messages: Message[],
    groupId: number,
    groupType: string
}
const ImageExtensionRegex = /(\.jpg|\.jpeg|\.png|\.svg|\.gif\.bmp)$/i;

interface MessengerState {
    windowExpanded: boolean,
    scrollToMessageEnd: any,
    onShowVideo: any
}

const MessageList: React.FC<MessengerState> = (props) => {
    const dispatch = useAppDispatch();

    const currentSessionMessages = useAppSelector(selectMessages);

    const user = useAppSelector(selectUser);

    const activeSession = useAppSelector(selectActiveSession);

    let groups: MessageGroup[] = [];

    const fileName = (text: string) => {
        if (props.windowExpanded) {
            return text;
        }
        if (text.length > 26) {
            return <span>{text.substr(0, 25)}  &hellip;</span>;
        }
        return text;
    };
    const deleteDocument = (message: Message) => {
        const deletedMessage: Message = { ...message, isDeleted: true }
        //console.log(deletedMessage);
        dispatch(sendMessage(deletedMessage));

        //axios.post("/messenger/documentdelete", message).then(function (result) {
        //    if (activeSession != null) {
        //        //dispatch(getMessagesAsync(activeSession));
        //        dispatch(sendMessage(message));
        //    }
        //});
    };

    const fileMessage = (message: Message) => {

        let showDelete: boolean = false;

        switch (user.role) {
            case UserRole.Customer:
                showDelete = message.senderId === activeSession.customerId;
                break;
            case UserRole.Vendor:
            case UserRole.Broker:
                showDelete = message.senderId === activeSession.vendorId;
                break;
            default:
                break;
        }

        //Render images inline
        if (ImageExtensionRegex.exec(message.url)) {
            return (
                <React.Fragment>
                    <a href={message.url} target="_blank">
                        <img className="image"
                            onLoad={props.scrollToMessageEnd}
                            src={message.url} />
                    </a>
                    {showDelete &&
                        <div style={{ paddingTop: 10, justifyContent: 'flex-end', display: 'flex' }}>
                            <DeleteOutlined style={{ fontSize: '18px', color: '#08c', paddingLeft: 10 }} title="Delete" onClick={() => deleteDocument(message)} />
                        </div>
                    }
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <a className="document" href={message.url} target="_blank">
                    <LinkOutlined style={{
                        fontSize: '18px',
                        color: '#08c',
                        paddingRight: '10px'
                    }} />
                    {fileName(message.body)}
                </a>
                {showDelete &&
                    <DeleteOutlined style={{ fontSize: '18px', color: '#08c', paddingLeft: '10px' }} title="Delete" onClick={() => deleteDocument(message)} />
                }
            </React.Fragment>
        );
    };

    const textMessage = (message: Message) => {
        return (
            <span>
                <Linkify options={{ target: '_blank' }}>
                    {message.body}
                </Linkify>
            </span>
        );
    };

    let activeMessages: Message[] = _.filter(currentSessionMessages, (message: Message) => {
        return !message.isDeleted;
    });

    if (activeMessages.length > 0) {

        let group: MessageGroup = {
            messages: [],
            groupId: 0,
            groupType: ""
        };

        activeMessages.map((message: Message, index: number) => {
            let groupType = "";
            if (!message.isDeleted) {
                switch (user.role) {
                    case UserRole.Customer:
                        groupType = message.senderId === activeSession.customerId ? "sender" : "recipient";
                        break;
                    case UserRole.Vendor:
                    case UserRole.Broker:
                        groupType = message.senderId === activeSession.vendorId ? "sender" : "recipient";
                        break;
                    default:
                        break;
                }
                if (message.messageType == MessageTypes.Video) {
                    group = {
                        messages: [message],
                        groupId: groups.length > 0 ? _.last(groups).groupId + 1 : 0,
                        groupType: "info"
                    };
                    groups.push(group);
                } else {
                    //First message
                    if (index === 0) {
                        group.groupType = groupType;
                        group.messages.push(message);
                        groups.push(group);
                    } else {
                        const lastGroup: MessageGroup = _.last(groups);
                        if (lastGroup.groupType != groupType) {
                            group = {
                                messages: [message],
                                groupId: lastGroup.groupId + 1,
                                groupType: groupType
                            };
                            groups.push(group);
                        } else {
                            group.messages.push(message);
                        }
                    }
                }
            }
        });
    }

    const groupRender = groups.map((group: MessageGroup) => {
        const lastMessage: Message = _.last(group.messages);

        const createdOn = () => {
            return getDateInMyTimeZone(lastMessage.createdOn);
        }

        const messages = group.messages.map((message: Message, index: number) => {
            let messageContent: any = null;
            if (!message.isDeleted) {
                switch (message.messageType) {
                    case MessageTypes.Text:
                        messageContent = textMessage(message);
                        break;
                    case MessageTypes.File:
                        messageContent = fileMessage(message);
                        break;
                    case MessageTypes.Video:
                        messageContent = <div>{message.body} </div>;
                        break;
                    default:
                        messageContent = textMessage(message);
                        break;
                }
            }
            return <div key={message.messageId} className="message">
                {messageContent}
            </div>;
        });

        return (
            <div key={group.groupId} className={group.groupType}>
                <div className="messages">
                    {messages}
                </div>
                <ReactTimeAgo date={createdOn()} />
            </div>
        );
    });

    if (groupRender.length > 0) {
        return (
            <React.Fragment>
                {groupRender}
            </React.Fragment>
        );
    } else {
        return (<div>No messages yet.</div>);
    }
}

export default MessageList;