import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button } from 'antd';
import {
    Broker,
    Vendor,
    AnalyticsType,
    AnalyticsProps,
    User,
    UserRole
} from 'model';

import {
    filterVendor,
    analytic
} from 'store/order';
import { selectUser } from 'store/user';

import { Communication } from 'components/vendor/Communication';
import { BrokerVendors } from 'components/vendor/BrokerVendors';

import history from 'store/history';
import styles from './Item.module.css';
interface Props {
    broker: Broker
}

export const BrokerItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const user: User = useAppSelector(selectUser);

    const vendorClickHandler = (vendor: Vendor) => {
        console.log("Vendor Click:", vendor.vendorId);
        //if (vendor.vendorId == DefaultGuid) {
        //    dispatch(resetAllFilters());
        //} else {
        //    dispatch(filterVendor(vendor));
        //}
        //scrollVendor = vendor;

        dispatch(filterVendor(vendor));
        history.push('/order');

        if (user.role == UserRole.Customer) {
            let analytics: AnalyticsProps = {
                id: vendor.vendorId,
                analyticsType: AnalyticsType.Vendor
            };
            dispatch(analytic(analytics));
        }
    };

    const image = props.broker.brokerLogo ? <img src={props.broker.brokerLogo} loading="lazy" /> : <span />;
    const orderOnClick = () => {
        //dispatch(filterVendor(props.vendor));
        //history.push('/order');
    };

    //const vendors = ;
    return (
        <div className={styles.item}>
            <div className={styles.logo}>
                {image}
            </div>
            <div className={styles.meta} >
                <div className={styles.title}>
                    <div className={styles.vendor} onClick={orderOnClick}>
                        {props.broker.brokerName}
                    </div>
                </div>
                <Communication manufacturer={props.broker} showInfo={false} />
                <BrokerVendors vendors={props.broker.vendors} />
            </div>
        </div>
    );
};
