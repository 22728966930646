import React, { useState } from 'react';
import * as log from 'loglevel';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import axios from 'axios';
import _ from "lodash";
import {
    useParams
} from "react-router-dom";

import {
    selectUser,
} from 'store/user';
import history from 'store/history';

import {
    selectVendors,
    selectBrokers,
    filterVendor,
    filterBroker,
    selectVirtualBooths,
    filterTypeOnly,
    selectCustomer,
    selectOrderOptions,
    filterBooth,
    selectBooth
} from 'store/order';

import {
    createSessionAsync,
    updateWindowVisibleStatus
} from 'store/messenger';

import {
    Vendor,
    Broker,
    MenuType,
    LinkButton,
    OrderFilter,
    OrderFilterType,
    MessengerSession,
    Customer,
    VirtualBooth,
    VirtualBoothSection,
    MediaProps,
    MediaType,
    UserRole,
    User,
    OrderOption,
    AnalyticsProps,
    AnalyticsType
} from 'model/index';

import { useAppState } from 'components/layout/AppStateProvider';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DefaultGuid } from '../../utility';
import { BrokerVendors } from 'components/vendor/BrokerVendors';
import { Empty } from 'antd';

interface State {
    currentIdex: number;
    currentBooth: VirtualBooth;
}

const BoothPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const vendors: Vendor[] = useAppSelector(selectVendors);

    const brokers: Broker[] = useAppSelector(selectBrokers);

    const allVirtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const selectedBooth: VirtualBooth = useAppSelector(selectBooth);

    const customer: Customer = useAppSelector(selectCustomer);

    const user: User = useAppSelector(selectUser);

    const virtualBooths: VirtualBooth[] = allVirtualBooths.filter((virtualBooth: VirtualBooth) => {
        return virtualBooth.entityId != orderOptions.hostVendorId;
    });

    const { id } = useParams<Record<string, string | undefined>>();

    const { showCompanyInfo, showMedia, logAnalytics } = useAppState();

    const [hotSpots, setHotSpots] = React.useState<VirtualBoothSection[]>([]);


    //const primeVendors: Vendor[] = vendors.filter((vendor: Vendor) => {
    //    return vendor.primeVendor === true;
    //});

    const [state, setState] = useState<State>({
        currentIdex: 0,
        currentBooth: {
            entityId: DefaultGuid,
            virtualBoothTypeId: 3,
            name: "Booth"
        }
    });

    React.useEffect(() => {
        document.title = `Booth | ${globalThis.showTitle}`;
        if (state.currentBooth.entityId != id) {
            let booth: VirtualBooth = virtualBooths.find(booth => {
                return booth.entityId == id;
            });
            if (booth) {
                dispatch(filterBooth(booth));
                if (id != DefaultGuid) {
                    getBoothData(id);
                }
            }
        }
    }, [id]);

    //React.useEffect(() => {
    //    if (virtualBooths && virtualBooths.length > 0 && id != DefaultGuid) {
    //        getBoothData(id);
    //    }
    //}, [virtualBooths]);

    const getBoothData = (id) => {

        //console.log("getBoothData", id);
        const currentIndex: number = _.findIndex(virtualBooths, (booth: VirtualBooth) => {
            return booth.entityId == id;
        });
        if (currentIndex > -1) {
            setState(prevState => {
                return {
                    ...prevState,
                    currentIdex: currentIndex
                }
            });
        }

        const booth: VirtualBooth = virtualBooths.find((booth: VirtualBooth) => {
            return booth.entityId == id;
        });

        if (booth) {
            //console.log("VirtualBooth", booth);
            setState(prevState => {
                return {
                    ...prevState,
                    currentBooth: booth
                }
            });

            axios.post<VirtualBoothSection[]>("/booth", booth).then(response => {
                if (response.data && response.data.length > 0) {
                    setHotSpots(response.data);

                    //log.debug("Booth", response.data);

                } else {
                    const background: VirtualBoothSection = {
                        id: 0,
                        imageH: 0,
                        imagePath: "https://tradeshoweasy.s3.us-east-2.amazonaws.com/asset/booth/booth-background.jpg",
                        imageType: "Background",
                        imageUrl: null,
                        imageW: 0,
                        imageX: 0,
                        imageY: 0,
                        priority: 0
                    };
                    setHotSpots([background]);
                }
            });

            let analytics: AnalyticsProps = {
                id: id,
                analyticsType: AnalyticsType.Booth
            };
            logAnalytics(analytics);

            const vendor = vendors.find((vendor: Vendor) => {
                return vendor.vendorId === id;
            });
            if (vendor) {
                dispatch(filterVendor(vendor));
            } else {
                const broker = brokers.find((broker: Broker) => {
                    return broker.brokerId === id;
                });
                if (broker) {
                    dispatch(filterBroker(broker));
                }
            }
        }
    };

    const imgOnClick = (virtualBoothSection: VirtualBoothSection) => {
        //console.log(virtualBoothSection);

        let menuType: MenuType = MenuType[virtualBoothSection.imageType];
        switch (menuType) {
            case MenuType.Image:
                window.open(virtualBoothSection.imagePath);
                break;
            case MenuType.Video:
                let media: MediaProps = {
                    mediaType: MediaType.Video,
                    url: virtualBoothSection.imageUrl,
                    title: `${selectedBooth.name}`
                };
                showMedia(media);
                break;
            case MenuType.Link:
                window.open(virtualBoothSection.imageUrl);
                break;
            default:
                menuItemOnClick(menuType);
                break;
        }
    };

    const menuItemOnClick = (menuType: MenuType) => {
        //console.log(menuType);

        let filter: OrderFilter = {
            filterType: OrderFilterType.All,
            query: ""
        };
        switch (menuType) {
            case MenuType.Order:
            case MenuType.Raffles:
                filter.filterType = OrderFilterType.All;
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.MessageCenter:
                history.push('/message-center');
                break;
            case MenuType.CheckMessages:
                if (user.role == UserRole.Customer) {
                    let messengerSession: MessengerSession = {
                        customerId: customer.customerId,
                        vendorId: selectedBooth.entityId
                    };
                    dispatch(createSessionAsync(user.role, messengerSession));
                } else {
                    dispatch(updateWindowVisibleStatus(true));
                }

                break;
            case MenuType.MyMeetingSchedule:
                history.push('/meeting-schedule');
                break;
            case MenuType.ScheduleMeeting:
                if (user.role == UserRole.Customer) {
                    history.push('/schedule');
                } else {
                    history.push('/meeting-schedule');
                }
                break;
            case MenuType.Booths:
                history.push('/booths');
                break;
            case MenuType.PrimeDeals:
                filter.filterType = OrderFilterType.Prime;
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.NewItems:
                filter.filterType = OrderFilterType.New;
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.Videos:
                filter.filterType = OrderFilterType.Videos;
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.Contracts:
                filter.filterType = OrderFilterType.Contracts;
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.Document:
                filter.filterType = OrderFilterType.Documents;
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.Website:
            case MenuType.VendorInfo:
            case MenuType.Logo:
            case MenuType.Contact:
                showCompanyInfo(state.currentBooth.entityId);
                break;
            case MenuType.Lobby:
                history.push('/lobby');
                break;
            default:
                break;
        }
    };

    const hotLinks: any = () => {
        return hotSpots.map((spot: VirtualBoothSection, index: number) => {
            if (spot.imagePath && spot.imagePath.length > 0) {
                if (spot.imageType == "Background") {
                    return <image
                        id={`${spot.id}`}
                        key={`button-${index++}`}
                        width="100%"
                        href={spot.imagePath}
                        xlinkHref={spot.imagePath}
                    />;
                } else {
                    //console.log("imageType:", spot.imageType, "imageUrl", spot.imageUrl);
                    let imageProps: any = {
                        href: spot.imagePath,
                        xlinkHref: spot.imagePath,
                        className: "link-button"
                    };
                    //className: (spot.imageType == MenuType.Image && spot.imageUrl && spot.imageUrl.length > 0) ? "link-button" : ""
                    if (spot.imageW > 0) {
                        imageProps.width = spot.imageW;
                    }
                    if (spot.imageH > 0) {
                        imageProps.height = spot.imageH;
                    }

                    return <image
                        id={`${spot.id}`}
                        key={`button-${index++}`}
                        className="link-button"
                        x={spot.imageX}
                        y={spot.imageY}
                        onClick={() => imgOnClick(spot)} {...imageProps} />;
                }
            } else {
                return null;
            }
        });
    };

    const buttonContent = (): LinkButton[] => {
        let links: LinkButton[] = [];
        const vendor = vendors.find((vendor: Vendor) => {
            return vendor.vendorId === id;
        });
        const broker = brokers.find((broker: Broker) => {
            return broker.brokerId === id;
        });
        //console.log(broker.vendors);
        if (vendor) {
            //if (vendor.videos) {
            //    links.push({ name: "Video", menuType: MenuType.Videos });
            //}
            links.push({ name: "Website", menuType: MenuType.Website });
            links.push({ name: "Contact", menuType: MenuType.Website });
            if (orderOptions.messageEnabled) {
                links.push({ name: "Leave A Message", menuType: MenuType.CheckMessages });
            }
            if (orderOptions.primeDealsEnabled && vendor.primeVendor) {
                links.push({ name: orderOptions.primeDealsTitle, menuType: MenuType.Order });
            }
            if (orderOptions.scheduleEnabled) {
                links.push({ name: "Request Meeting", menuType: MenuType.ScheduleMeeting });
            }
            if (vendor.documents) {
                links.push({ name: "Resources", menuType: MenuType.Order });
            }
            if (vendor.contracts) {
                links.push({ name: "Contracts", menuType: MenuType.Contracts });
            }
            links.push({ name: "Shop Now", menuType: MenuType.Order });
            links.push({ name: "Back To Lobby", menuType: MenuType.Lobby });
        }
        if (broker) {
            if (broker.vendors && broker.vendors.some(vendor => vendor.videos)) {
                links.push({ name: "Video", menuType: MenuType.Videos });
            }
            links.push({ name: "Website", menuType: MenuType.Website });
            links.push({ name: "Contact", menuType: MenuType.Website });
            if (orderOptions.messageEnabled) {
                links.push({ name: "Leave A Message", menuType: MenuType.CheckMessages });
            }
            if (orderOptions.primeDealsEnabled && broker.vendors.some(vendor => vendor.primeVendor)) {
                links.push({ name: orderOptions.primeDealsTitle, menuType: MenuType.Order });
            }
            if (orderOptions.scheduleEnabled) {
                links.push({ name: "Request Meeting", menuType: MenuType.ScheduleMeeting });
            }
            if (broker.vendors && broker.vendors.some(vendor => vendor.documents)) {
                links.push({ name: "Resources", menuType: MenuType.Order });
            }
            if (broker.vendors && broker.vendors.some(vendor => vendor.contracts)) {
                links.push({ name: "Contracts", menuType: MenuType.Contracts });
            }
            links.push({ name: "Shop Now", menuType: MenuType.Order });
            links.push({ name: "Back To Lobby", menuType: MenuType.Lobby });
        }

        return links;

    }

    const brokerContent: any = () => {

        const broker = brokers.find((broker: Broker) => {
            return broker.brokerId === id;
        });
        if (broker) {
            return (
                <div className="broker-vendor-list">
                    {broker.vendors &&
                        <BrokerVendors vendors={broker.vendors} />
                    }
                </div>
            );
        } else {
            return null;
        }

    }

    const moveBoothOnClick = (increase: boolean) => {
        //var color: Color = Color[green];
        const newIndex: number = increase ? Number(state.currentIdex) + 1 : Number(state.currentIdex) - 1;
        //console.log(newIndex);
        if (newIndex > -1 && newIndex < virtualBooths.length) {
            let newBooth: VirtualBooth = virtualBooths[newIndex];
            history.push(`/booth/${newBooth.entityId}`);
            setState(prevState => {
                return {
                    ...prevState,
                    currentIdex: newIndex
                }
            });
            getBoothData(newBooth.entityId);
        }
    }

    if (state.currentBooth && state.currentBooth.entityId != DefaultGuid) {

        const buttonLinks = buttonContent();

        return (
            <div className="page-content">
                <div className="header">
                    <div className="left" >
                        {(virtualBooths && virtualBooths.length > 0 && state.currentIdex) > 0 &&
                            <div onClick={() => moveBoothOnClick(false)}>
                                <LeftOutlined /> previous booth
                            </div>
                        }
                    </div>
                    <div className="center">
                        {state.currentBooth.name}
                    </div>
                    <div className="right">
                        {(virtualBooths && virtualBooths.length > 0 && state.currentIdex < (virtualBooths.length - 1)) &&
                            <div onClick={() => moveBoothOnClick(true)}>
                                next booth <RightOutlined />
                            </div>
                        }
                    </div>
                </div>
                <div className="booth-page">
                    <div>
                        {brokerContent()}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 733">
                        {hotLinks()}
                        {buttonLinks.length > 0 &&
                            <foreignObject
                                className="node"
                                x={170}
                                y={575}
                                width={900}
                                height={80}>
                                <div className="button-list">
                                    {buttonLinks.map((button: LinkButton, index: number) => {
                                        index++;
                                        return <div key={`booth-button-${index}`}
                                            onClick={() => menuItemOnClick(button.menuType)}>
                                            {button.name}
                                        </div>;
                                    })}
                                </div>
                            </foreignObject>
                        }
                    </svg>
                </div>
            </div>
        );
    } else {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

};

export default BoothPage;