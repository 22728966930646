import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectUser,
} from 'store/user';
import {
    selectShow
} from 'store/order';
import { User } from 'model';

const SupportPage: React.FC = () => {

    const user: User = useAppSelector(selectUser);

    const show = useAppSelector(selectShow);

    React.useEffect(() => {
        document.title = `Support | ${globalThis.showTitle}`;
    }, []);

    return (
        <div className="page-content">
            <h1>
                Support
            </h1>
            <div className="content-html"  >

                <p>Need help? In order to efficiently assist you, please provide this information:</p>
                <p><span className="bold">Program:</span> {show.title}</p>
                <p><span className="bold">{user.role}:</span> {user.company} [{user.accountNo}]</p>
                <p><span className="bold">App Version:</span> {globalThis.appVersion}</p>


                {/* <p>Please call 630-656-1133 or email <a href="mailto:support@tmaplus.com">support@tmaplus.com</a></p>
               
                <p><span className="bold"> How To Eliminate Conference Call Echo & Feedback</span></p>
                <ul>
                    <li>Plug in headphones or headset if you can.</li>
                    <li>If you can't use headphone or headset, attendees should mute themselves when they're not speaking.</li>
                </ul>
                <p><span className="bold">Google Chrome Settings:</span> If you are having camera & microphone issues, please click on the padlock icon and make sure Camera and Microphone access is allowed. </p>
                <p><img src="https://tradeshoweasy.s3-us-east-2.amazonaws.com/media/1/5471430c-1823-4430-b5e3-02b76ca02b8a.jpg" alt="Google Chrome Settings" /> </p>
                <p><span className="bold">Video Troubleshoot: </span>
                    <a href="https://networktest.twilio.com/" target="_blank">Please troubleshoot video services here.</a>
                </p> */}

            </div>
        </div>
    );
};

export default SupportPage;