import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    UserRole,
    Customer,
    User,
    Company,
    defaultVendor
} from 'model/index';
import { DefaultGuid } from 'utility/utility';
import { Tooltip } from 'antd';
import {
    MessageTwoTone,
    MailTwoTone,
    CloseOutlined
} from '@ant-design/icons';
import _ from "lodash";
import {
    selectCustomer,
    resetCustomer,
    selectMessageEnabled,
    selectHideAllModals
} from 'store/order';
import { selectUser } from 'store/user';
import { CustomerSearch } from './CustomerSearch';
import { useAppState } from 'components/layout/AppStateProvider';
import {
    Button,
    Modal
} from 'antd';

import { VendorMessageCenter } from 'components/messagecenter/VendorMessageCenter';


interface State {
    windowVisible: boolean
}

export const VendorCustomer: React.FC = () => {

    const dispatch = useAppDispatch();

    const user: User = useAppSelector(selectUser);

    const customer: Customer = useAppSelector(selectCustomer);

    const messageEnabled = useAppSelector(selectMessageEnabled);

    const { vendorOnMessage, email } = useAppState();

    const hideAllModals: boolean = useAppSelector(selectHideAllModals);

    const [state, setState] = useState<State>({
        windowVisible: false
    });

    const selectCustomerOnClick = () => {
        //console.log("SELECT CUSTOMER");
        setState(prevState => {
            return {
                ...prevState,
                windowVisible: true
            }
        });
    };

    const resetOnClick = () => {
        setState(prevState => {
            return {
                ...prevState,
                windowVisible: false
            }
        });
    };

    //Create a messenger session from vendor or broker
    const onSendMessage = () => {
        vendorOnMessage(customer.customerId);
    }

    const onSendEmail = () => {
        const sender: Company = {
            id: user.id,
            name: user.company
        };
        const recipient: Company = {
            id: customer.customerId,
            name: customer.customerName
        };
        email(sender, recipient);
    }

    const changeCustomerClick = () => {
        switch (user.role) {
            case UserRole.Vendor:
            case UserRole.Broker:
                dispatch(resetCustomer());
                break;
            default:
                break;
        }
    };

    const CustomerNameIcon = () => {
        if (messageEnabled) {
            return (
                <div
                    className="customer-name">
                    <span>{customer.customerName} [{customer.customerAccountId}] </span>
                    <Tooltip title="Send a message to the customer." placement="bottom">
                        <MessageTwoTone style={{ fontSize: 24 }} onClick={onSendMessage} />
                    </Tooltip>
                    <Tooltip title="Send an email to the customer." placement="bottom">
                        <MailTwoTone style={{ fontSize: 24 }} onClick={onSendEmail} />
                    </Tooltip>

                </div>
            );
        } else {
            return (
                <div className="customer-name">
                    <span>{customer.customerName} [{customer.customerAccountId}] </span>
                </div>
            );
        }
    }

    const CustomerName = () => {
        return (
            <React.Fragment >
                <div className="filter-item">
                    <CustomerNameIcon />
                </div>
                <div className="filter-item">
                    <Button type="link" onClick={changeCustomerClick}>
                        Change Customer
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    const SelectCustomer = () => {
        return (
            <div className="filter-item">
                <Button type="primary" size="middle" onClick={selectCustomerOnClick} style={{ paddingTop: 0, paddingBottom: 0 }}>
                    SELECT CUSTOMER
                </Button>
            </div>
        );
    }
    const modalWindowConfig: any = {
        className: "modal-window",
        width: 800,
        maskClosable: false,
        style: {
            top: 0,
            paddingBottom: 0
        },
        closable: true,
        closeIcon: <React.Fragment> CLOSE <CloseOutlined /></React.Fragment>,
        destroyOnClose: true
    };

    const windowOnOk = () => {
        //console.log("windowOnOk");
        setState(prevState => {
            return {
                ...prevState,
                windowVisible: false
            }
        });
        //Modal.destroyAll();
    };

    React.useEffect(() => {
        document.title = `Message Center | ${globalThis.showTitle}`;
    }, []);

    React.useEffect(() => {
        if (hideAllModals && state.windowVisible) {
            setState(prevState => {
                return {
                    ...prevState,
                    windowVisible: false
                }
            });
        }
    }, [hideAllModals]);


    if (user.role == UserRole.Customer) {
        return <React.Fragment />;
    } else {
        if (customer.customerId == DefaultGuid) {
            return (
                <React.Fragment>
                    <Modal
                        className="media-modal"
                        open={state.windowVisible}
                        title="Search Customers"
                        onOk={windowOnOk}
                        onCancel={windowOnOk}
                        cancelButtonProps={{ hidden: true }}
                        okButtonProps={{ hidden: true }}
                        footer={null}
                        {...modalWindowConfig}
                    >
                        <VendorMessageCenter isStandAlone={true} />
                    </Modal>

                    <SelectCustomer />
                </React.Fragment>

            );
        } else {
            return <CustomerName />;
        }
    }
};
