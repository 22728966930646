import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    UserRole,
    MenuType,
    User,
    OrderOption,
    MessengerSession,
    VirtualBooth
} from 'model/index';
import history from 'store/history';
import {
    resetAllFilters,
    selectOrderOptions,
    selectVirtualBooths
} from 'store/order';
import {
    updateWindowVisibleStatus,
    selectSessions
} from 'store/messenger';
import {
    selectUser
} from 'store/user';
import { useAppState } from './AppStateProvider';
import {
    QuestionCircleTwoTone,
    InfoCircleTwoTone,
    BellTwoTone,
    ClockCircleTwoTone,
    MailTwoTone,
    ShoppingTwoTone,
    CalendarTwoTone,
    HomeTwoTone,
    ProfileTwoTone,
    ContainerTwoTone,
    MessageTwoTone,
    ShopTwoTone,
} from '@ant-design/icons';
import { Button, Drawer } from 'antd';

export const NavigationComponent: React.FC = () => {

    const dispatch = useAppDispatch();
    const user: User = useAppSelector(selectUser);
    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);
    const virtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);
    const sessions: MessengerSession[] = useAppSelector(selectSessions);
    const sessionsWithMeetings = sessions.filter((messengerSession: MessengerSession) => {
        return messengerSession.meetingDate != null;
    });

    const { drawerStatus, showHideDrawer } = useAppState();

    const menuItemOnClick = (menuType: MenuType) => {

        showHideDrawer(false);

        switch (menuType) {
            case MenuType.Lobby:
                history.push('/lobby');
                break;
            case MenuType.HostBooth:
                history.push('/host-booth');
                break;
            case MenuType.Order:
                dispatch(resetAllFilters());
                history.push('/order');
                break;
            case MenuType.OrderSummary:
                history.push('/order-summary');
                break;
            case MenuType.MessageCenter:
                history.push('/message-center');
                break;
            case MenuType.CheckMessages:
                dispatch(updateWindowVisibleStatus(true));
                history.push('/message-center');
                break;
            case MenuType.MyMeetingSchedule:
                history.push('/meeting-schedule');
                break;
            case MenuType.ScheduleMeeting:
                history.push('/schedule');
                break;
            case MenuType.ShowInfo:
                history.push('/show-info');
                break;
            case MenuType.Instructions:
                history.push('/instructions');
                break;
            case MenuType.Support:
                history.push('/support');
                break;
            case MenuType.Announcements:
                history.push('/announcements');
                break;
            case MenuType.VendorPortal:
                localStorage.removeItem(globalThis.app.tokenName);
                window.location.href = "https://www.tradeshoweasy.com/vendor/report/order/list/";
                break;
            case MenuType.Booths:
                history.push('/booths');
                break;
            default:
                break;
        }
    };

    const onClose = () => {
        showHideDrawer(false);
    };

    const isCustomer: boolean = user.role == UserRole.Customer;

    const virtualBoothTitle: string = orderOptions.virtualBoothEnabled ? `${orderOptions.virtualBoothTitle} Booths` : "";

    return (
        <Drawer
            title={globalThis.showTitle}
            className="main-menu"
            placement="top"
            closable={true}
            onClose={onClose}
            open={drawerStatus}
            maskClosable={true}
            height={300}
        >
            <div className="main-menu-content">
                {orderOptions.lobbyEnabled &&
                    <Button type="link" onClick={() => menuItemOnClick(MenuType.Lobby)} icon={<HomeTwoTone />} title="Lobby"> Lobby</Button>
                }
                <Button type="link" onClick={() => menuItemOnClick(MenuType.Order)} icon={<ShoppingTwoTone />} title="Order"> Order</Button>
                {isCustomer &&
                    <React.Fragment>
                        <Button type="link" onClick={() => menuItemOnClick(MenuType.OrderSummary)} icon={<ProfileTwoTone />} title="Order Summary"> Order Summary</Button>
                        {(orderOptions.virtualBoothEnabled && virtualBooths && virtualBooths.length > 0) &&
                            <Button type="link" onClick={() => menuItemOnClick(MenuType.Booths)} icon={<ShopTwoTone />} title={virtualBoothTitle}> {virtualBoothTitle}</Button>
                        }
                        {orderOptions.hostBoothEnabled &&
                            <Button type="link" onClick={() => menuItemOnClick(MenuType.HostBooth)} icon={<ShopTwoTone />} title="Host Booth"> Host Booth</Button>
                        }
                        {orderOptions.messageEnabled &&
                            <React.Fragment>
                                <Button type="link" onClick={() => menuItemOnClick(MenuType.MessageCenter)} icon={<MailTwoTone />} title="Message Center"> Message Center</Button>
                                <Button type="link" onClick={() => menuItemOnClick(MenuType.CheckMessages)} icon={<MessageTwoTone />} title="Check Messages"> Send/Check Messages</Button>
                            </React.Fragment>
                        }
                        {orderOptions.scheduleEnabled &&
                            <Button type="link" onClick={() => menuItemOnClick(MenuType.ScheduleMeeting)} icon={<ClockCircleTwoTone />} title="Schedule Meeting"> Schedule Meetings</Button>
                        }
                    </React.Fragment>
                }
                {!isCustomer &&
                    <React.Fragment>
                        {(orderOptions.virtualBoothEnabled && virtualBooths && virtualBooths.length > 0) &&
                            <Button type="link" onClick={() => menuItemOnClick(MenuType.Booths)} icon={<ShopTwoTone />} title={virtualBoothTitle}> {virtualBoothTitle}</Button>
                        }
                        {orderOptions.messageEnabled &&
                            <React.Fragment>
                                <Button type="link" onClick={() => menuItemOnClick(MenuType.MessageCenter)} icon={<MailTwoTone />} title="Message Center"> Message Center</Button>
                                <Button type="link" onClick={() => menuItemOnClick(MenuType.CheckMessages)} icon={<MessageTwoTone />} title="Check Messages"> Send/Check Messages</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {(sessionsWithMeetings && sessionsWithMeetings.length > 0) &&
                    <Button type="link" onClick={() => menuItemOnClick(MenuType.MyMeetingSchedule)} icon={<CalendarTwoTone />} title="Meeting Schedule"> My Meetings</Button>
                }
                <Button type="link" onClick={() => menuItemOnClick(MenuType.Announcements)} icon={<BellTwoTone />} title="Announcements"> Announcements</Button>
                {!isCustomer &&
                    <Button type="link" onClick={() => menuItemOnClick(MenuType.VendorPortal)} icon={<ProfileTwoTone />} title="Vendor Portal"> Vendor Portal</Button>
                }
                <Button type="link" onClick={() => menuItemOnClick(MenuType.ShowInfo)} icon={<InfoCircleTwoTone />} title="Show Info"> Show Info</Button>
                <Button type="link" onClick={() => menuItemOnClick(MenuType.Instructions)} icon={<ContainerTwoTone />} title="Instructions"> Instructions</Button>
                <Button type="link" onClick={() => menuItemOnClick(MenuType.Support)} icon={<QuestionCircleTwoTone />} title="Support"> Support</Button>
            </div>
        </Drawer>
    );
};

export const Navigation = React.memo(NavigationComponent);

