import React, { useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Input, Layout, Button as CustomButton, Select, Empty } from 'antd';

const { Search } = Input;
const { Option, OptGroup } = Select;
import useDebounce from 'components/hooks/useDebouncedEffect';
import {
    selectVendors,
    selectBrokers,
    selectOrderOptions
} from 'store/order';
import { selectUser } from 'store/user';
import {
    Vendor,
    Broker,
    User,
    UserRole,
    OrderOption
} from 'model/index';

import { VendorItem } from './Vendor';

import { BrokerItem } from './Broker';

import styles from './Index.module.css';
import { Virtuoso } from 'react-virtuoso';
const { Header, Content } = Layout;

import useWindowDimensions from 'components/hooks/useWindowDimensions';

export enum VendorFilterType {
    AllVendors = "AllVendors",
    AllBrokers = "AllBrokers",
    Prime = "Prime",
    Category = "Category",
}

interface State {
    filterType: VendorFilterType;
    filterDetail: string;
    searchText: string;
    defaultText: string;
    currentRecords: Vendor[] | Broker[];
    isFiltered: boolean;
}
//|| Broker[]
export const CustomerMessageCenter: React.FC = () => {

    const virtuoso = useRef(null);

    const allVendors = useAppSelector(selectVendors);

    const allBrokers: Broker[] = useAppSelector(selectBrokers);

    const user: User = useAppSelector(selectUser);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const { height, width } = useWindowDimensions();

    const [state, setState] = useState<State>({
        filterType: VendorFilterType.AllVendors,
        filterDetail: "",
        searchText: "",
        defaultText: "",
        currentRecords: allVendors,
        isFiltered: false
    });

    React.useEffect(() => {
        document.title = `Message Center | ${globalThis.showTitle}`;
    }, []);

    useDebounce(() => {
        updateGrid();

    }, 100, [state.searchText, state.filterType, state.filterDetail]);

    const updateGrid = () => {
        //console.log("filterType:", state.filterType, "filterDetail", state.filterDetail, "searchText", state.searchText);

        let isFiltered: boolean = false;
        let filteredRecords: Vendor[] | Broker[] = allVendors;

        switch (state.filterType) {
            case VendorFilterType.Prime:
                filteredRecords = allVendors.filter((vendor: Vendor) => {
                    return vendor.primeVendor === true;
                });
                isFiltered = true;
                break;
            case VendorFilterType.AllVendors:
                filteredRecords = allVendors;
                break;
            case VendorFilterType.AllBrokers:
                filteredRecords = allBrokers;
                break;
            case VendorFilterType.Category:
                filteredRecords = allVendors.filter((vendor: Vendor) => {
                    return vendor.category != null && vendor.category.indexOf(state.filterDetail) > -1;
                });
                isFiltered = true;
                break;
            default:
                break;
        }

        if (state.searchText.length > 0) {
            isFiltered = true;
            let searchFor = new RegExp(state.searchText, 'i');
            if (state.filterType == VendorFilterType.AllBrokers) {
                filteredRecords = allBrokers.filter((broker: Broker) => {
                    return searchFor.test(broker.brokerName);
                });
            } else {
                filteredRecords = allVendors.filter((vendor: Vendor) => {
                    return searchFor.test(vendor.vendorName) || searchFor.test(vendor.vendorAccountId);
                });
            }
        }

        setState(prevState => {
            return {
                ...prevState,
                currentRecords: filteredRecords,
                isFiltered: isFiltered
            }
        });

        if (virtuoso.current) {
            virtuoso.current.scrollToIndex(0);
        }
    };

    const handleTextChange = (searchText: string) => {
        setState(prevState => {
            return {
                ...prevState,
                searchText: searchText
            }
        });
    };

    const handleFilterChange = (value: any) => {

        if (value.indexOf("category:") > -1) {
            //console.log(value.toString().replace("category:", ""));
            setState(prevState => {
                return {
                    ...prevState,
                    filterDetail: value.toString().replace("category:", ""),
                    filterType: VendorFilterType.Category
                }
            });

        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    filterType: value
                }
            });
        }
    };

    const resetSearch = () => {
        setState(prevState => {
            return {
                ...prevState,
                defaultText: "",
                searchText: "",
                filterDetail: "",
                filterType: VendorFilterType.AllVendors
            }
        });
    };

    const searchVendor = (searchText: string) => {
        //console.log(searchText);
        setState(prevState => {
            return {
                ...prevState,
                searchText: searchText
            }
        });
    };

    const getValue = () => {
        let result: string = "";
        switch (state.filterType) {
            case VendorFilterType.Category:
                result = `category:${state.filterDetail}`;
                break;
            default:
                result = state.filterType;
                break;
        }
        //console.log("FILTER VALUE", result);
        return result;
    };

    const getOptions = () => {
        const children = [];
        let key: number = 1;
        children.push(<Option key={key++} value={VendorFilterType.AllVendors}>All Vendors</Option>);
        children.push(<Option key={key++} value={VendorFilterType.AllBrokers}>All Brokers</Option>);

        if (user.role == UserRole.Customer && orderOptions.virtualBoothEnabled && orderOptions.virtualBoothTitle && orderOptions.virtualBoothTitle.length > 0) {
            children.push(<Option key={VendorFilterType.Prime} value={VendorFilterType.Prime}>{orderOptions.virtualBoothTitle} Booths</Option>);
        }

        let categoriesSet = new Set();

        allVendors.map((vendor: Vendor) => {

            if (vendor.category != null && vendor.category.length > 0) {
                try {
                    let multipleCategories = vendor.category.split(",");
                    multipleCategories.forEach(category => {
                        categoriesSet.add(category);
                    });
                } catch (e) {

                }
            }
        });

        let categories = Array.from(categoriesSet).sort();
        if (categories.length > 0) {
            let categoryGroup = <OptGroup key="category" label="Categories">
                {categories.map((category: string) => {
                    return <Option key={`category:${category}`} value={`category:${category}`}>{category}</Option>;
                })
                }
            </OptGroup>;
            children.push(categoryGroup);
        }
        return children;
    };

    const getItem = (index: number) => {
        switch (state.filterType) {
            case VendorFilterType.AllBrokers:
                const broker = state.currentRecords[index] as Broker;
                return <BrokerItem key={index} broker={broker} />;
            default:
                const vendor = state.currentRecords[index] as Vendor;
                return <VendorItem key={index} vendor={vendor} />;
        }
    };

    const getRowCount = () => {
        if (state.currentRecords.length === 0) {
            return "No records found";
        } else if (state.currentRecords.length === 1) {
            return state.filterType == VendorFilterType.AllBrokers ? "1 broker." : "1 vendor.";
        } else {
            return state.filterType == VendorFilterType.AllBrokers ? `${state.currentRecords.length} brokers.` : `${state.currentRecords.length} vendors.`;
        }
    };

    const contentStyle = () => {
        if (height < 768) {
            return {
                marginTop: 125,
                height: 560
            };
        } else {
            return {
                marginTop: 125,
            };
        }
    }

    return (
        <Layout style={{ height: '100%' }}>
            <Header style={{
                position: 'fixed',
                zIndex: 1,
                padding: 0,
                margin: 0,
                width: '100%',
                backgroundColor: '#FFFFFF'
            }}>
                <h1 className="page-header">
                    Message Center
                </h1>
                <div className={styles.header}>
                    <div className={styles.filter}>
                        <Input.Group compact style={{ marginTop: '15px' }}>
                            <Select size="large" style={{ width: 150 }}
                                onChange={handleFilterChange}
                                filterOption={(input, option) =>
                                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                optionFilterProp="children"
                                value={getValue()}>
                                {getOptions()}
                            </Select>
                            <Search
                                style={{ width: 400 }}
                                placeholder="Search company..."
                                onSearch={value => searchVendor(value)}
                                onChange={e => handleTextChange(e.target.value)}
                                allowClear
                                size="large"
                                value={state.searchText}
                            />
                            {state.isFiltered &&
                                <CustomButton type="link" onClick={() => resetSearch()} size="large">Reset</CustomButton>
                            }
                        </Input.Group>
                    </div>
                    <div className={styles.count}>
                        {getRowCount()}
                    </div>
                </div>
            </Header>
            <Content style={contentStyle()}>
                {(state.currentRecords && state.currentRecords.length > 0) ?
                    <Virtuoso
                        ref={virtuoso}
                        style={{ flex: 1 }}
                        totalCount={state.currentRecords.length}
                        itemContent={getItem}
                    /> : <div className="center-content"> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
            </Content>
        </Layout>
    );
};