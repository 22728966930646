import { Callback } from '../../../types';
import EventEmitter from 'events';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';


import { MessengerSession, MessengerVideoSession } from 'model/index';


import {
    disableVideo,
    enableVideo,
    videoConnected,
    videoDisconnected
} from 'store/messenger';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
    // @ts-ignore
    const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
    const [isConnecting, setIsConnecting] = useState(false);
    const localTracksRef = useRef<LocalTrack[]>([]);
    const optionsRef = useRef(options);

    const dispatch = useAppDispatch();

    useEffect(() => {
        // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
        // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
        // once the user is connected to the room.
        localTracksRef.current = localTracks;
    }, [localTracks]);

    useEffect(() => {
        // This allows the connect function to always access the most recent version of the options object. This allows us to
        // reliably use the connect function at any time.
        optionsRef.current = options;
    }, [options]);

    const connect = useCallback(
        token => {
            setIsConnecting(true);

            return Video.connect(token, { ...optionsRef.current, tracks: [] }).then(newRoom => {

                setRoom(newRoom);

                //console.log("ROOM CONNECTED:", newRoom);

                const messengerVideoSession: MessengerVideoSession = {
                    sessionId: Number(newRoom.name.split("-")[2]),
                }

                dispatch(videoConnected(messengerVideoSession));

                const disconnect = () => newRoom.disconnect();

                newRoom.once('disconnected', () => {

                    //console.log("ROOM DISCONNECTED:", newRoom);

                    // Reset the room only after all other `disconnected` listeners have been called.
                    // @ts-ignore
                    //setTimeout(() => setRoom(new EventEmitter() as Room));

                    window.removeEventListener('beforeunload', disconnect);

                    window.removeEventListener('disconnectFromRoom', disconnect);

                    if (isMobile) {
                        window.removeEventListener('pagehide', disconnect);
                    }

                    globalThis.twilioRoom = undefined;

                    dispatch(videoDisconnected(messengerVideoSession));

                    //dispatch(disableVideo());

                });

                globalThis.twilioRoom = newRoom;

                localTracksRef.current.forEach(track =>
                    // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
                    // However, tracks must be published manually in order to set the priority on them.
                    // All video tracks are published with 'low' priority. This works because the video
                    // track that is displayed in the 'MainParticipant' component will have it's priority
                    // set to 'high' via track.setPriority()
                    newRoom.localParticipant.publishTrack(track, { priority: track.kind === 'video' ? 'low' : 'standard' })
                );

                setIsConnecting(false);

                // Add a listener to disconnect from the room when a user closes their browser
                window.addEventListener('beforeunload', disconnect);

                window.addEventListener('disconnectFromRoom', disconnect);

                if (isMobile) {
                    // Add a listener to disconnect from the room when a mobile user closes their browser
                    window.addEventListener('pagehide', disconnect);
                }
            },
                error => {
                    onError(error);
                    setIsConnecting(false);
                }
            );
        },
        [onError]
    );

    return { room, isConnecting, connect };
}
