import React, { useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
    selectUser,
    showMessage
} from 'store/user';

import {
    selectVendors,
    selectVendor,
    selectBroker,
    selectCustomer,
    selectOrderOptions,
    selectColumns,
    selectFilterType,
    selectSearchQuery,
    selectItemCount,
    selectItems,
    selectVendorGroups,
    getItemsAsync,
    selectOrderMode,
    selectLocationGroups,
    selectLocations,
    selectDeliverySchedule,
    selectReadOnly,
    selectItemToRefresh,
    updateOrderAsync,
    getStatusAsync,
    selectCurrentItem,
    getItemAsync,
    setItem,
    selectReloadItems,
    setLoading,
    filterVendor
} from 'store/order';
import { CloseOutlined } from '@ant-design/icons';

import {
    OrderOption,
    Vendor,
    Broker,
    Customer,
    User,
    OrderFilter,
    OrderFilterType,
    ColumnSettings,
    defaultVendor,
    OrderGridFilterProps,
    OrderGridVendorProps,
    OrderMode,
    CatalogItem,
    OrderItem,
    CatalogOrder,
    UserRole,
    OrderItemRefresh
} from 'model/index';

import { ModalProps } from 'antd/lib/modal';

import { Spin, Modal, Tooltip } from 'antd';

import { GroupedVirtuoso } from 'react-virtuoso';
import useDebounce from 'components/hooks/useDebouncedEffect';
import { OrderGridVendor } from 'components/vendor/OrderGridVendor';

import { VideoRow, HeadlineRow, CommentRow, ImageRow, ContractRow, DocumentRow } from './Rows';
import { ItemRow } from './ItemRow';

import clsx from 'clsx';

import _ from "lodash";

import { InfoCircleTwoTone } from '@ant-design/icons';

import styles from './OrderGrid.module.css';

//import { nanoid } from 'nanoid';

import ChainOrderWindow from './ChainOrderWindow';
import { DefaultGuid } from 'utility/utility';


import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

interface ChainOrderState {
    windowVisible: boolean,
    catalogItem?: CatalogItem,
}

export const OrderGrid: React.FC = () => {
    const virtuoso = useRef(null);

    const dispatch = useAppDispatch();
    const user: User = useAppSelector(selectUser);
    const customer: Customer = useAppSelector(selectCustomer);
    const allVendors: Vendor[] = useAppSelector(selectVendors);
    const selectedVendor: Vendor = useAppSelector(selectVendor);
    const selectedBroker: Broker = useAppSelector(selectBroker);

    const orderColumns: ColumnSettings[] = useAppSelector(selectColumns);
    const orderFilter: OrderFilter = useAppSelector(selectFilterType);
    const searchQuery: string = useAppSelector(selectSearchQuery);
    const items: any = useAppSelector(selectItems);
    const vendorGroups: OrderGridVendorProps[] = useAppSelector(selectVendorGroups);
    const total: number = useAppSelector(selectItemCount);
    const readOnly = useAppSelector(selectReadOnly);
    const deliverySchedule = useAppSelector(selectDeliverySchedule);
    const locations = useAppSelector(selectLocations);
    const locationGroups = useAppSelector(selectLocationGroups);
    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);
    const orderMode: OrderMode = useAppSelector(selectOrderMode);
    const itemToRefresh: OrderItemRefresh = useAppSelector(selectItemToRefresh);

    const searchParams = useQuery();
    const vendorAccountId = searchParams.get("vendoraccountid");



    const reloadItems: number = useAppSelector(selectReloadItems);

    const currentItemStatus: OrderItem = useAppSelector(selectCurrentItem);

    const [chainState, setChainState] = useState<ChainOrderState>({
        windowVisible: false,
        catalogItem: null,
    });
    //nanoid(5)
    const [gridState, setGridState] = useState<any[]>([]);

    const [gridStartIndex, setGridStartIndex] = useState<number>(0);

    //const [isScrolling, setIsScrolling] = useState(false);
    //isScrolling = { setIsScrolling }

    const [visibleRange, setVisibleRange] = useState({
        startIndex: 0,
        endIndex: 0,
    });

    const [currentItemData, setCurrentItemData] = useState(null);

    const getData = (startIndex: number, includeVendors: boolean) => {

        //console.log("GET DATA startIndex:", startIndex, " includeVendors:", includeVendors, "visiblerange:", visibleRange);
        //console.log("final:", startIndex);

        let vendorAccountIds: string[] = [];

        if (selectedBroker.brokerId != DefaultGuid) {
            vendorAccountIds = _.map(selectedBroker.vendors, (vendor: Vendor) => vendor.vendorAccountId);
        }

        if (selectedVendor.vendorId != DefaultGuid) {
            vendorAccountIds = [selectedVendor.vendorAccountId];
        }

        let currentOrderFilter: OrderFilter = Object.assign({}, orderFilter);
        if (orderOptions.historyAverage && currentOrderFilter.filterType == OrderFilterType.History) {
            currentOrderFilter.filterType = OrderFilterType.HistoryAvarage;
        }

        const filter: OrderGridFilterProps = {
            filter: currentOrderFilter,
            startIndex: startIndex,
            vendorAccountId: vendorAccountIds,
            customerId: customer.customerId,
            includeVendors: includeVendors,
            query: searchQuery
        };

        dispatch(setLoading(true));

        dispatch(getItemsAsync(filter));

        setGridStartIndex(startIndex);

    }

    const updateGrid = () => {
        //console.log("updateGrid:", visibleRange.startIndex, visibleRange.endIndex);
        //
        if (items && items.length > 0) {
            let startIndex: number = items.length > 0 ? items[0].rank - 1 : 0;
            let endIndex: number = items.length > 0 ? items[items.length - 1].rank : 0;

            //PADDING RANGES
            let targetStartIndex: number = visibleRange.startIndex;
            let targetEndIndex: number = visibleRange.endIndex;

            if (currentItemData) {
                const currenItemIndex = currentItemData.rank - 1;
                if (currenItemIndex < targetStartIndex || currenItemIndex > targetEndIndex) {
                    //console.log(currentItem.rank, targetStartIndex, targetEndIndex);
                    //RESET ITEM
                    //setCurrentItem(null);
                }
            }

            if (targetStartIndex < 0) {
                targetStartIndex = 0;
            }
            if (endIndex > 0 && targetEndIndex > total) {
                targetEndIndex = total;
            }
            if (targetStartIndex >= startIndex &&
                targetStartIndex <= endIndex &&
                targetEndIndex >= startIndex &&
                targetEndIndex <= endIndex
            ) {
                //console.log("IN range:", visibleRange.startIndex, visibleRange.endIndex, " CURRENT:", startIndex, endIndex);
            } else {
                //console.log("NOT in range:", visibleRange.startIndex, visibleRange.endIndex, " CURRENT:", startIndex, endIndex);
                getData(targetStartIndex, false);
            }
        }
    }

    const itemClick = (item: any) => {

        if (currentItemData && currentItemData.CatalogId == item.CatalogId) {

            setCurrentItemData(null);

        } else {
            setCurrentItemData(item);

            const itemData = {
                catalogId: item.CatalogId,
                customerId: customer.customerId,
                isChain: orderMode == OrderMode.ChainAllLocations
            };
            dispatch(getItemAsync(itemData));

        }
    };

    const noScroll: boolean = false;

    const getItem = (index: number) => {
        if (items && items.length > 0) {
            //console.log("GET ITEM:", index);
            const item = items.find((row: any) => {
                return row.rank === index + 1;
            });

            if (item) {
                //return <CommentRow item={item} key={item.CatalogId} isScrolling={isScrolling} />;

                const isAlternate: boolean = item.rank % 2 === 0;
                switch (item.CatalogTypeId) {
                    case 2://Comment
                        return <CommentRow item={item} key={item.CatalogId} isScrolling={noScroll} isAlternate={isAlternate} />;
                    case 3://Headline
                        return <HeadlineRow item={item} key={item.CatalogId} isScrolling={noScroll} isAlternate={isAlternate} />;
                    case 4://Video
                        return <VideoRow item={item} key={item.CatalogId} isScrolling={noScroll} isAlternate={isAlternate} />;
                    case 5://Image
                        return <ImageRow item={item} key={item.CatalogId} isScrolling={noScroll} isAlternate={isAlternate} />;
                    case 6://Contract
                        return <ContractRow item={item} key={item.CatalogId} isScrolling={noScroll} isAlternate={isAlternate} />;
                    case 7://Document
                        return <DocumentRow item={item} key={item.CatalogId} isScrolling={noScroll} isAlternate={isAlternate} />;
                    default://Item
                        //return <CommentRow item={item} key={item.CatalogId} />;
                        return <ItemRow key={item.CatalogId}
                            orderMode={orderMode}
                            item={item}
                            isAlternate={isAlternate}
                            isScrolling={noScroll}
                            onClick={itemClick}
                            orderColumns={orderColumns}
                            currentItemData={currentItemData}
                            submitOrder={submitOrder}
                            currentItemStatus={currentItemStatus}
                        />;
                }
            }
        }
        return <div key={`row-${index}`} style={{
            height: 63, borderBottom: '1px solid #d9d9d9'
        }}> </div>;
    };

    const getGroup = (index: number) => {
        const showIcons: boolean = user.role === UserRole.Customer;
        if (allVendors && allVendors.length > 0 && vendorGroups[index]) {
            const currentVendor: Vendor = allVendors.find(vendor => {
                return vendor.vendorAccountId == vendorGroups[index].vendorAccountId;
            });
            if (currentVendor) {
                return <OrderGridVendor vendor={currentVendor} showIcons={showIcons} />;
            }
        }
        return <OrderGridVendor vendor={defaultVendor} showIcons={showIcons} />;
    };



    useDebounce(() => {
        updateGrid();
    }, 100, [visibleRange]);

    useDebounce(() => {
        getData(0, true);

    }, 100, [selectedVendor, selectedBroker, orderFilter, customer, searchQuery]);

    const modalWindowConfig: ModalProps = {
        className: "modal-window",
        transitionName: "",
        maskTransitionName: "",
        width: "100%",
        maskClosable: false,
        style: {
            top: 0,
            paddingBottom: 0
        },
        closable: true,
        closeIcon: <React.Fragment> CLOSE <CloseOutlined /></React.Fragment>
    };

    const ChainOrderWindowTitle = () => {
        if (currentItemData) {
            return <div className="item-name-header">
                {currentItemData.VendorName}: {currentItemData.Description}
            </div>;
        } else {
            return <React.Fragment />;
        }
    };

    const chainOrderWindowOnCancel = () => {
        //console.log("CHAIN CANCEL");
        setCurrentItemData(null);
        setChainState(prevState => {
            return {
                ...prevState,
                windowVisible: false
            }
        });
    };

    const submitOrder = (orderItems: CatalogOrder[]) => {

        if (orderItems && currentItemData) {
            let order: any = {} as any;
            order.CatalogId = currentItemData.CatalogId;
            order.CustomerId = customer.customerId;
            order.Items = orderItems;

            dispatch(updateOrderAsync(order));
        }
    }

    const updateChainOrder = (orderItems: CatalogOrder[]) => {
        if (chainState.catalogItem) {
            if (readOnly) {
                setChainState(prevState => {
                    return {
                        ...prevState,
                        windowVisible: false
                    }
                });
            } else {

                submitOrder(orderItems);

                setCurrentItemData(null);

                setChainState(prevState => {
                    return {
                        ...prevState,
                        windowVisible: false
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (vendorGroups) {
            //console.log("new groups");
            //const id: string = nanoid(5);
            //console.log("ID:", id);
            //setGridState({
            //    id: nanoid(5),
            //    groupCounts: vendorGroups.map(vendor => vendor.total)
            //});
            setGridState(vendorGroups.map(vendor => vendor.total));

            setCurrentItemData(null);

            dispatch(setItem(null));

            if (virtuoso.current) {
                virtuoso.current.scrollToIndex(0);
            }

        }
    }, [vendorGroups]);

    useEffect(() => {
        //console.log("itemToRefresh:", itemToRefresh);
        if (itemToRefresh.index > 0 && customer.customerId != DefaultGuid) {

            dispatch(getStatusAsync(customer));

            if (items && items.length > 0) {
                const targetItem = items.find(item => item.CatalogId == itemToRefresh.catalogId);
                if (targetItem) {
                    const itemData = {
                        catalogId: itemToRefresh.catalogId,
                        customerId: customer.customerId,
                        isChain: orderMode == OrderMode.ChainAllLocations
                    };
                    dispatch(getItemAsync(itemData));
                }
            }

        }
    }, [itemToRefresh]);

    useEffect(() => {
        if (customer.customerId != DefaultGuid) {
            if (user.role == UserRole.Vendor || user.role == UserRole.Broker) {
                dispatch(showMessage(`Ordering for ${customer.customerName} [${customer.customerAccountId}]`));
            }
            dispatch(getStatusAsync(customer));
        }
    }, [customer]);

    useEffect(() => {

        if (orderMode == OrderMode.ChainAllLocations && currentItemStatus && currentItemData) {

            const catalogItem: CatalogItem = {
                desciption: currentItemData.Description,
                catalogId: currentItemData.CatalogId,
                itemDeliverySchedule: currentItemStatus.status,
                quantity: currentItemStatus.quantity,
                history: currentItemStatus.history,
                vendorName: currentItemData.VendorName,
                vendorAccountId: currentItemData.VendorAccountId
            };

            //console.log(catalogItem);

            setChainState(prevState => {
                return {
                    ...prevState,
                    catalogItem: catalogItem,
                    windowVisible: true
                }
            });
        }

    }, [currentItemStatus]);

    useEffect(() => {

        if (reloadItems > 0) {
            //Reload items
            getData(gridStartIndex, false);

            dispatch(getStatusAsync(customer));
        }

    }, [reloadItems]);

    useEffect(() => {
        if (vendorAccountId) {
            let vendor = allVendors.find(vendor => vendor.vendorAccountId == vendorAccountId);
            if (vendor) {
                dispatch(filterVendor(vendor));
            }
        }
    }, [vendorAccountId]);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.quantity} title="Order Quantity">
                        Qty
                    </div>
                    {orderColumns.map((column, index) => {
                        //const css = renderHeader(column);
                        const css = clsx({
                            [styles.x2]: column.data == "Description",
                            [styles.itemno]: column.data == "ItemNo",
                            [styles.history]: column.data == "History",
                            [styles.generic]: true
                        });
                        return <div key={`${index}-${column.data}`} className={css}>
                            {column.data == "History" ? <Tooltip title={column.title} placement="bottom"><span>History <InfoCircleTwoTone /></span></Tooltip> : <span title={column.title}>{column.title}</span>}
                        </div>;
                    })}
                </div>
                <div className={styles.content}>
                    {vendorGroups && vendorGroups.length > 0 &&
                        <GroupedVirtuoso
                            ref={virtuoso}
                            groupCounts={gridState}
                            groupContent={getGroup}
                            itemContent={getItem}
                            rangeChanged={setVisibleRange}
                            components={{
                                Footer: () => {
                                    return (<div className={styles.footer}></div>);
                                }
                            }}
                        />
                    }
                </div>
            </div>
            {orderMode == OrderMode.ChainAllLocations &&
                <Modal
                    className="order-modal"
                    open={chainState.windowVisible}
                    title={<ChainOrderWindowTitle />}
                    onCancel={chainOrderWindowOnCancel}
                    footer={null}
                    {...modalWindowConfig}>
                    <div>
                        <ChainOrderWindow
                            orderOptions={orderOptions}
                            catalogItem={chainState.catalogItem}
                            deliverySchedule={deliverySchedule}
                            locationGroups={locationGroups}
                            locations={locations}
                            readOnly={readOnly}
                            onOk={updateChainOrder}
                            onCancel={chainOrderWindowOnCancel}
                        />
                    </div>
                </Modal>
            }
        </React.Fragment>
    );
};

//overscan = {{
//    main: 1000,
//        reverse: 1000
//}}