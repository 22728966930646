import React, { useState } from 'react';
import {
    reloadApp
} from 'store/user';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import packageJson from '../package.json';

globalThis.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

interface State {
    loading: boolean
}

interface Props {
    children: any
}

const CacheBuster: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();

    const [state, setState] = useState<State>({
        loading: true
    });
    React.useEffect(() => {
        if (state.loading) {
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version;
                    const currentVersion = globalThis.appVersion;
                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        console.log(`New app version: ${latestVersion} Current version:${currentVersion}.`);
                        setState(prevState => {
                            return {
                                ...prevState,
                                loading: false,
                            }
                        });
                        dispatch(reloadApp());
                    } else {
                        console.log(`App is up to date: ${latestVersion}.`);
                        setState(prevState => {
                            return {
                                ...prevState,
                                loading: false
                            }
                        });
                    }
                });
        }
    }, [state.loading]);
    const { loading } = state;
    return props.children({ loading });
}

export default CacheBuster;