import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    MailTwoTone,
    MessageTwoTone,
    ShoppingTwoTone
} from '@ant-design/icons';
import { Button } from 'antd';
import {
    Customer,
    Company,
    OrderOption,
    User,
    defaultVendor,
} from 'model';
import {
    hideAllActiveModals,
    selectOrderOptions,
    setCustomerAsync,
    resetAllFilters
} from 'store/order';
import {
    selectUser
} from 'store/user';
import { useAppState } from 'components/layout/AppStateProvider';
import history from 'store/history';

import { Modal } from 'antd';

interface Props {
    customer: Customer
}

export const Communication: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch();

    const user: User = useAppSelector(selectUser);

    //const vendors: Vendor[] = useAppSelector(selectVendors);

    //const brokers: Broker[] = useAppSelector(selectBrokers);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const { vendorOnMessage, email } = useAppState();

    const messageOnClick = () => {
        //console.log("MESSAGE", props.customer);
        vendorOnMessage(props.customer.customerId);
        //Modal.destroyAll();
        dispatch(hideAllActiveModals(true));
    };

    const emailOnClick = () => {
        const sender: Company = {
            id: user.id,
            name: user.company
        };
        const recipient: Company = {
            id: props.customer.customerId,
            name: props.customer.customerName
        };
        email(sender, recipient);

    };

    const orderOnClick = () => {
        dispatch(resetAllFilters());
        dispatch(setCustomerAsync(props.customer));
        history.push('/order');
    };

    return (
        <div className="customer-controls">
            <Button type="link" icon={<ShoppingTwoTone style={{ fontSize: 24 }} />} onClick={orderOnClick}> Order</Button>
            {orderOptions.messageEnabled &&
                <Button type="link" icon={<MessageTwoTone style={{ fontSize: 24 }} />} onClick={messageOnClick}> Send Message</Button>
            }
            {(orderOptions.vendorEmailToCustomerEnabled && props.customer.hasEmail === 1) &&
                <Button type="link" icon={<MailTwoTone style={{ fontSize: 24 }} />} onClick={emailOnClick}> Email</Button>
            }
        </div>
    );
}
