import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store/store';
import axios from 'axios';
import {
    DeliverySchedule,
    OrderFilterType,
    OrderFilter,
    Customer,
    Vendor,
    Broker,
    Show,
    OrderSettings,
    OrderStatus,
    OrderSummary,
    OrderOption,
    OrderMode,
    ColumnSettings,
    defaultVendor,
    defaultBroker,
    AnalyticsProps,
    OrderGridFilterProps,
    OrderGridProps,
    OrderGridVendorProps,
    OrderItem,
    OrderItemRefresh,
    VendorViewType,
    ErrorStatus,
    VirtualBooth
} from 'model/index';

import _ from 'lodash';
import { DefaultGuid } from 'utility/utility';
import { HubConnectionState } from '@microsoft/signalr';
import { setError } from './user';
import * as log from 'loglevel';

export interface OrderState {
    filter: OrderFilter,
    category: string,
    searchQuery: string,
    isLoading: boolean,
    isLoaded: boolean,
    customer: Customer,
    vendors: Vendor[],
    brokers: Broker[],
    vendor?: Vendor,
    broker?: Broker,
    deliverySchedule: DeliverySchedule[],
    summary: OrderSummary,
    vendorsWithOrders: string[],
    locations: Customer[],
    locationGroups: string[],
    show: Show,
    options: OrderOption,
    orderMode: OrderMode,
    columns: ColumnSettings[],
    itemToRefresh: OrderItemRefresh,
    hideAllModals: boolean,
    messageEnabled: boolean,
    timeZone: string,
    itemCount: number,
    items: any,
    vendorGroups: OrderGridVendorProps[],
    currentItem: OrderItem,
    vendorViewType: VendorViewType,
    reloadItems: number,
    booth?: VirtualBooth,
    virtualBooths: VirtualBooth[]
}

export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        filter: {
            filterType: OrderFilterType.All,
            query: ""
        },
        items: [],
        currentItem: null,
        vendorGroups: [],
        itemCount: 0,
        searchQuery: "",
        isLoading: false,
        isLoaded: false,
        vendor: {
            vendorId: DefaultGuid,
            vendorAccountId: "0",
            vendorName: "0",
            newItems: false,
            historyItems: false,
            primeVendor: false,
            logo: "0",
            category: ""
        },
        broker: {
            brokerId: DefaultGuid,
            brokerName: "",
            vendors: []
        },
        booth: {
            entityId: DefaultGuid,
            virtualBoothTypeId: 0,
        },
        vendorViewType: VendorViewType.Vendor,
        options: {
            history: false,
            historyTitle: "",
            announcements: true,
            contracts: false,
            showOrderNow: true,
            lobbyEnabled: false,
            hostBoothEnabled: false,
            messageEnabled: false,
            enforceMeetingSchedule: false,
            scheduleEnabled: false,
            vendorEmailToCustomerEnabled: false,
            customerEmailToVendorEnabled: true,
            customerVendorInfoEnabled: true,
            timeZone: "",
            virtualBoothEnabled: false,
            virtualBoothTitle: "",
            primeDealsEnabled: false,
            primeDealsTitle: "",
            orderSummaryFormat: ""
        },
        customer: {
            customerId: DefaultGuid,
        },
        vendors: [],
        brokers: [],
        vendorsWithLogos: [],
        deliverySchedule: [],
        summary: {
            allowance: "",
            point: "",
            price: "",
            quantity: 0
        },
        vendorsWithOrders: [],
        locations: [],
        locationGroups: [],
        show: null,
        orderMode: OrderMode.Store,
        columns: [],
        orderEnabled: false,
        itemToRefresh: {
            index: 0,
            catalogId: '',
            customerId: ''
        },
        hideAllModals: false,
        messageEnabled: true,
        timeZone: "",
        reloadItems: 0,
        virtualBooths: []
    },
    reducers: {
        getSettings: (state, action: PayloadAction<OrderSettings>) => {
            state.isLoaded = true;

            state.orderEnabled = action.payload.orderEnabled;
            state.messageEnabled = action.payload.messageEnabled;

            state.show = action.payload.show;
            globalThis.showId = state.show.showId;
            globalThis.showTitle = state.show.title;

            globalThis.timeZone = action.payload.timeZone;

            state.customer = action.payload.customer;
            state.deliverySchedule = action.payload.deliverySchedule;
            state.vendors = action.payload.vendors;

            state.virtualBooths = action.payload.virtualBooths;

            state.options = action.payload.options;

            state.columns = action.payload.columns;
            let locations = action.payload.locations;

            if (action.payload.locations.length > 0) {
                state.orderMode = OrderMode.ChainAllLocations;


                state.locationGroups = action.payload.groups;

            }

            state.locations = locations;

            const vendorsWithBrokers: Vendor[] = action.payload.vendors.filter((vendor: Vendor) => {
                return vendor.brokerId && vendor.brokerId.length > 0;
            });

            let brokers: Broker[] = [];
            const map = new Map();
            for (const item of vendorsWithBrokers) {
                if (!map.has(item.brokerId)) {
                    map.set(item.brokerId, true);
                    brokers.push({
                        brokerId: item.brokerId,
                        brokerName: item.brokerName,
                        brokerLogo: item.brokerLogo,
                        vendors: vendorsWithBrokers.filter((vendor: Vendor) => {
                            return vendor.brokerId === item.brokerId;
                        })
                    });
                }
            }
            state.brokers = _.sortBy(brokers, [(broker) => { return broker.brokerName; }]);
        },

        updateSettings: (state, action: PayloadAction<OrderSettings>) => {
            //console.log("Order updateSettings:", action.payload);
            state.orderEnabled = action.payload.orderEnabled;
            state.messageEnabled = action.payload.messageEnabled;
        },

        updateStatus: (state, action: PayloadAction<OrderStatus>) => {
            state.summary = action.payload.summary;
            state.vendorsWithOrders = action.payload.vendorsWithOrders;
        },

        filterVendor: (state, action: PayloadAction<Vendor>) => {
            state.vendor = action.payload;
            state.broker = defaultBroker;
            state.vendorViewType = VendorViewType.Vendor;
        },

        filterBroker: (state, action: PayloadAction<Broker>) => {
            state.broker = action.payload;
            state.vendor = defaultVendor;
            state.vendorViewType = VendorViewType.Broker;
        },

        filterBrokerVendor: (state, action: PayloadAction<Vendor>) => {
            state.vendor = action.payload;
        },

        filterPrimeVendor: (state, action: PayloadAction<Vendor>) => {
            state.filter = {
                filterType: OrderFilterType.All,
                query: ""
            }
            state.vendor = action.payload;
            state.broker = defaultBroker;
            state.vendorViewType = VendorViewType.Vendor;
        },
        filterType: (state, action: PayloadAction<OrderFilter>) => {
            state.filter = action.payload;
            state.vendor = defaultVendor;
        },
        filterByQuery: (state, action: PayloadAction<string>) => {
            state.searchQuery = action.payload;
        },

        setVendorViewType: (state, action: PayloadAction<VendorViewType>) => {
            state.vendor = defaultVendor;
            state.broker = defaultBroker;
            state.vendorViewType = action.payload;
        },
        resetAllFilters: (state) => {
            state.filter = {
                filterType: OrderFilterType.All,
                query: ""
            }
            state.searchQuery = "";
            state.vendor = defaultVendor;
            state.broker = defaultBroker;
            state.vendorViewType = VendorViewType.Vendor;
        },

        filterTypeOnly: (state, action: PayloadAction<OrderFilter>) => {
            state.filter = action.payload;
        },
        setCustomer: (state, action: PayloadAction<OrderSettings>) => {
            state.customer = action.payload.customer;
            if (action.payload.locations.length > 0) {
                state.locations = action.payload.locations;
                state.orderMode = OrderMode.ChainAllLocations;
                state.locationGroups = action.payload.groups;
                //state.locationGroups = Array.from(new Set(action.payload.locations.map((customer: Customer) => customer.groupName)));

            } else {
                state.orderMode = OrderMode.Store;
                state.locations = [];
                state.locationGroups = [];
            }
        },
        resetCustomer: (state) => {
            state.orderMode = OrderMode.Store;
            state.customer = {
                customerId: DefaultGuid
            };
            state.locations = [];
            state.locationGroups = [];
            state.summary = {
                allowance: "",
                point: "",
                price: "",
                quantity: 0
            }
            state.vendorsWithOrders = [];
        },

        refreshOrder: (state, action: PayloadAction<OrderItemRefresh>) => {
            if (state.customer.customerId == action.payload.customerId) {
                state.itemToRefresh = action.payload;
            }
        },

        hideAllActiveModals: (state, action: PayloadAction<boolean>) => {
            state.hideAllModals = action.payload;
        },
        analytic: (state, action: PayloadAction<AnalyticsProps>) => {
            //console.log("analytic slice", action.payload);
            if (globalThis.hubConnection && globalThis.hubConnection.state == HubConnectionState.Connected) {
                if (action.payload.id != DefaultGuid) {
                    globalThis.hubConnection.invoke("Analytic", action.payload);
                }
            }
        },
        setItems: (state, action: PayloadAction<OrderGridProps>) => {
            state.itemCount = action.payload.total;
            state.items = action.payload.items;
            if (action.payload.vendors) {
                state.vendorGroups = action.payload.vendors;
            }
            state.isLoading = false;
        },

        setItem: (state, action: PayloadAction<any>) => {
            //console.log("ITEM STATUS", action.payload);
            state.currentItem = action.payload;
        },

        updateOrder: (state, action: PayloadAction<OrderStatus>) => {

            state.summary = action.payload.summary;
            state.vendorsWithOrders = action.payload.vendorsWithOrders;

            if (action.payload.catalogId && state.items && state.items.length > 0) {
                state.items = state.items.map((item: any) => {
                    if (item.CatalogId == action.payload.catalogId) {
                        item.TotalQuantity = action.payload.totalQuantity;
                    }
                    return item;
                });;
            }
        },

        setReloadItems: (state, action: PayloadAction<boolean>) => {
            state.reloadItems = new Date().getTime();
        },

        filterBooth: (state, action: PayloadAction<VirtualBooth>) => {
            state.booth = action.payload;
        },

        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});

export const {
    getSettings,
    updateSettings,
    updateStatus,
    filterVendor,
    filterBrokerVendor,
    filterBroker,
    filterPrimeVendor,
    filterType,
    filterTypeOnly,
    setCustomer,
    resetCustomer,
    updateOrder,
    refreshOrder,
    hideAllActiveModals,
    analytic,
    filterByQuery,
    resetAllFilters,
    setVendorViewType,
    setItems,
    setItem,
    setReloadItems,
    filterBooth,
    setLoading
} = orderSlice.actions;

export const selectReloadItems = (state: RootState) => state.order.reloadItems;

export const selectItemCount = (state: RootState) => state.order.itemCount;

export const selectItems = (state: RootState) => state.order.items;

export const selectCurrentItem = (state: RootState) => state.order.currentItem;

export const selectVendorGroups = (state: RootState) => state.order.vendorGroups;

export const selectIsLoaded = (state: RootState) => state.order.isLoaded;

export const selectShow = (state: RootState) => state.order.show;

export const selectBooth = (state: RootState) => state.order.booth;

export const selectCustomer = (state: RootState) => state.order.customer;

export const selectVendors = (state: RootState) => state.order.vendors;

export const selectBrokers = (state: RootState) => state.order.brokers;

export const selectVendor = (state: RootState) => state.order.vendor;

export const selectBroker = (state: RootState) => state.order.broker;

export const selectVirtualBooths = (state: RootState) => state.order.virtualBooths;

export const selectVendorViewType = (state: RootState) => state.order.vendorViewType;

export const selectSummary = (state: RootState) => state.order.summary;

export const selectDeliverySchedule = (state: RootState) => state.order.deliverySchedule;

export const selectLocations = (state: RootState) => state.order.locations;

export const selectLocationGroups = (state: RootState) => state.order.locationGroups;

export const selectVendorsWithOrders = (state: RootState) => state.order.vendorsWithOrders;

export const selectOrder = (state: RootState) => state.order;

export const selectFilterType = (state: RootState) => state.order.filter;

export const selectFilterCategory = (state: RootState) => state.order.category;

export const selectSearchQuery = (state: RootState) => state.order.searchQuery;

export const selectOrderOptions = (state: RootState) => state.order.options;

export const selectOrderMode = (state: RootState) => state.order.orderMode;

export const selectColumns = (state: RootState) => state.order.columns;

export const selectReadOnly = (state: RootState) => state.order.customer.customerId === DefaultGuid || !state.order.orderEnabled;

export const selectItemToRefresh = (state: RootState) => state.order.itemToRefresh;

export const selectHideAllModals = (state: RootState) => state.order.hideAllModals;

export const selectMessageEnabled = (state: RootState) => state.order.messageEnabled;

export const selectOptions = (state: RootState) => state.order.options;

export const selectLoading = (state: RootState) => state.order.isLoading;

export const getSettingsAsync = (): AppThunk => (dispatch, getState) => {
    axios.get<OrderSettings>("/order/settings").then(result => {
        if (result.data) {
            dispatch(getSettings(result.data));
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getSettingsAsync"
        };
        dispatch(setError(errorStatus));
    });
};

export const getStatusAsync = (customer: Customer): AppThunk => (dispatch, getState) => {
    //console.log("ORDER STORE getStatusAsync");
    axios.post<OrderStatus>("/order/status", customer).then(result => {
        if (result.data) {
            dispatch(updateStatus(result.data));
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getStatusAsync"
        };
        dispatch(setError(errorStatus));
    });
};

export const setCustomerAsync = (customer: Customer): AppThunk => (dispatch, getState) => {
    axios.post<OrderSettings>("/order/customer", customer).then(response => {
        if (response.data) {
            dispatch(setCustomer(response.data));
        }
    }).catch(error => {
        //console.log(error.toJSON());
        const errorStatus: ErrorStatus = {
            error: "setCustomerAsync"
        };
        dispatch(setError(errorStatus));
    });
};

export const getItemsAsync = (filter: OrderGridFilterProps): AppThunk => (dispatch, getState) => {

    return axios.post<OrderGridProps>("/order/list", filter).then(response => {
        if (response.data) {
            dispatch(setItems(response.data));
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getItemsAsync"
        };
        dispatch(setError(errorStatus));
    });
};

export const getItemAsync = (itemData: any): AppThunk => (dispatch, getState) => {

    return axios.post<OrderGridProps>("/order/item", itemData).then(response => {
        if (response.data) {
            dispatch(setItem(response.data));
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getItemAsync"
        };
        dispatch(setError(errorStatus));
    });
};

export const updateOrderAsync = (order: any): AppThunk => (dispatch, getState) => {
    return axios.post<OrderStatus>("/order/update", order).then(response => {
        if (response.data) {
            let orderStatus: OrderStatus = { ...response.data };
            orderStatus.catalogId = order.CatalogId;
            //console.log("orderStatus:", orderStatus);
            dispatch(updateOrder(orderStatus));
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "updateOrderAsync"
        };
        dispatch(setError(errorStatus));
    });
};


export default orderSlice.reducer;


