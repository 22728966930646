import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DefaultGuid } from 'utility/utility';
import {
    selectCustomer,
    selectSummary,
    selectOrderOptions
} from 'store/order';
import {
    OrderOption
} from 'model';

export const Status: React.FC = () => {

    const orderSummary = useAppSelector(selectSummary);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const customer = useAppSelector(selectCustomer);

    if (customer.customerId === DefaultGuid) {
        return <React.Fragment />;
    } else {

        const result = () => orderOptions.orderSummaryFormat.replace(/\${(.*?)}/g, (x, g) => orderSummary[g]);

        return (
            <span>
                {result()}
            </span>
        );
    }

}
