import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styles from './Messenger.module.css';
import {
    selectUnreadSessionCount
} from 'store/messenger';
export default function SessionCount() {
    const unreadSessionCount = useAppSelector(selectUnreadSessionCount);
    return (
        <React.Fragment>
            {unreadSessionCount > 0 && <div className={styles.count}>{unreadSessionCount}</div>}
        </React.Fragment>
    );
}
