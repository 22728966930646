import React from 'react';
import {
    AppstoreTwoTone,
} from '@ant-design/icons';
import { useAppState } from './AppStateProvider';

export const MenuIconComponent: React.FC = () => {
    const { showHideDrawer } = useAppState();

    const showMenuOnClick = () => {
        showHideDrawer(true);
    };
    
    return (
        <div id="menu"  onClick={showMenuOnClick} onMouseOver={showMenuOnClick}>
            <AppstoreTwoTone style={{ fontSize: 30 }} />
            <span>
                MENU
            </span>
        </div>
    );
};
export const MenuIcon = React.memo(MenuIconComponent);
