//import { renderToStaticMarkup } from 'react-dom/server';
import { format as formatDate, isValid, compareAsc, parseISO, parse } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import * as log from 'loglevel';

import {
    DeliverySchedule,
    CatalogOrder,
    CatalogItemDeliverySchedule,
} from 'model/index';


const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

const isNumeric = (num: any) => {
    return !isNaN(num);
}

//import { MenuOutlined } from '@ant-design/icons';
export class DatatableRender {
    static ellipsis(cutoff: number) {
        return function (data: any, type: string) {
            if (type === "display") {
                let result = "";
                if (data) {
                    let str = data.toString(); // cast numbers
                    result = str.length < cutoff ? str : str.substr(0, cutoff - 1) + '&#8230;';
                    result = `<div title='${str}'>${result}</div>`;
                }
                return result;
            }
            return data;
        };
    }
    static groupName() {
        return function (rows: any, group: any) {
            let id: string = "";
            //console.log(rows.data()[0]);
            try {
                id = rows.data()[0].VendorAccountId;
            } catch (e) {
            }
            //console.log(rows.data()[0].VendorName);
            return `<div class="vendor-name-header" data-id="${id}">${group}</div>`;
            //<svg viewBox="64 64 896 896" focusable="false" class="" data-icon="menu" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path></svg>
        };
    }
    static wrapText(data: any): string {
        let result = "";
        if (data) {
            result = data.toString();
            result = result.replace(/(?:\r\n|\r|\n)/g, '<br>');
            //var str = data.toString(); // cast numbers
            //result = str.length < 100 ? str : str.substr(0, 100 - 1) + '&#8230;';
            //result = `<div title='${str}'>${result}</div>`;
        }
        return result;
    }

    static vendorName() {
        return function (data: any, type: string, row: any) {
            if (data) {
                let text = data.toString();
                if (text) {
                    text = text.length < 150 ? text : text.substr(0, 150 - 1) + '&#8230;';
                    text = `<div title='${data}'>${text}</div>`;
                }
                return `<div class='vendor-name'>${row.VendorName}</div><div>${text}</div>`;
            }
            return "";
        };
    }

    static itemNo() {
        return function (data: any, type: string, row: any) {
            let result = data;
            if (row.IsNewItem) {
                result = `<span class="ant-badge">${data}</a><sup class="ant-badge-count">new</sup></span>`;
            }
            if (row.AdURL && row.AdURL.length > 0) {
                result = `<img class="item-image" loading="lazy" src="${row.AdURL}"/> ${result}`;
            }
            return result;
        };
    }

    //static video() {
    //    return function (data: any, type: string, row: any) {
    //        let content = <tr><td className="wrap video-link" colSpan = { columnCount } > <YoutubeOutlined /> <em>{data.Description}</em > </td></tr >;

    //        return data;
    //    };
    //}

    static money() {
        return function (data: any, type: string, row: any) {
            if (data) {
                let text = data.toString();
                if (text && isNumeric(text)) {
                    //text.valueAsNumber
                    let numberValue = Number(text);
                    if (numberValue > 0) {
                        return moneyFormatter.format(Number(text));
                    }
                }
            }
            return "";
        };
    }

    static number() {
        return function (data: any, type: string, row: any) {
            if (data) {
                let text = data.toString();
                if (text && isNumeric(text)) {
                    //text.valueAsNumber
                    let numberValue = Number(text);
                    if (numberValue > 0) {
                        return Number(text);
                    }
                }
            }
            return "";
        };
    }


    static boolValue() {
        return function (data: any, type: string, row: any) {
            if (typeof data === "boolean") {
                if (data) {
                    return "<img src='https://tradeshoweasy.s3.us-east-2.amazonaws.com/asset/check-icon.svg'/>";
                }
            }
            return "";
        };
    }

    static file(title: string, showId: number) {
        return function (data: any, type: string, row: any) {
            if (data) {
                return `<a href='${data}' target='_blank' class='link'>${title}</a>`;
            }
            return "";
        };
    }
}

export const DefaultGuid: string = "00000000-0000-0000-0000-000000000000";

export const isMobile = (() => {
    if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
        return false;
    }
    return /Mobile/.test(navigator.userAgent);
})();

export const isTablet = (() => {
    if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
        return false;
    }
    return /Mobile/.test(navigator.userAgent);
})();

export class Utility {

    static youTubeGetID(url) {
        let ID = '';
        url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
        }
        else {
            ID = url;
        }
        return ID;
    }
}

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
    return navigator.mediaDevices
        .enumerateDevices()
        .then(devices => devices.every(device => !(device.deviceId && device.label)))
        .then(shouldAskForMediaPermissions => {
            if (shouldAskForMediaPermissions) {
                return navigator.mediaDevices
                    .getUserMedia({ audio: true, video: true })
                    .then(mediaStream => mediaStream.getTracks().forEach(track => track.stop()));
            }
        });
}

const dateFormat = 'M/dd h:mm a';
const timeFormat = 'h:mm a';
const dayFormat = 'iiii, MMMM d, yyyy';

export const getDate = (d: any): string => {
    //console.log("Parse:", d);
    let parsedDate = parseISO(d);
    if (isValid(parsedDate)) {
        return formatDate(parsedDate, dateFormat);
    } else {
        return "";
    }
}

export const getTime = (date: Date): string => {
    //console.log("Parse:", d);
    return formatDate(date, timeFormat);
}

export const getDay = (date: string): string => {
    let parsedDate = parse(date, 'LL/dd/yyyy', new Date());
    //console.log("parsedDate", parsedDate);
    if (isValid(parsedDate)) {
        return formatDate(parsedDate, dayFormat);
    } else {
        return date;
    }
}

export const getDateInMyTimeZone = (date: string): Date => {
    let serverDateTime = zonedTimeToUtc(parseISO(date), globalThis.timeZone);
    let userDateTime = utcToZonedTime(serverDateTime, globalThis.userTimeZone);
    return userDateTime;
}


export const getTimeInMyTimeZone = (date: string): string => {
    let serverDateTime = zonedTimeToUtc(parseISO(date), globalThis.timeZone);
    let userDateTime = utcToZonedTime(serverDateTime, globalThis.userTimeZone);
    return formatDate(userDateTime, timeFormat);
}

export const getMeetingDayAndTime = (startDate: string, endDate: string): string => {

    let serverStartDateTime = zonedTimeToUtc(parseISO(startDate), globalThis.timeZone);
    let userStartDateTime = utcToZonedTime(serverStartDateTime, globalThis.userTimeZone);

    let serverEndDateTime = zonedTimeToUtc(parseISO(endDate), globalThis.timeZone);
    let userEndDateTime = utcToZonedTime(serverEndDateTime, globalThis.userTimeZone);

    return `${formatDate(userStartDateTime, 'M/dd/yyyy')} ${formatDate(userStartDateTime, 'h:mm a')} - ${formatDate(userEndDateTime, 'h:mm a')}`;
}

export const getMeetingTime = (startDate: string, endDate: string): string => {

    //console.log("startDate", startDate, " endDate:", endDate);

    let serverStartDateTime = zonedTimeToUtc(parseISO(startDate), globalThis.timeZone);
    let userStartDateTime = utcToZonedTime(serverStartDateTime, globalThis.userTimeZone);

    let serverEndDateTime = zonedTimeToUtc(parseISO(endDate), globalThis.timeZone);
    let userEndDateTime = utcToZonedTime(serverEndDateTime, globalThis.userTimeZone);

    return `${formatDate(userStartDateTime, 'h:mm a')} - ${formatDate(userEndDateTime, 'h:mm a')}`;

    //return "ss";
}


export const formatMoney = (value: string): string => {
    if (value) {
        let text = value.toString();
        if (text && isNumeric(text)) {
            let numberValue = Number(text);
            if (numberValue > 0) {
                return moneyFormatter.format(Number(text));
            }
        }
    }
    return "";
}

export const getTimeZoneLabel = (timeZone: string): string => {
    switch (timeZone) {
        case "America/New_York":
            return "Eastern Time Zone";
        case "America/Chicago":
            return "Central Time Zone";
        case "America/Denver":
            return "Mountain Time Zone";
        case "America/Los_Angeles":
            return "Pacific Time Zone";
        default:
            return "";
    }
}

export const checkMinMaxQuantity = (item: CatalogItemDeliverySchedule, deliverySchedule: DeliverySchedule[], quantityRow: CatalogOrder): boolean => {
    let valid: boolean = true;

    if (item.MaxQuantity && item.MaxQuantity > 0) {
        let maxQuantityType = "total";
        if (item.MaxQuantityType) {
            maxQuantityType = item.MaxQuantityType;
        }
        if (maxQuantityType == "total") {
            let total: number = 0;
            deliverySchedule.map((delivery: DeliverySchedule) => {
                const title: string = `W${delivery.weekNo.toString()}`;
                if (quantityRow[title as keyof CatalogOrder]) {
                    let value = quantityRow[title as keyof CatalogOrder].toString();
                    if (value != "0") {
                        total += Number(value);
                    }
                }  
            });
            if (total > item.MaxQuantity) {
                valid = false;
            }
        } else {
            deliverySchedule.map((delivery: DeliverySchedule) => {
                const title: string = `W${delivery.weekNo.toString()}`;
                if (quantityRow[title as keyof CatalogOrder]) {
                    let value = quantityRow[title as keyof CatalogOrder].toString();
                    if (value != "0") {
                        //log.debug(value, item.MaxQuantity)
                        if (Number(value) > item.MaxQuantity) {
                            //log.debug("NOT VALID");
                            valid = false;
                        }
                    }
                }
            });
        }
    }

    if (item.MinimumQuantity && item.MinimumQuantity > 0) {
        let minQuantityType = "total";
        if (item.MinimumQuantityType) {
            minQuantityType = item.MinimumQuantityType;
        }
    //    log.debug(minQuantityType);
        if (minQuantityType == "total") {
            let total: number = 0;
            deliverySchedule.map((delivery: DeliverySchedule) => {
                const title: string = `W${delivery.weekNo.toString()}`;
                if (quantityRow[title as keyof CatalogOrder]) {
                    let value = quantityRow[title as keyof CatalogOrder].toString();
                    if (value != "0") {
                        total += Number(value);
                    }
                }
            });
            if (total> 0 && total < item.MinimumQuantity) {
                valid = false;
            }
        } else {
            deliverySchedule.map((delivery: DeliverySchedule) => {
                const title: string = `W${delivery.weekNo.toString()}`;
                if (quantityRow[title as keyof CatalogOrder]) {
                    let value = quantityRow[title as keyof CatalogOrder].toString();
                    //log.debug(value);
                    if (value != "0") {
                        //log.debug(value, item.MaxQuantity)
                        if (Number(value) < item.MinimumQuantity) {
                            //log.debug("NOT VALID");
                            valid = false;
                        }
                    }
                }
            });
        }
    }


    return valid;
}