export enum ChainOrderRowRowType {
    Group,
    Customer
}

export interface ChainOrderRow {
    key: string;
    customerId: string;
    customerAccountId: string;
    customerName: string;
    group: string;
    rowType: ChainOrderRowRowType;

    history?: number;
    historyAvarage?: number;

    W1?: number;
    W2?: number;
    W3?: number;
    W4?: number;
    W5?: number;
    W6?: number;
    W7?: number;
    W8?: number;
    W9?: number;
    W10?: number;
    W11?: number;
    W12?: number;
    W13?: number;
    W14?: number;
    W15?: number;
    W16?: number;
    W17?: number;
    W18?: number;
    W19?: number;
    W20?: number;
    W21?: number;
    W22?: number;
    W23?: number;
    W24?: number;
    W25?: number;
    W26?: number;
    W27?: number;
    W28?: number;
    W29?: number;
    W30?: number;
    W31?: number;
    W32?: number;
    W33?: number;
    W34?: number;
    W35?: number;
    W36?: number;
    W37?: number;
    W38?: number;
    W39?: number;
    W40?: number;
    W41?: number;
    W42?: number;
    W43?: number;
    W44?: number;
    W45?: number;
    W46?: number;
    W47?: number;
    W48?: number;
    W49?: number;
    W50?: number;
    W51?: number;
    W52?: number;
}


export interface OrderItemRefresh {
    index: number,
    catalogId: string,
    customerId: string;
    connectionId: string;
}

export interface OrderItem {
    catalogId: string,
    quantity: CatalogOrder[];
    status: CatalogItemDeliverySchedule;
    history?: CatalogHistory[];
}

export interface CatalogItem {
    catalogId: string;
    desciption: string;
    vendorName: string;
    vendorAccountId: string;
    itemDeliverySchedule: CatalogItemDeliverySchedule;
    quantity?: CatalogOrder[];
    history?: CatalogHistory[];
}

export interface CatalogHistory {
    CustomerId: string;
    quantity?: number;
    quantityAverage?: number;
}

export enum MinMaxQuantityType {
    Total = "total",
    Weekly = "weekly"
}

export interface CatalogItemDeliverySchedule {
    MaxQuantity?: number,
    MaxQuantityType?: MinMaxQuantityType,
    MinimumQuantity?: number,
    MinimumQuantityType?: MinMaxQuantityType,
    W1: boolean;
    W2: boolean;
    W3: boolean;
    W4: boolean;
    W5: boolean;
    W6: boolean;
    W7: boolean;
    W8: boolean;
    W9: boolean;
    W10: boolean;
    W11: boolean;
    W12: boolean;
    W13: boolean;
    W14: boolean;
    W15: boolean;
    W16: boolean;
    W17: boolean;
    W18: boolean;
    W19: boolean;
    W20: boolean;
    W21: boolean;
    W22: boolean;
    W23: boolean;
    W24: boolean;
    W25: boolean;
    W26: boolean;
    W27: boolean;
    W28: boolean;
    W29: boolean;
    W30: boolean;
    W31: boolean;
    W32: boolean;
    W33: boolean;
    W34: boolean;
    W35: boolean;
    W36: boolean;
    W37: boolean;
    W38: boolean;
    W39: boolean;
    W40: boolean;
    W41: boolean;
    W42: boolean;
    W43: boolean;
    W44: boolean;
    W45: boolean;
    W46: boolean;
    W47: boolean;
    W48: boolean;
    W49: boolean;
    W50: boolean;
    W51: boolean;
    W52: boolean;
}

export interface CatalogOrder {
    CustomerId: string;
    W1?: number;
    W2?: number;
    W3?: number;
    W4?: number;
    W5?: number;
    W6?: number;
    W7?: number;
    W8?: number;
    W9?: number;
    W10?: number;
    W11?: number;
    W12?: number;
    W13?: number;
    W14?: number;
    W15?: number;
    W16?: number;
    W17?: number;
    W18?: number;
    W19?: number;
    W20?: number;
    W21?: number;
    W22?: number;
    W23?: number;
    W24?: number;
    W25?: number;
    W26?: number;
    W27?: number;
    W28?: number;
    W29?: number;
    W30?: number;
    W31?: number;
    W32?: number;
    W33?: number;
    W34?: number;
    W35?: number;
    W36?: number;
    W37?: number;
    W38?: number;
    W39?: number;
    W40?: number;
    W41?: number;
    W42?: number;
    W43?: number;
    W44?: number;
    W45?: number;
    W46?: number;
    W47?: number;
    W48?: number;
    W49?: number;
    W50?: number;
    W51?: number;
    W52?: number;
}

export interface DeliverySchedule {
    title: string,
    weekNo: number,
    disabled: boolean
}

export enum OrderMode {
    None = "None",
    Disabled = "Disabled",
    Store = "Store",
    ChainAllLocations = "ChainAllLocations",
    ChainSelectedLocations = "ChainSelectedLocations"
}

export interface OrderFilter {
    filterType: OrderFilterType,
    query: string
}

export enum OrderFilterType {
    All = "All",
    Purchased = "Purchased",
    New = "New Items",
    History = "History Items",
    HistoryAvarage = "History Avarage",
    Prime = "Prime",
    Booth = "Booth",
    Contracts = "Contracts",
    Category = "Category",
    Videos = "Videos",
    Documents = "Documents",
    Tag = "Tag",
}

export interface Customer {
    customerId: string,
    customerAccountId?: string,
    customerName?: string,
    groups?: string[],
    locationCount?: number,
    customerOf?: string,
    salesRep?: string,
    city?: string
    state?: string
    zip?: string
    chainName?: string,
    hasEmail?: number
}

export interface Show {
    showId: number,
    title: string,
    logo: string
}

export interface OrderOption {

    showBookTitle: string,

    history: boolean,
    historyTitle: string,

    historyAverage: boolean,
    historyAverageTitle: string,

    price: boolean,
    priceTitle: string,

    announcements: boolean,
    contracts: boolean,
    showOrderNow: boolean,
    lobbyEnabled: boolean,
    hostBoothEnabled: boolean,
    hostVendorId: string,
    messageEnabled: boolean,
    enforceMeetingSchedule: boolean,
    scheduleEnabled: boolean,

    vendorEmailToCustomerEnabled: boolean,
    customerEmailToVendorEnabled: boolean,
    customerVendorInfoEnabled: boolean,

    timeZone: "",
    virtualBoothEnabled: boolean,
    virtualBoothTitle: string,
    primeDealsEnabled: boolean,
    primeDealsTitle: string,
    entryStatus: boolean,

    notesEnabled: boolean,

    orderSummaryFormat: string
}


export interface OrderSummary {
    allowance: string,
    point: string,
    price: string,
    quantity: number
}

export interface GridVendor {
    vendorName: string,
    vendorAccountId: string,
    total: number
}

export interface Vendor {
    vendorId: string,
    vendorName: string,
    vendorAccountId: string,

    newItems: boolean,
    documents: boolean,
    contracts: boolean,
    videos: boolean,
    historyItems: boolean,
    primeVendor: boolean,
    category: string,
    logo: string,

    brokerId: string,
    brokerName: string,
    brokerLogo: string
    tags: string[]
}

export interface Broker {
    brokerId: string,
    brokerName: string,
    brokerLogo: string,
    vendors: Vendor[]
}

export enum VendorViewType {
    Vendor = "Vendor",
    Broker = "Broker"
}

export enum UserRole {
    Vendor = "Vendor",
    Broker = "Broker",
    Customer = "Customer"
}

export interface User {
    page: string,
    accountNo: string,
    company: string,
    showId: number,
    id: string,
    token?: string,
    role: UserRole,
    exp: number
}

export interface UserSettings {
    timeZone: string,
    timeZoneLabel: string
}

export interface LoginRequest {
    userName: string,
    password: string,
    remember: boolean
}

export interface LoginResponse {
    token: string,
    status: boolean,
    message: string,
    page: string

}

export interface Message {
    sessionId?: number,
    messageId?: number,
    body?: string,
    messageType?: string,
    url?: string,
    senderId?: string,
    recipientId?: string,
    createdOn?: string,
    isDeleted?: boolean
}

export enum MessageTypes {
    Text = "Text",
    File = "File",
    Video = "Video"
}

export interface MessengerSession {
    sessionId?: number,
    meetingDate?: string,
    userId?: string,
    vendorId?: string,
    brokerId?: string,
    customerId?: string,
    vendorName?: string,
    brokerName?: string,
    customerName?: string,
    hasUnread?: boolean,
    isOnline?: boolean,
    isBroker?: boolean,
    isActive?: boolean,
    scrollToView?: boolean,
    messages?: Message[],
    scheduleStart?: string,
    scheduleEnd?: string
}

export interface MessengerVideoSession {
    sessionId: number,
    connected?: boolean,
    userId?: string
}

export interface CreateMessengerSession {
    session: MessengerSession,
    sessions: MessengerSession[]
}

export interface MessengerUserStatus {
    userId?: string,
    role?: string,
    isOnline: boolean
}

export interface OrderSettings {
    messageEnabled: boolean,
    scheduleEnabled: boolean,
    orderEnabled: boolean,
    orderEnabledStatus: string,
    vendors?: Vendor[],
    brokers?: Broker[],
    deliverySchedule?: DeliverySchedule[],
    locations?: Customer[],
    groups?: string[],
    customer?: Customer,
    show?: Show,
    options?: OrderOption,
    columns?: ColumnSettings[],
    timeZone: string,
    virtualBooths: VirtualBooth[]
}

export interface OrderStatus {
    vendorsWithOrders?: string[],
    status?: string,
    totalQuantity: number,
    catalogId?: string,
    summary?: OrderSummary
}


export interface GridProps {
    vendor?: Vendor,
    vendors?: Vendor[],
    filter: OrderFilter,
    deliverySchedule: DeliverySchedule[],

    onVendorSchedule(vendorId: string): void,
    onVendorViewBooth(vendorId: string): void,

    onVendorSelect(vendorAccountId: string): void,
    onVendorEmail(vendorAccountId: string): void,
    onVendorInformation(vendorAccountId: string): void,
    onChainOrderWindowShow(catalogItem: CatalogItem): void,
    onAnalytics(content: AnalyticsProps): void,

    submitOrder(order: any): any,
    gridData(filter: any): any,
    itemData(catalogId: string): any,
    refreshTimer: number,
    forceRefresh: number,
    totalQuantity: number,
    customer: Customer,
    user: User,
    orderOptions: OrderOption,
    orderMode: OrderMode,
    readOnly: boolean,
    show: Show,
    columns: ColumnSettings[],
    onMediaWindowShow(content: MediaProps): void
}

type FunctionColumnRender = (data: any, type: string, row: any) => any;

export interface ColumnSettings {
    //className?: string;
    data?: number | string | null;
    title?: string;
    //type?: string;
    columnType?: OrderColumnType;
    //visible?: boolean;
    width?: number;
    //render?: FunctionColumnRender;
    //createdCell?: any;
}

export type FilterType = {
    pageSize: number,
    startIndex: number,
    customerId?: string,
    vendorAccountId?: string,
    filter?: OrderFilter,
    query?: string,
    catalogId?: string
}

export type GridFilter = {
    customerId: string,
    vendorAccountId: string,
    filter: OrderFilter
}

export enum OrderColumnType {
    File = "File",
    Money = "Money",
    Text = "Text",
    CheckBox = "CheckBox",
    Number = "Number",
    Decimal = "Decimal",
    Date = "Date"
}


import { DefaultGuid } from 'utility/index';

export const defaultVendor: Vendor = {
    vendorId: DefaultGuid,
    vendorAccountId: "",
    vendorName: "",
    newItems: false,
    contracts: false,
    videos: false,
    documents: false,
    historyItems: false,
    primeVendor: false,
    logo: "",
    category: "",
    brokerId: "",
    brokerName: "",
    brokerLogo: "",
    tags: []
};


export const defaultBroker: Broker = {
    brokerId: DefaultGuid,
    brokerName: "",
    brokerLogo: "",
    vendors: []
};

export const defaultCustomer: Customer = {
    customerId: DefaultGuid,
};


export enum MediaType {
    None = "None",
    Video = "Video",
    File = "File",
    Contract = "Contract",
    Image = "Image"
}

export interface MediaProps {
    url?: string,
    mediaType: MediaType,
    item?: any,
    title?: string,
    onOk?: any,
    vendorAccountId?: string
}

export enum AnalyticsType {
    Vendor = "Vendor",
    Item = "Item",
    Contract = "Contract",
    Media = "Media",
    Video = "Video",
    Image = "Image",
    Document = "Document",
    Email = "Email",
    Info = "Info",
    Message = "Message",
    Booth = "Booth",
}


export interface AnalyticsProps {
    id?: string,
    vendorId?: string,
    vendorAccountId?: string,
    analyticsType: AnalyticsType
}

export interface Company {
    id?: string,
    name?: string,
}

export interface EmailAttachement {
    fileName: string;
    fileUrl: string;
}

export interface AppEmail {
    senderId: string,
    recipientId: string,
    message: string,
    attachments?: EmailAttachement[];
}

export interface CompanyInformation {
    id: string,
    name: string,
    accountId: string,
    contact: string,
    phone: string,
    email: string,
    website: string,
    logo: string
}

export interface AppConfig {
    tokenName: string;
    baseUrl: string;
    version: string;
}

export interface ShowField {
    content?: string;
    title?: string;
}

export interface CustomerContract {
    catalogId: string,
    customerId: string,
    signee: string,
    customerSignature: string,
    customerSignatureIsEmpty: boolean,
    notes: string,
    customerLocations: string[]
}


export interface MeetingScheduleAvailabilty {
    days: MeetingScheduleDay[]
}



export interface MeetingScheduleSpot {
    scheduleStartTime: string,
    scheduleStart: string,
    scheduleEnd: string,
    isSelected: boolean,
    isAvailable: boolean
}

export interface MeetingScheduleDay {
    index: number,
    title: string,
    availableSpots: MeetingScheduleSpot[]
}

export interface ReserveScheduleSpot {
    vendorId?: string,
    customerId?: string,
    scheduleStart: string
}

export interface MeetingSchedule {
    availableDays: MeetingScheduleDay[]
    mySchedule: MyScheduleDay[]
}

export interface MyScheduleDay {
    index: number,
    title: string,
    schedule: MySchedule[]
}

export interface MySchedule {
    meetingScheduleId: number,
    scheduleStart: string,
    title: string
}


export interface VendorCommunicationProps {
    vendor: Vendor,
    orderOptions: OrderOption
}

export interface Announcement {
    announcementId: number,
    title: string,
    messageBody: string,
    createdOn: string
}




export enum MenuType {
    Lobby = "Lobby",
    Order = "Order",
    OrderSummary = "OrderSummary",
    MessageCenter = "MessageCenter",
    CheckMessages = "CheckMessages",
    MyMeetingSchedule = "MyMeetingSchedule",
    ScheduleMeeting = "ScheduleMeeting",
    Instructions = "Instructions",
    Support = "Support",
    Announcements = "Announcements",
    VendorPortal = "VendorPortal",
    VendorInfo = "VendorInfo",
    ShowInfo = "ShowInfo",
    Booths = "Booths",
    Videos = "Videos",
    Contracts = "Contracts",
    NewItems = "NewItems",
    PrimeDeals = "PrimeDeals",
    Raffles = "Raffles",
    HostVideo = "HostVideo",
    Website = "Website",
    Logo = "Logo",
    Document = "Document",
    Video = "Video",
    Contact = "Contact",
    Image = "Image",
    HostBooth = "HostBooth",
    HostOrder = "HostOrder",
    Custom = "Custom",
    Link = "Link",
    Banner = "Banner",
    Email = "Email"

}

export interface LinkButton {
    name?: string,
    x?: number,
    y?: number,
    width?: number,
    height?: number,
    fill?: string,
    fillOpacity?: number,
    menuType: MenuType,
    index?: number,
}


export interface OrderGridFilterProps {
    startIndex: number,
    filter: OrderFilter,
    vendorAccountId: string[],
    customerId: string,
    includeVendors: boolean,
    query: string
}

export interface OrderGridProps {
    items: any,
    total: number,
    vendors?: OrderGridVendorProps[]
}

export interface OrderGridVendorProps {
    total: number,
    vendorAccountId: string,
    vendorName: string
}

export enum VideoStatus {
    NotInit = "NotInit",
    Connected = "Connected",
    Disconnected = "Disconnected"
}

export enum SessionFilterType {
    All = "All",
    Unread = "Unread"
}

export interface AppUpdate {
    showId: number,
    reloadItems: boolean,
    reloadSessions: boolean,
    reloadApp: boolean,
    newAnnouncement: boolean,
    reloadAnnouncements: boolean,
    logout: boolean,
    message: string,
    userId: string,
    audience: string
}

export interface ErrorStatus {
    error: string,
    message?: string;
}

export interface VirtualBoothSection {
    id: number;
    priority: number;
    imagePath: string;
    imageType: string;
    imageUrl: string;
    imageX: number;
    imageY: number;
    imageW: number;
    imageH: number;
}

export interface VirtualBooth {
    entityId: string;
    virtualBoothTypeId: number;
    name?: string;
    logo?: string;
    vendorCount?: number;
}

export enum VirtualBoothType {
    Lobby = 1,
    HostBooth = 2,
    Vendor = 3
}

export interface AppVersion {
    version: string;
}


export interface OrderNotes {
    customerId: string,
    catalogId: string,
    notes: string,
    promote: boolean
}
