import React from 'react';
import Participant from '../Participant/Participant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';


export default function ParticipantStrip() {
    const { room: { localParticipant }, } = useVideoContext();
    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();



    return (
        <React.Fragment>
            <Participant
                participant={localParticipant}
                isSelected={selectedParticipant === localParticipant}
                onClick={() => setSelectedParticipant(localParticipant)}
            />
            {participants.map(participant => (
                <Participant
                    key={participant.sid}
                    participant={participant}
                    isSelected={selectedParticipant === participant}
                    onClick={() => setSelectedParticipant(participant)}
                />
            ))}
        </React.Fragment>
    );
}
