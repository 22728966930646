import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import MainParticipant from '../MainParticipant/MainParticipant';

import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
    selectSidebarCollapsed
} from 'store/messenger';

export default function Room() {
    const sidebarCollapsed: boolean = useAppSelector(selectSidebarCollapsed);

    const css = clsx({
        "messenger-video-participant-list": true,
        "hidden": sidebarCollapsed
    });

    return (
        <React.Fragment>
            <div className={css}>
                <ParticipantStrip />
            </div>
            <div className="messenger-video-main-participant">
                <MainParticipant />
            </div>
        </React.Fragment>
    );
}
