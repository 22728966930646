import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import _ from "lodash";
import { Button, Layout, Timeline, Empty, Modal } from 'antd';
import {
    User,
    Customer,
    UserRole,
    MessengerSession,
    AnalyticsProps,
    AnalyticsType
} from 'model';
import {
    selectUser
} from 'store/user';
import {
    selectCustomer,
    analytic
} from 'store/order';
import {
    selectSessions
} from 'store/messenger';
import {
    ClockCircleOutlined,
    MessageTwoTone
} from '@ant-design/icons';
import { getDay } from 'utility/utility';
import {
    createSessionAsync
} from 'store/messenger';

interface Props {
    removeSchedule?: any,
    showRemoveButton: boolean
}

import { MeetingTime } from 'components/common/MeetingTime';

export const MyScheduleComponent: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch();

    const sessions = useAppSelector(selectSessions);

    const user: User = useAppSelector(selectUser);

    const customer: Customer = useAppSelector(selectCustomer);

    const onDeleteConfirm = (session: MessengerSession) => {
        props.removeSchedule(session);
    };

    const activeSessionWarning = (messengerSession: MessengerSession) => {
        return Modal.confirm({
            title: 'Confirmation',
            content: 'You are leaving the video chat, please confirm.',
            onOk: () => {
                window.dispatchEvent(new Event('disconnectFromRoom'));
                dispatch(createSessionAsync(user.role, messengerSession));
            },
            onCancel: () => {
            }
        });
    }

    const onVendorSelect = (vendorId: string) => {
        let messengerSession: MessengerSession = {
            customerId: customer.customerId,
            vendorId: vendorId
        };
        if (globalThis.twilioRoom != undefined) {
            activeSessionWarning(messengerSession);
        } else {
            window.dispatchEvent(new Event('disconnectFromRoom'));
            dispatch(createSessionAsync(user.role, messengerSession));
        }
        if (user.role == UserRole.Customer) {
            let analytics: AnalyticsProps = {
                id: vendorId,
                analyticsType: AnalyticsType.Message
            };
            dispatch(analytic(analytics));
        }
    };

    const onCustomerSelect = (session: MessengerSession) => {
        let messengerSession: MessengerSession = {
            customerId: session.customerId,
            vendorId: session.vendorId
        };
        if (globalThis.twilioRoom != undefined) {
            activeSessionWarning(messengerSession);
        } else {
            window.dispatchEvent(new Event('disconnectFromRoom'));
            dispatch(createSessionAsync(user.role, messengerSession));
        }
    };

    const meetings = (sessions: MessengerSession[]) => {
        if (user.role == UserRole.Customer) {
            return (<Timeline mode="left">
                {sessions.map((session: MessengerSession) => {
                    const vendorName = props.showRemoveButton ? <span>{session.vendorName}</span> : <Button type="link" onClick={() => onVendorSelect(session.vendorId)}>
                        {session.vendorName} <MessageTwoTone />
                    </Button>;
                    return <Timeline.Item key={session.sessionId} dot={<ClockCircleOutlined style={{ fontSize: 16 }} />}>
                        <div className="schedule-time">
                            <div className="schedule-time-label">
                                <MeetingTime
                                    startDate={session.scheduleStart}
                                    endDate={session.scheduleEnd}
                                    showDate={false}
                                />
                            </div>
                            <div className="schedule-time-remove">
                                {props.showRemoveButton &&
                                    <Button type="link" size="small" title="Remove" onClick={() => onDeleteConfirm(session)}>
                                        X
                                    </Button>
                                }
                            </div>
                        </div>
                        <div className="bold">
                            {vendorName}
                        </div>
                        {session.brokerName &&
                            <div> {session.brokerName}</div>
                        }
                    </Timeline.Item>;;
                })}
            </Timeline>);
        } else {
            return (<Timeline mode="left">
                {sessions.map((session: MessengerSession) => {
                    return <Timeline.Item key={session.sessionId} dot={<ClockCircleOutlined style={{ fontSize: 16 }} />}>
                        <div className="schedule-time">
                            <div className="schedule-time-label">
                                <MeetingTime
                                    startDate={session.scheduleStart}
                                    endDate={session.scheduleEnd}
                                    showDate={false}
                                />
                            </div>
                        </div>
                        <div className="bold">
                            <Button type="link" onClick={() => onCustomerSelect(session)}> {session.customerName} <MessageTwoTone /></Button>
                        </div>
                        <div>
                            {session.vendorName}
                        </div>
                    </Timeline.Item>;;
                })}
            </Timeline>);
        }
    };

    const days = () => {
        const filteredSessions = sessions.filter((messengerSession: MessengerSession) => {
            return messengerSession.meetingDate != null && messengerSession.meetingDate.length > 0;
        });
        let meetingDays = _.uniq(_.map(filteredSessions, 'meetingDate'));
        if (meetingDays.length > 0) {
            return (<div >
                {meetingDays.map((meetingDay: string) => {
                    let dayLabel = getDay(meetingDay);

                    let dayMeetings = filteredSessions.filter((messengerSession: MessengerSession) => {
                        return messengerSession.meetingDate == meetingDay;
                    });
                    return (
                        <div key={meetingDay}>
                            <h3> {dayLabel}</h3>
                            {meetings(dayMeetings)}
                        </div>
                    );
                })}
            </div>);
        } else {
            return <div style={{ marginTop: 60 }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>;
        }
    };

    return (
        <div className="my-schedule">
            {days()}
        </div>
    );
};


export const MySchedule = React.memo(MyScheduleComponent);
