import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import {
    Modal,
    Select,
    Form,
    Input,
    Layout,
    Button,
    Checkbox
} from 'antd';
const { Option } = Select;
import {
    Vendor,
    MessengerSession,
    CompanyInformation,
    MediaProps,
    MediaType,
    User,
    UserRole,
    AnalyticsProps,
    AnalyticsType,
    UserSettings,
    Company,
    CatalogItem,
    OrderNotes
} from 'model';
import {
    InfoCircleTwoTone,
    CloseOutlined,
    DownCircleTwoTone,
    SettingTwoTone,
    FormOutlined
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Email } from 'components/order/components/Email';
import {
    selectVendors,
    analytic
} from 'store/order';
import {
    selectUser,
    selectSettings,
    updateSettings
} from 'store/user';
import { createSessionAsync } from 'store/messenger';
import { Media } from 'components/order/components/Media';

export interface StateContextType {
    showCompanyInfo(id: string): void;
    email(sender: Company, recipient: Company): void;
    showMedia(content: MediaProps): void;
    vendorOnMessage(customerId: string): void;
    activeSessionWarning(messengerSession: MessengerSession): void;
    showMedia(content: MediaProps): void;
    logAnalytics(content: AnalyticsProps): void;
    showSettings(): void;
    showHideDrawer(status: boolean): void;
    drawerStatus: boolean;
    showNotes(catalogId: string, customerId: string): void;
}
export const StateContext = createContext<StateContextType>(null!);

interface State {
    emailWindowVisible: boolean,
    emailWindowTitle: string,
    mediaWindowVisible: boolean,
    mediaWindowTitle: any,
    sender: Company,
    recipient: Company,
    media: MediaProps,
    drawerVisible: boolean,
    notesWindowVisible: boolean,

}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {

    const dispatch = useAppDispatch();

    const vendors: Vendor[] = useAppSelector(selectVendors);

    const user: User = useAppSelector(selectUser);

    const userSettings: UserSettings = useAppSelector(selectSettings);

    const [state, setState] = useState<State>({
        emailWindowVisible: false,
        emailWindowTitle: "",
        mediaWindowVisible: false,
        mediaWindowTitle: "",
        sender: null,
        recipient: null,
        media: {
            mediaType: MediaType.None
        },
        drawerVisible: false,
        notesWindowVisible: false
    });

    let contextValue: StateContextType = {
        showCompanyInfo: (id) => {

            let company: Company = {
                id: id
            };

            let analytics: AnalyticsProps = {
                id: id,
                analyticsType: AnalyticsType.Info
            };
            dispatch(analytic(analytics));

            axios.post<CompanyInformation>("/order/companyinformation", company).then(response => {
                let email = `mailto:${response.data.email}`;
                return Modal.info({
                    title: response.data.name,
                    maskClosable: true,
                    icon: <InfoCircleTwoTone />,
                    width: 800,
                    content: (
                        <React.Fragment>
                            <p><span className="bold">Account #:</span> {response.data.accountId}</p>

                            {response.data.contact != null && response.data.contact.length > 0 &&
                                <p><span className="bold">Contact Name:</span> {response.data.contact}</p>
                            }

                            {response.data.phone != null && response.data.phone.length > 0 &&
                                <p><span className="bold">Phone:</span> {response.data.phone}</p>
                            }

                            {response.data.email != null && response.data.email.length > 0 &&
                                <p><span className="bold">Email:</span> <a href={email}>{response.data.email}</a></p>
                            }

                            {response.data.website != null && response.data.website.length > 0 &&
                                <p><span className="bold">Website:</span> <a href={response.data.website} target="_blank">{response.data.website}</a></p>
                            }

                            {response.data.logo != null && response.data.logo.length > 0 &&
                                <img className="vendor-logo" src={response.data.logo} />
                            }

                        </React.Fragment>
                    ),
                    onOk: () => {
                    }
                });
            });
        },
        email: (sender, recipient) => {
            setState(prevState => {
                return {
                    ...prevState,
                    sender: sender,
                    recipient: recipient,
                    emailWindowVisible: true,
                    emailWindowTitle: "Email"
                }
            });
            //vendor.vendorId != DefaultGuid ? "Email Vendor" : "Email Customer"
        },
        showMedia: (content) => {
            if (content.mediaType == MediaType.None) {
                setState(prevState => {
                    return {
                        ...prevState,
                        mediaWindowVisible: false,
                        mediaWindowTitle: "",
                        media: {
                            mediaType: MediaType.None
                        }
                    }
                });
            } else {

                if (user.role == UserRole.Customer && content.vendorAccountId) {

                    let analyticsType = AnalyticsType.Image;
                    switch (content.mediaType) {
                        case MediaType.Video:
                            analyticsType = AnalyticsType.Video;
                            break;
                        case MediaType.Image:
                            analyticsType = AnalyticsType.Image;
                            break;
                        case MediaType.File:
                            analyticsType = AnalyticsType.Document;
                            break;
                        default:
                            break;
                    }

                    const analyticsVendor = vendors.find(vendor => vendor.vendorAccountId == content.vendorAccountId);
                    if (analyticsVendor) {
                        let analytics: AnalyticsProps = {
                            id: analyticsVendor.vendorId,
                            analyticsType: analyticsType
                        };
                        dispatch(analytic(analytics));
                    }
                }

                let title = content.title;

                if (content.mediaType == MediaType.Contract) {
                    const contractUrl = `https://www.tradeshoweasy.com/documents/${globalThis.showId}/CONTRACT-${content.item}.pdf`;
                    title = <div>{content.title} - <a href={contractUrl} target="_blank"><DownCircleTwoTone /> Download Contract</a></div>;
                }

                setState(prevState => {
                    return {
                        ...prevState,
                        mediaWindowVisible: true,
                        mediaWindowTitle: title,
                        media: content
                    }
                });
            }

        },
        logAnalytics: (content) => {
            if (user.role == UserRole.Customer) {
                //const analyticsVendor = vendors.find(vendor => vendor.vendorAccountId == content.vendorAccountId);
                //if (analyticsVendor) {
                //    content.vendorId = analyticsVendor.vendorId;
                //    dispatch(analytic(content));
                //}
                dispatch(analytic(content));
            }
        },
        vendorOnMessage: (customerId) => {
            let messengerSession: MessengerSession = {
                customerId: customerId,
                vendorId: user.id
            };
            dispatch(createSessionAsync(user.role, messengerSession));
        },
        activeSessionWarning: (messengerSession: MessengerSession) => {
            showActiveSessionWarning(messengerSession);
        },
        showSettings: () => {

            return Modal.info({
                title: 'Settings',
                maskClosable: true,
                icon: <SettingTwoTone />,
                width: 600,
                style: modalWindowStyle,
                content: (
                    <React.Fragment>
                        <div className="left-aligned">
                            <span style={{ paddingRight: 5 }}> My Time Zone:</span>
                            <Select defaultValue={userSettings.timeZone} style={{ width: 120 }} onChange={handleTimeZoneChange}>
                                <Option value="America/New_York">Eastern</Option>
                                <Option value="America/Chicago">Central</Option>
                                <Option value="America/Denver">Mountain</Option>
                                <Option value="America/Los_Angeles">Pacific</Option>
                            </Select>
                        </div>
                    </React.Fragment>
                ),
                onOk: () => {
                }
            });
        },
        showHideDrawer: (status) => {
            setState(prevState => {
                return {
                    ...prevState,
                    drawerVisible: status
                }
            });
        },
        drawerStatus: state.drawerVisible,
        showNotes: (catalogId, customerId) => {

            //console.log("catalogId", catalogId, "customerId", customerId);

            let orderNotes: OrderNotes = {
                customerId: customerId,
                catalogId: catalogId,
                notes: "",
                promote: false
            };

            setState(prevState => {
                return {
                    ...prevState,
                    notesWindowVisible: true,
                }
            });

            axios.post<OrderNotes>("/order/ordernotes", orderNotes).then(response => {
                //console.log("Catalog", response.data);

                return Modal.info({
                    title: "Notes",
                    maskClosable: true,
                    icon: <FormOutlined />,
                    width: 600,
                    okButtonProps: {
                        hidden: true
                    },
                    visible: state.notesWindowVisible,
                    content: (
                        <React.Fragment>
                            <div className="left-aligned">
                                <Form
                                    {...layout}
                                    name="notesWindow"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}

                                    initialValues={{
                                        customerId: customerId,
                                        catalogId: response.data.catalogId,
                                        notes: response.data.notes,
                                        promote: response.data.promote
                                    }}
                                >
                                    <Form.Item
                                        hidden={true}
                                        name="customerId"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        hidden={true}
                                        name="catalogId"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Notes"
                                        name="notes"
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item
                                        colon={false}
                                        label=" "
                                        name="promote"
                                        valuePropName="checked"
                                    >
                                        <Checkbox style={{ lineHeight: '32px' }}>
                                            Promote
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                        <Button onClick={onNotesCancel} style={{ margin: '0 8px' }} >
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </React.Fragment>
                    ),
                    onOk: () => {
                    }
                });
            });
        }

    };

    const onNotesCancel = () => {
        //console.log("Cancel Notes");

        setState(prevState => {
            return {
                ...prevState,
                notesWindowVisible: false
            }
        });
        Modal.destroyAll();
    }

    const onFinish = (values: any) => {
        //console.log("Submit Notes:", values);

        let orderNotes: OrderNotes = {
            customerId: values.customerId,
            catalogId: values.catalogId,
            notes: values.notes,
            promote: values.promote
        };

        axios.post<OrderNotes>("/order/ordernotesupdate", orderNotes).then(response => {
            setState(prevState => {
                return {
                    ...prevState,
                    notesWindowVisible: false
                }
            });
            Modal.destroyAll();
        });


    }

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 20 },
    };
    const fullLayout = {
        wrapperCol: { span: 24 },
    };

    const onFinishFailed = errorInfo => {
        //console.log('Failed:', errorInfo);
    };

    const modalWindowStyle = {
        top: 0,
        marginBottom: 0,
        paddingBottom: 0
    };

    const handleTimeZoneChange = (value: any) => {
        let newSettings = { ...userSettings };
        newSettings.timeZone = value;
        dispatch(updateSettings(newSettings));
    };

    const windowOnOk = () => {
        //console.log("windowOnOk");
        setState(prevState => {
            return {
                ...prevState,
                emailWindowVisible: false,
                mediaWindowVisible: false,
                media: {
                    mediaType: MediaType.None
                }
            }
        });
        Modal.destroyAll();
    };

    //const createSession = (customerId: string, vendorId: string) => {
    //    let messengerSession: MessengerSession = {
    //        customerId: customerId,
    //        vendorId: vendorId
    //    };
    //    dispatch(createSessionAsync(messengerSession));
    //};

    //const showVendorMessage = (customerId: string) => {
    //    if (vendors.length === 1) {
    //        createSession(customerId, vendors[0].vendorId);
    //    } else {
    //        if (vendor.vendorId == DefaultGuid) {
    //            return Modal.info({
    //                title: "Select a Vendor",
    //                maskClosable: true,
    //                icon: <InfoCircleTwoTone />,
    //                okText: "Cancel",
    //                okButtonProps: { type: "default" },
    //                width: 500,
    //                content: (
    //                    <ul className="simple-list">
    //                        {vendors.map((currentVendor: Vendor) => {
    //                            return <li key={currentVendor.vendorAccountId}
    //                                title={currentVendor.vendorName}
    //                            >
    //                                <Button type="default"
    //                                    block
    //                                    style={{ width: '100%' }}
    //                                    onClick={() => {
    //                                        createSession(customerId, currentVendor.vendorId);
    //                                        Modal.destroyAll();

    //                                        dispatch(hideAllActiveModals(true));
    //                                    }
    //                                    }>
    //                                    {currentVendor.vendorName}
    //                                </Button>
    //                            </li>;
    //                        })}
    //                    </ul>
    //                )
    //            });
    //        } else {
    //            createSession(customerId, vendor.vendorId);

    //            dispatch(hideAllActiveModals(true));

    //        }
    //    }
    //};

    const showActiveSessionWarning = (messengerSession: MessengerSession) => {
        return Modal.confirm({
            title: 'Confirmation',
            content: 'You are leaving the video chat, please confirm.',
            onOk: () => {
                //console.log("OK");
                window.dispatchEvent(new Event('disconnectFromRoom'));
                dispatch(createSessionAsync(user.role, messengerSession));
            },
            onCancel: () => {
                //console.log("CANCEL");
            }
        });
    }

    const modalWindowConfig: any = {
        className: "modal-window",
        width: "100%",
        maskClosable: false,
        style: {
            top: 0,
            paddingBottom: 0
        },
        closable: true,
        closeIcon: <React.Fragment> CLOSE <CloseOutlined /></React.Fragment>,
        destroyOnClose: true
    };

    return <StateContext.Provider value={{ ...contextValue }}>
        {props.children}
        <Modal
            open={state.emailWindowVisible}
            className="media-modal"
            title={state.emailWindowTitle}
            onOk={windowOnOk}
            onCancel={windowOnOk}
            okButtonProps={{ hidden: true }}
            footer={null}
            {...modalWindowConfig}>
            <Email
                sender={state.sender}
                recipient={state.recipient}
                onCancel={windowOnOk} />
        </Modal>
        <Modal
            className="media-modal"
            open={state.mediaWindowVisible}
            title={state.mediaWindowTitle}
            onOk={windowOnOk}
            onCancel={windowOnOk}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
            footer={null}
            {...modalWindowConfig}
        >
            <Media media={state.media} />
        </Modal>
    </StateContext.Provider>;
}

export function useAppState() {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}