import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Header as OrderHeader } from 'components/order/Header';
import {
    Vendor,
    OrderOption,
} from 'model/index';
import { Messenger } from 'components/messenger/Messenger';
import { Loading } from 'components/loading/Loading';
import { Route, Switch, useLocation, matchPath } from "react-router-dom";
import clsx from 'clsx';
import { Layout } from 'antd';
import { VendorSlider } from 'components/order/VendorSlider';
import {
    getSettingsAsync,
    selectIsLoaded,
    selectVendors,
    selectOrderOptions,
} from 'store/order';
import AppStateProvider from './AppStateProvider';
import { Navigation } from './Navigation';
import { MenuIcon } from './MenuIcon';

import {
    getAnnouncementsAsync
} from 'store/user';


type OrderLayoutProps = {
    children: any
};

export const OrderLayout = (props: OrderLayoutProps) => {

    const dispatch = useAppDispatch();
    const isLoaded: boolean = useAppSelector(selectIsLoaded);
    const vendors: Vendor[] = useAppSelector(selectVendors);
    const location = useLocation();
    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    React.useEffect(() => {
        if (!isLoaded) {

            dispatch(getSettingsAsync());

            dispatch(getAnnouncementsAsync(false));
        }
    }, [isLoaded]);

    const vendorsWithLogos = vendors.filter((vendor: Vendor) => {
        return vendor.logo != null && vendor.primeVendor && vendor.logo.length > 0;
    });

    const isOrderPage = matchPath(location.pathname, {
        path: '/order',
        exact: true,
        strict: false
    })

    const headerHeight: string = (isOrderPage != null && vendorsWithLogos.length > 0) ? "111px" : "50px";

    const messengerCss = clsx({
        "hidden": !orderOptions.messageEnabled,
    });

    if (isLoaded) {
        return (
            <AppStateProvider>
                <MenuIcon />
                <div className="order-window">
                    <div className="order-header" style={{ height: headerHeight, minHeight: headerHeight }} >
                        <OrderHeader />
                        <Switch>
                            <Route exact path="/order">
                                <VendorSlider />
                            </Route>
                        </Switch>
                    </div>
                    <Layout>
                        {props.children}
                    </Layout>
                </div>
                <div className={messengerCss}>
                    <Messenger />
                </div>
                <Navigation />
            </AppStateProvider>
        )
    } else {
        return (
            <Loading />
        );
    }
};

