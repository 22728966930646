import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectUser,
} from 'store/user';
import {
    User,
    UserRole
} from 'model/index';
import { CustomerMessageCenter } from './CustomerMessageCenter';
import { VendorMessageCenter } from './VendorMessageCenter';

const MessageCenterPage: React.FC = () => {

    const user: User = useAppSelector(selectUser);

    React.useEffect(() => {
        document.title = `Message Center | ${globalThis.showTitle}`;
    }, []);

    return (
        <React.Fragment>
            {user.role == UserRole.Customer ? <CustomerMessageCenter /> : <VendorMessageCenter isStandAlone={false} />}
        </React.Fragment>
    );
};

export default MessageCenterPage;