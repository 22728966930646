import React from 'react';
import ReactPlayer from 'react-player';
import { MediaProps, MediaType } from 'model/index';

import { Contract } from './Contract';

interface Props {
    media: MediaProps
}
export const Media: React.FC<Props> = (props) => {
    //console.log("Media", props.media);
    switch (props.media.mediaType) {
        case MediaType.Video:
            let url = `${props.media.url}`;
            //let url = `https://www.youtube.com/watch?v=${props.media.url}`;
            return (
                <div style={{ padding: 0, width: '100%', height: '100%'  }}>
                    <ReactPlayer
                        controls={true}
                        url={url}
                        playing={true}
                        width={'100%'}
                        height={'100%'} />
                </div>
            );
        case MediaType.Image:
            return (
                <div className="centered">
                    <img src={props.media.url} className="responsive" />
                </div>
            );
        case MediaType.File:
            return (
                <div className="centered">
                    <iframe src={props.media.url} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
            );
        case MediaType.Contract:
            return (
                <Contract catalogId={props.media.item} title={props.media.title} />
            );
        default:
        case MediaType.None:
            return <React.Fragment />;
    }
}
