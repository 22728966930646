import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    Vendor,
    UserRole
} from 'model/index';
import { Button, message } from 'antd';
import { DefaultGuid } from 'utility/utility';
import clsx from 'clsx';
import history from 'store/history';
import {
    selectCustomer,
    resetCustomer
} from 'store/order';

import { selectUser } from 'store/user';
import { VendorCustomer } from './VendorCustomer';
import { FilterOptions } from './FilterOptions';

import { setError, showMessage } from 'store/user';

import { isMobile } from 'utility/utility';

interface State {
    searching: boolean;
    searchText: string;
    selectedVendor: Vendor;
}

export const Filters: React.FC = () => {

    const dispatch = useAppDispatch();

    const user = useAppSelector(selectUser);

    const customer = useAppSelector(selectCustomer);

    const completeOnClick = () => {
        //console.log("COMPLETE");
        switch (user.role) {
            case UserRole.Vendor:
            case UserRole.Broker:
                dispatch(showMessage(`Order submitted for ${customer.customerName} [${customer.customerAccountId}]`));
                dispatch(resetCustomer());
                break;
            case UserRole.Customer:
                dispatch(showMessage(`Thank you for your order`));
                history.push("/order-summary");
                break;
            default:
                break;
        }
    };

    const buttonText = (): string => {
        //console.log("COMPLETE");
        switch (user.role) {
            case UserRole.Vendor:
            case UserRole.Broker:
                return "COMPLETE ORDER";
                break;
            case UserRole.Customer:
                if (isMobile) {
                    return "COMPLETE ORDER";
                } else {
                    return "ORDER REPORTS/COMPLETE ORDER";
                }
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <div id="grid-filters">
                <div className="filters">
                    <VendorCustomer />
                    <div className="filter-item">
                        <FilterOptions />
                    </div>
                </div>
                <div className={clsx({
                    'submit': true,
                    'hidden': customer.customerId == DefaultGuid
                })}>
                    <Button type="primary" onClick={completeOnClick}>{buttonText()}</Button>
                </div>
            </div>
        </React.Fragment>
    );
}
