import React from 'react';
import { MySchedule } from './MySchedule';
import { TimeZone } from 'components/common/TimeZone';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    Customer,
    Show 
} from 'model/index';
import {
    PrinterOutlined
} from '@ant-design/icons';
import {
    selectCustomer,
    selectShow,
} from 'store/order';
const MeetingSchedulePage: React.FC = () => {

    const customer: Customer = useAppSelector(selectCustomer);

    const show: Show = useAppSelector(selectShow);

    React.useEffect(() => {
        document.title = `My Meeting Schedule | ${globalThis.showTitle}`;
    }, []);

    let printUrl = `https://www.tradeshoweasy.com/report/${show.showId}/meeting-schedule/${customer.customerId}?entity=customer`;

    return (
        <div className="page-content">
            <h1>
                My Meetings
            </h1>
            <div className="content-html"  >
                <div className="right-aligned">
                    <a href={printUrl} target="_blank"><PrinterOutlined /> Print</a>    <TimeZone />
                </div>
                <MySchedule showRemoveButton={false} />
            </div>
        </div>
    );
};
export default MeetingSchedulePage;
