import React, { useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Input, Layout, Button as CustomButton, Select } from 'antd';
const { Search } = Input;
const { Option } = Select;
import styles from './Index.module.css';
import {
    resetCustomer
} from 'store/order';
import { CustomerItem } from './Customer';
const { Header, Content } = Layout;
import axios from 'axios';
import { Virtuoso } from 'react-virtuoso';
import useDebounce from 'components/hooks/useDebouncedEffect';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import * as log from 'loglevel';


export enum CustomerFilterType {
    All = "All",
    Stores = "Stores",
    Chains = "Chains",
}
interface State {
    filterType: CustomerFilterType;
    searchText: string;
    isFiltered: boolean;
}
export type FilterType = {
    pageSize: number,
    startIndex: number,
    filter?: CustomerFilterType,
    query?: string,
}

interface Props {
    isStandAlone: boolean;
}

export const VendorMessageCenter: React.FC<Props> = (props) => {

    const virtuoso = useRef(null);

    const dispatch = useAppDispatch();

    const { height, width } = useWindowDimensions();

    const [state, setState] = useState<State>({
        filterType: CustomerFilterType.All,
        searchText: "",
        isFiltered: false
    });

    const [gridState, setGridState] = useState<{
        total: number,
        items: any[],
    }>({
        total: 0,
        items: []
    })

    React.useEffect(() => {
        document.title = `Message Center | ${globalThis.showTitle}`;
    }, []);

    const handleTextChange = (searchText: string) => {
        setState(prevState => {
            return {
                ...prevState,
                searchText: searchText
            }
        });
    };

    const handleFilterChange = (value: any) => {
        setState(prevState => {
            return {
                ...prevState,
                filterType: value,
            }
        });
    };

    const resetSearch = () => {
        setState(prevState => {
            return {
                ...prevState,
                searchText: "",
                filterType: CustomerFilterType.All
            }
        });
    };

    const searchCustomer = (searchText: string) => {
        //console.log(searchText);
        setState(prevState => {
            return {
                ...prevState,
                searchText: searchText
            }
        });
    };

    const getValue = () => {
        let result: string = "";
        result = state.filterType;
        return result;
    };

    const getOptions = () => {
        const children = [];
        let key: number = 1;
        children.push(<Option key={key++} value={CustomerFilterType.All}>All Customers</Option>);
        children.push(<Option key={key++} value={CustomerFilterType.Chains}>Chains Only</Option>);
        children.push(<Option key={key++} value={CustomerFilterType.Stores}>Stores Only</Option>);
        return children;
    };

    const pageSize: number = 150;

    const [isScrolling, setIsScrolling] = useState(false);

    const [visibleRange, setVisibleRange] = useState({
        startIndex: 0,
        endIndex: 0,
    });
    const getData = (startIndex: number) => {

        startIndex = startIndex - 50;
        if (startIndex < 0) {
            startIndex = 0;
        }
        const filter: FilterType = {
            pageSize: pageSize,
            startIndex: startIndex,
            filter: state.filterType,
            query: state.searchText
        };

        let isFiltered: boolean = state.searchText.length > 0 || state.filterType != CustomerFilterType.All;

        setState(prevState => {
            return {
                ...prevState,
                isFiltered: isFiltered
            }
        });

        return axios.post("/customer/list", filter).then(response => {
            if (response) {
                setGridState(prevState => {
                    return {
                        ...prevState,
                        total: response.data.resultsCount,
                        items: response.data.data
                    }
                });

                if (virtuoso.current) {
                    virtuoso.current.scrollToIndex(0);
                }
            }
        });
    }
    //key: (...args) => null,

    const updateGrid = () => {

        let startIndex: number = gridState.items.length > 0 ? gridState.items[0].rank - 1 : 0;
        let endIndex: number = gridState.items.length > 0 ? gridState.items[gridState.items.length - 1].rank : 0;
        //PADDING RANGES
        let targetStartIndex: number = visibleRange.startIndex;
        let targetEndIndex: number = visibleRange.endIndex;
        if (targetStartIndex < 0) {
            targetStartIndex = 0;
        }
        if (endIndex > 0 && targetEndIndex > gridState.total) {
            targetEndIndex = gridState.total;
        }
        if (targetStartIndex >= startIndex &&
            targetStartIndex <= endIndex &&
            targetEndIndex >= startIndex &&
            targetEndIndex <= endIndex
        ) {

        } else {
            //console.log("ITEM NOT in range. Should have:", visibleRange.startIndex, visibleRange.endIndex, " current:", startIndex, endIndex);
            getData(visibleRange.startIndex);
        }
    }

    useDebounce(() => {
        updateGrid();
    }, 200, [visibleRange]);

    useDebounce(() => {
        //console.log("INIT");
        getData(0);

    }, 300, [state.searchText, state.filterType]);

    useEffect(() => {
        dispatch(resetCustomer());
    }, []);

    const getItem = (index: number) => {
        const customer = gridState.items.find((row: any) => {
            return row.rank === index + 1;
        });

        if (customer) {
            return <CustomerItem key={index} index={index} customer={customer} />;
        } else {
            return <div key={index} className={styles.item}><div className={styles.loading}>Loading...</div></div>;
        }
    };

    const contentStyle = () => {
        if (height < 768) {
            return {
                marginTop: props.isStandAlone ? 0 : 125,
                backgroundColor: '#FFFFFF',
                height: 560
            };
        } else {
            return {
                marginTop: props.isStandAlone ? 0 : 125,
                backgroundColor: '#FFFFFF'
            };
        }
    }

    return (
        <Layout style={{ height: '100%' }}>
            <Header style={{
                position: props.isStandAlone ? 'relative' : 'fixed',
                zIndex: 1,
                padding: 0,
                margin: 0,
                width: '100%',
                backgroundColor: '#FFFFFF'
            }}>
                {!props.isStandAlone &&
                    <h1 className="page-header">
                        Message Center
                    </h1>
                }
                <div className={styles.header}>
                    <div className={styles.filter}>
                        <Input.Group compact style={{ marginTop: '10px' }}>
                            <Select size="large" style={{ width: 150 }}
                                onChange={handleFilterChange}
                                filterOption={(input, option) =>
                                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                optionFilterProp="children"
                                value={getValue()}>
                                {getOptions()}
                            </Select>
                            <Search
                                style={{ width: 400 }}
                                placeholder="Search Customers..."
                                onSearch={value => searchCustomer(value)}
                                onChange={e => handleTextChange(e.target.value)}
                                allowClear
                                size="large"
                                value={state.searchText}
                            />
                            {state.isFiltered &&
                                <CustomButton type="link" onClick={() => resetSearch()} size="large">Reset</CustomButton>
                            }
                        </Input.Group>
                    </div>
                    <div className={styles.count}>
                        {gridState.total === 1 ? '1 customer.' : `${gridState.total} customers.`}
                    </div>
                </div>
            </Header>
            <Content style={contentStyle()}>
                <Virtuoso
                    ref={virtuoso}
                    isScrolling={setIsScrolling}
                    rangeChanged={setVisibleRange}
                    style={{ flex: 1 }}
                    totalCount={gridState.total}
                    itemContent={getItem}
                />
            </Content>
        </Layout>
    );
};
