import React, { useState, useRef } from 'react';
import * as log from 'loglevel';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Input, Space } from 'antd';
import {
    Vendor,
    Broker,
    OrderFilterType,
    OrderFilter,
    defaultVendor,
    defaultBroker,
    User,
    UserRole,
    AnalyticsProps,
    AnalyticsType,
    VendorViewType
} from 'model/index';
import clsx from 'clsx';
import { SearchOutlined } from '@ant-design/icons';
import {
    selectVendors,
    selectBrokers,
    filterVendor,
    filterBrokerVendor,
    filterBroker,
    selectVendor,
    selectBroker,
    selectVendorsWithOrders,
    selectFilterType,
    resetAllFilters,
    selectVendorViewType,
    setVendorViewType,
    analytic
} from 'store/order';

import { selectUser } from 'store/user';
import { DefaultGuid } from '../../utility';
const { Search } = Input;

interface State {
    searching: boolean;
    searchText: string;
    selectedVendor: Vendor;
    currentVendors: Vendor[];
}

import _ from "lodash";

import { Radio, Button } from 'antd';

let scrollCompany: string = DefaultGuid;

export const Vendors: React.FC = () => {

    const dispatch = useAppDispatch();

    const vendors: Vendor[] = useAppSelector(selectVendors);

    const vendorsWithOrders: string[] = useAppSelector(selectVendorsWithOrders);

    const brokers: Broker[] = useAppSelector(selectBrokers);

    const selectedVendor: Vendor = useAppSelector(selectVendor);

    const selectedBroker: Broker = useAppSelector(selectBroker);

    const vendorViewType: VendorViewType = useAppSelector(selectVendorViewType);

    const currentFilterType: OrderFilter = useAppSelector(selectFilterType);

    const user: User = useAppSelector(selectUser);

    const [state, setState] = useState<State>({
        searching: false,
        searchText: "",
        selectedVendor: defaultVendor,
        currentVendors: vendors
    });

    //const vendorTypeFilters = useRef(null);

    //Create refs for vendors
    const vendorRefs = vendors.reduce((vendor, value) => {
        vendor[value.vendorId] = React.createRef();
        return vendor;
    }, {});

    //Create refs for brokers
    const brokerRefs = brokers.reduce((broker, value) => {
        broker[value.brokerId] = React.createRef();
        return broker;
    }, {});

    //Merge refs
    const refs = {
        ...vendorRefs,
        ...brokerRefs
    };

    //React.useEffect(() => {
    //    if (selectedVendor.vendorId == DefaultGuid && state.searching && currentFilterType.filterType == OrderFilterType.All) {
    //        setState(prevState => {
    //            return {
    //                ...prevState,
    //                searchText: "",
    //                searching: false
    //            }
    //        });
    //    }
    //}, [currentFilterType]);

    React.useEffect(() => {
        let companyId: string = DefaultGuid;
        //Vendor is selected
        if (selectedVendor.vendorId != DefaultGuid) {
            companyId = selectedVendor.vendorId;
        }
        //Vendor is selected
        if (selectedBroker.brokerId != DefaultGuid) {
            companyId = selectedBroker.brokerId;
        }
        if (companyId != DefaultGuid) {
            if (refs[companyId] && scrollCompany != companyId && refs[companyId].current) {
                refs[companyId].current.scrollIntoView(false);
            }
        }

    }, [selectedVendor, selectedBroker]);

    React.useEffect(() => {

        let filteredVendors: Vendor[] = [];

        switch (currentFilterType.filterType) {
            case OrderFilterType.Purchased:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendorsWithOrders.includes(vendor.vendorAccountId);
                });
                break;
            case OrderFilterType.New:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.newItems === true;
                });
                break;
            case OrderFilterType.History:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.historyItems === true;
                });
                break;
            case OrderFilterType.Prime:
            case OrderFilterType.Booth:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.primeVendor === true;
                });
                break;
            case OrderFilterType.All:
                filteredVendors = vendors;
                break;
            case OrderFilterType.Contracts:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.contracts === true;
                });
                break;
            case OrderFilterType.Videos:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.videos === true;
                });
                break;
            case OrderFilterType.Documents:
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.documents === true;
                });
                break;
            case OrderFilterType.Category:
                //filteredVendors = vendors;
                //console.log("CATEGORY", currentFilterType.query);
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.category != null && vendor.category.indexOf(currentFilterType.query) > -1;
                });
                break;
              case OrderFilterType.Tag:
                //filteredVendors = vendors;
                //console.log("TAG", currentFilterType.query);
                filteredVendors = vendors.filter((vendor: Vendor) => {
                    return vendor.tags != null && vendor.tags.includes(currentFilterType.query)
                });
                break;
            default:
                break;
        }

        //filteredVendors = vendors;

        if (state.searchText.length > 0) {
            let searchFor = new RegExp(state.searchText, 'i');
            filteredVendors = filteredVendors.filter((vendor: Vendor) => {
                return searchFor.test(vendor.vendorName) || searchFor.test(vendor.vendorAccountId);
            });
        }

        setState(prevState => {
            return {
                ...prevState,
                currentVendors: filteredVendors
            }
        });

    }, [currentFilterType, state.searchText]);

    React.useEffect(() => {
        //console.log(state.currentVendors.length);
        if (state.currentVendors.length === 1) {
            dispatch(filterVendor(state.currentVendors[0]));
        }
    }, [state.currentVendors]);

    const vendorClickHandler = (vendor: Vendor) => {
        //console.log("Vendor Click:", vendor.vendorId);
        if (vendor.vendorId == DefaultGuid) {
            dispatch(resetAllFilters());
        } else {
            dispatch(filterVendor(vendor));
        }
        scrollCompany = vendor.vendorId;
        if (user.role == UserRole.Customer) {
            let analytics: AnalyticsProps = {
                id: vendor.vendorId,
                analyticsType: AnalyticsType.Vendor
            };
            dispatch(analytic(analytics));
        }
    };

    const brokerClickHandler = (broker: Broker) => {
        //console.log("Vendor Click:", vendor.vendorId);
        if (broker.brokerId == DefaultGuid) {
            dispatch(resetAllFilters());
        } else {
            dispatch(filterBroker(broker));

            scrollCompany = broker.brokerId;

            if (user.role == UserRole.Customer) {
                let analytics: AnalyticsProps = {
                    id: broker.brokerId,
                    analyticsType: AnalyticsType.Vendor
                };
                dispatch(analytic(analytics));
            }
        }
    };

    const brokerVendorClickHandler = (vendor: Vendor) => {

        dispatch(filterBrokerVendor(vendor));

        if (user.role == UserRole.Customer) {
            let analytics: AnalyticsProps = {
                id: vendor.vendorId,
                analyticsType: AnalyticsType.Vendor
            };
            dispatch(analytic(analytics));
        }
    };

    const searchClickHandler = () => {
        dispatch(resetAllFilters());
        setState(prevState => {
            return {
                ...prevState,
                searching: true
            }
        });
    };

    const handleChange = (searchText: string) => {
        setState(prevState => {
            return {
                ...prevState,
                searchText: searchText
            }
        });
    };

    const searchVendor = (searchText: string) => {

        if (searchText.length === 0) {
            dispatch(filterVendor(defaultVendor));
            setState(prevState => {
                return {
                    ...prevState,
                    searchText: "",
                    searching: false
                }
            });
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    searchText: searchText
                }
            });
        }
    };

    const filterOnChange = (e: any) => {
        dispatch(setVendorViewType(e.target.value));
    };

    const vendorHeader = () => {
        if (user.role == UserRole.Customer && brokers.length > 0) {
            return <Radio.Group buttonStyle="outline" defaultValue={vendorViewType} value={vendorViewType} >
                <Radio.Button value={VendorViewType.Vendor} onClick={filterOnChange}
                    style={{ height: 30, width: (user.role == UserRole.Customer) ? 100 : 200, textAlign: 'center' }}
                    type="default">
                    Vendors
                </Radio.Button>
                <Radio.Button value={VendorViewType.Broker} onClick={filterOnChange}
                    style={{ height: 30, width: 100, textAlign: 'center' }}
                    type="default">
                    Brokers
                </Radio.Button>
            </Radio.Group>;
        } else {
            return <div className="flex">
                <div className="link" onClick={() => vendorClickHandler(defaultVendor)}>
                    <strong>ALL VENDORS</strong>
                </div>
            </div>;
        }
    };

    const vendorSearchBar = () => {
        if (state.searching) {
            return (<React.Fragment>
                <div><Space direction="vertical" align="baseline" size="large">
                    <Search autoFocus={true}
                        placeholder="Search vendors..."
                        onSearch={value => searchVendor(value)}
                        onChange={e => handleChange(e.target.value)}
                        allowClear
                    />
                </Space></div>
            </React.Fragment>);
        } else {
            return (<React.Fragment>
                <div className="flex">
                    {vendorHeader()}
                    <div className="push" onClick={searchClickHandler}>
                        <SearchOutlined style={{ fontSize: '18px', verticalAlign: 'middle' }} />
                    </div>
                </div>
            </React.Fragment>);
        }
    };

    const brokerVendorList = (vendors: Vendor[], brokerIndex: number) => {
        return vendors.map((vendor: Vendor, index: number) => {
            let css = clsx({
                'indent': true,
                'order-vendor': vendorsWithOrders.includes(vendor.vendorAccountId),
                'bold-indent': selectedVendor.vendorId === vendor.vendorId
            });
            const id: string = `${brokerIndex}-${index}`;
            //console.log(id);
            return <li key={id}
                title={vendor.vendorName}
                className={css}
                onClick={() => brokerVendorClickHandler(vendor)}>
                {vendor.vendorName}
            </li>;
        });
    }
    const brokerList = () => {
        return brokers.map((broker: Broker, index: number) => {
            const vendorAccountIds: string[] = _.map(broker.vendors, (vendor: Vendor) => vendor.vendorAccountId);
            const brokerHasOrders = vendorAccountIds.filter(vendorAccountId => vendorsWithOrders.includes(vendorAccountId));
            let css = clsx({
                'order-vendor': brokerHasOrders.length > 0,
                'bold': selectedBroker.brokerId === broker.brokerId
            });
            return <React.Fragment key={index}>
                <li
                    ref={refs[broker.brokerId]}
                    title={broker.brokerName}
                    className={css}
                    onClick={() => brokerClickHandler(broker)}>
                    {broker.brokerName}
                </li>
                {selectedBroker.brokerId === broker.brokerId &&
                    brokerVendorList(broker.vendors, index)
                }
            </React.Fragment>;
        });
    }

    const vendorList = () => {
        return state.currentVendors.map((vendor: Vendor, index: number) => {
            //log.debug(selectedVendor, vendor.vendorId);
            let css = clsx({
                'order-vendor': vendorsWithOrders.includes(vendor.vendorAccountId),
                'bold': selectedVendor.vendorId === vendor.vendorId
            });
            return <li key={index}
                ref={refs[vendor.vendorId]}
                title={vendor.vendorName}
                className={css}
                onClick={() => vendorClickHandler(vendor)}>
                {vendor.vendorName}
            </li>;
        });

    }

    const list = () => {
        if ((user.role == UserRole.Customer || user.role == UserRole.Broker) && brokers.length > 0) {
            if (vendorViewType === VendorViewType.Vendor) {
                return vendorList();
            } else {
                return brokerList();
            }
        } else {
            return vendorList();
        }
    }

    return (
        <React.Fragment>
            <div className="all-vendors">
                {vendorSearchBar()}
            </div>
            <ul className="vendor-list">
                {list()}
            </ul>
        </React.Fragment>
    );
}
