import packageJson from '../../package.json';
import { AppConfig } from 'model/index';
var app: AppConfig;
globalThis.app = {
    tokenName: "token-v1",
    baseUrl: "api",
    version: packageJson.version
};
globalThis.showId = 0;
// Freeze so these can only be defined in this file.
Object.freeze(globalThis.app);