import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
    Announcement
} from 'model/index';

import { getDate } from 'utility/utility';

import {
    selectAnnouncements,
    getAnnouncementsAsync,
    selectAnnouncementNotification
} from 'store/user';
import { Empty } from 'antd';
import * as log from 'loglevel';

const AnnouncementsPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const announcementNotification: number = useAppSelector(selectAnnouncementNotification);

    const announcements: Announcement[] = useAppSelector(selectAnnouncements);

    const createMarkup = (content: string) => {
        return {
            __html: content
        };
    }

    React.useEffect(() => {
        document.title = `Announcements | ${globalThis.showTitle}`;
        //console.log("Load Announcement");

        dispatch(getAnnouncementsAsync(false));

    }, []);

    React.useEffect(() => {
        //console.log("announcementCount", announcementNotification);
        if (announcementNotification > 0) {
            dispatch(getAnnouncementsAsync(false));
        }
    }, [announcementNotification]);

    const announcementsList = announcements.map((announcement: Announcement) => {
        return (
            <li key={announcement.announcementId}>
                <h2>
                    {announcement.title}
                </h2>
                <div className="announcement-content-html" dangerouslySetInnerHTML={createMarkup(announcement.messageBody)}>
                </div>
                <div className="date">
                    {getDate(announcement.createdOn)}
                </div>
            </li>
        );
    });

    return (
        <div className="page-content">
            <h1>Announcements</h1>
            <div className="content-html">
                {(announcements && announcements.length > 0) ?
                    <ul className="announcements-list">
                        {announcementsList}
                    </ul> : <div className="center-content"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}

            </div>
        </div>
    );
};

export default AnnouncementsPage;