import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppStateProvider, { useAppState } from './state';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import { isMobile } from './utils';
import './types';
import { VideoProvider } from './components/VideoProvider';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    //audio: true,
    video: {
        height: 480,
        frameRate: 24,
        width: 640
    },
    audio: {
        workaroundWebKitBug180748: true
    },
    bandwidthProfile: {
        video: {
            mode: 'collaboration',
            dominantSpeakerPriority: 'high',
            maxTracks: 20,
            maxSubscriptionBitrate: 0,
            renderDimensions: {
                high: {
                    height: 720,
                    width: 1280
                },
                standard: {
                    height: 480,
                    width: 640
                },
                low: {
                    height: 144,
                    width: 176
                }
            }
        }
    },
    dominantSpeaker: true,
    maxAudioBitrate: 0,
    maxVideoBitrate: 0,
    networkQuality: {
        local: 1,
        remote: 1
    },
    //DSCP tagging allows you to request enhanced QoS treatment for RTP media packets from any firewall that the client may be behind.Setting this option to true will request DSCP tagging for media packets on supported browsers(only Chrome supports this as of now).Audio packets will be sent with DSCP header value set to 0xb8 which corresponds to Expedited Forwarding(EF).Video packets will be sent with DSCP header value set to 0x88 which corresponds to Assured Forwarding(AF41).
    enableDscp: true,
    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. This has no effect if you are
    // using Peer-to-Peer Rooms.
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    //logLevel: 'debug' 
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

const VideoApp = () => {
    const { error, setError } = useAppState();
    return (
        <VideoProvider options={connectionOptions} onError={setError}>
            <ErrorDialog dismissError={() => setError(null)} error={error} />
            <App />
        </VideoProvider>
    );
};

export const Video: React.FC = () => {

    //const videoStatus = useAppSelector(selectVideoStatus);

    return (
        <AppStateProvider>
            <VideoApp />
        </AppStateProvider>
    );
};
