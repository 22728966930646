import React from 'react';
import * as log from 'loglevel';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    MessengerSession,
    UserRole,
    Vendor,
    Broker,
    Customer,
    AnalyticsProps,
    AnalyticsType,
    SessionFilterType,
    defaultVendor,
    defaultBroker
} from 'model/index';
import clsx from 'clsx';
import { Modal } from 'antd';
import {
    selectActiveSession,
    selectSessions,
    getMessagesAsync,
    selectSessionFilter
} from 'store/messenger';
import {
    filterVendor,
    filterBroker,
    selectVendors,
    selectBrokers,
    setCustomerAsync,
    selectCustomer,
    analytic
} from 'store/order';
import {
    selectUser
} from 'store/user';
import { Menu } from 'antd';
//import { getMeetingDayAndTime } from 'utility/utility';

import { MeetingTime } from 'components/common/MeetingTime';

export const SessionList: React.FC = () => {

    const dispatch = useAppDispatch();

    const user = useAppSelector(selectUser);

    const sessions: MessengerSession[] = useAppSelector(selectSessions);

    const vendors = useAppSelector(selectVendors);

    const brokers: Broker[] = useAppSelector(selectBrokers);

    const activeSession: MessengerSession = useAppSelector(selectActiveSession);

    const customer: Customer = useAppSelector(selectCustomer);

    const activeSessionWarning = (messengerSession: MessengerSession) => {
        return Modal.confirm({
            title: 'Confirmation',
            content: 'You are leaving the video chat, please confirm.',
            onOk: () => {
                //console.log("OK");
                window.dispatchEvent(new Event('disconnectFromRoom'));
                dispatch(getMessagesAsync(messengerSession));
            },
            onCancel: () => {
                //console.log("CANCEL");
            }
        });
    }

    const onSelectSession = (messengerSession: MessengerSession) => {

        if (messengerSession.isBroker) {
            const broker = brokers.find((broker: Broker) => {
                return broker.brokerId === messengerSession.vendorId;
            });
            if (broker) {
                dispatch(filterBroker(broker));
            } else {
                dispatch(filterBroker(defaultBroker));
            }
        } else {
            let vendor = vendors.find((vendor: Vendor) => {
                return vendor.vendorId === messengerSession.vendorId;
            });
            if (vendor) {
                dispatch(filterVendor(vendor));
            } else {
                dispatch(filterVendor(defaultVendor));
            }
        }
        //console.log("messengerSession:", messengerSession, " customer:", customer);
        switch (user.role) {
            case UserRole.Customer:
                let analytics: AnalyticsProps = {
                    id: messengerSession.vendorId,
                    analyticsType: AnalyticsType.Vendor
                };
                dispatch(analytic(analytics));
                break;
            case UserRole.Vendor:
            case UserRole.Broker:
                if (customer.customerId != messengerSession.customerId) {
                    const customer: Customer = {
                        customerId: messengerSession.customerId
                    };
                    dispatch(setCustomerAsync(customer));
                }
                break;
            default:
                break;
        }

        if (activeSession.sessionId != messengerSession.sessionId) {
            if (globalThis.twilioRoom != undefined) {
                activeSessionWarning(messengerSession);
            } else {
                window.dispatchEvent(new Event('disconnectFromRoom'));
                dispatch(getMessagesAsync(messengerSession));
            }
        }
    }

    const sessionFilter: SessionFilterType = useAppSelector(selectSessionFilter)

    const menu = (
        <Menu>
            <Menu.Item>
                Archive
            </Menu.Item>
        </Menu>
    );

    return (
        <React.Fragment>
            <ul className="messenger-session-list">
                {sessions.map((messengerSession: MessengerSession) => {
                    const css = clsx({
                        'unread': messengerSession.hasUnread,
                        'hidden': sessionFilter == SessionFilterType.Unread && (!messengerSession.hasUnread),
                        'active': activeSession.sessionId == messengerSession.sessionId,
                        'vendor-name-visible': user.role == UserRole.Broker,
                        'broker-name-visible': user.role == UserRole.Customer && messengerSession.brokerName,
                        'schedule-visible': true,
                    });

                    let title: string = user.role == UserRole.Customer ? messengerSession.vendorName : messengerSession.customerName;

                    return <li
                        key={messengerSession.sessionId}
                        className={css}
                        onClick={() => onSelectSession(messengerSession)}
                        ref={(element: HTMLLIElement) => {
                            if (element && messengerSession.sessionId == activeSession.sessionId && messengerSession.scrollToView) {
                                //console.log(element);
                                element.scrollIntoView(false);
                            }
                        }}
                        title={messengerSession.isOnline ? `${title} - ONLINE` : title}
                    >
                        <div className="session">
                            <span className="name">
                                {title}
                            </span>
                            <span className="vendor-name">
                                {messengerSession.vendorName}
                            </span>
                            <span className="broker-name">
                                {messengerSession.brokerName}
                            </span>
                            <span className="schedule">
                                {(messengerSession.scheduleStart && messengerSession.scheduleStart != "0001-01-01T00:00:00") &&
                                    <MeetingTime
                                        startDate={messengerSession.scheduleStart}
                                        endDate={messengerSession.scheduleEnd}
                                        showDate={true}
                                    />
                                }
                            </span>
                        </div>
                        <div className={messengerSession.isOnline ? "online" : "offline"}></div>
                    </li>;
                })}
            </ul>
        </React.Fragment>
    );
}
