import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import axios from 'axios';
import {
    selectUser,
    selectAnnouncements
} from 'store/user';
import history from 'store/history';

import {
    filterType,
    resetAllFilters
} from 'store/order';

import {
    updateWindowVisibleStatus
} from 'store/messenger';

import {
    VirtualBooth,
    VirtualBoothSection,
    VirtualBoothType,
    MenuType,
    Announcement,
    OrderFilter,
    OrderFilterType,
    MediaProps,
    MediaType,
    User,
    UserRole
} from 'model/index';

import { useAppState } from 'components/layout/AppStateProvider';
import { DefaultGuid } from '../../utility';

import useWindowDimensions from 'components/hooks/useWindowDimensions';

interface State {
    sections: VirtualBoothSection[]
}

const LobbyPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const { height, width } = useWindowDimensions();

    const announcements: Announcement[] = useAppSelector(selectAnnouncements);

    const { showMedia } = useAppState();

    const user: User = useAppSelector(selectUser);

    const [state, setState] = useState<State>({
        sections: []
    });

    React.useEffect(() => {
        document.title = `Lobby | ${globalThis.showTitle}`;

        const lobby: VirtualBooth = {
            entityId: DefaultGuid,
            virtualBoothTypeId: VirtualBoothType.Lobby
        };

        axios.post<VirtualBoothSection[]>("/booth", lobby).then(response => {
            if (response.data && response.data.length > 0) {
                setState(prevState => {
                    return {
                        ...prevState,
                        sections: response.data
                    }
                });
            } else {
                const background: VirtualBoothSection = {
                    id: 0,
                    imageH: 0,
                    imagePath: "https://tradeshoweasy.s3-us-east-2.amazonaws.com/asset/lobby.jpg",
                    imageType: "Lobby",
                    imageUrl: null,
                    imageW: 0,
                    imageX: 0,
                    imageY: 0,
                    priority: 0
                };
                setState(prevState => {
                    return {
                        ...prevState,
                        sections: [background]
                    }
                });
            }
        });
    }, []);

    const menuItemOnClick = (section: VirtualBoothSection) => {
        //console.log("section:", section);

        let filter: OrderFilter = {
            filterType: OrderFilterType.All,
            query: ""
        };

        switch (section.imageType) {
            case MenuType.HostBooth:
                history.push('/host-booth');
                break;
            case MenuType.Raffles:
            case MenuType.Link:
                if (section.imageUrl.length > 0) {
                    //local link
                    if (section.imageUrl.startsWith('/')) {
                        history.push(section.imageUrl);
                    } else {
                        window.open(section.imageUrl);
                    }
                }
                break;
            case MenuType.Order:
                dispatch(resetAllFilters());
                history.push('/order');
                break;
            case MenuType.OrderSummary:
                history.push('/order-summary');
                break;
            case MenuType.MessageCenter:
                history.push('/message-center');
                break;
            case MenuType.CheckMessages:
                dispatch(updateWindowVisibleStatus(true));
                break;
            case MenuType.MyMeetingSchedule:
                history.push('/meeting-schedule');
                break;
            case MenuType.ScheduleMeeting:
                if (user.role == UserRole.Customer) {
                    history.push('/schedule');
                } else {
                    history.push('/meeting-schedule');
                }
                break;
            case MenuType.ShowInfo:
                history.push('/show-info');
                break;
            case MenuType.Instructions:
                history.push('/instructions');
                break;
            case MenuType.Support:
                history.push('/support');
                break;
            case MenuType.Announcements:
                history.push('/announcements');
                break;
            case MenuType.Booths:
                history.push('/booths');
                break;
            case MenuType.PrimeDeals:
                filter.filterType = OrderFilterType.Prime;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.NewItems:
                filter.filterType = OrderFilterType.New;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.Videos:
                filter.filterType = OrderFilterType.Videos;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.Contracts:
                filter.filterType = OrderFilterType.Contracts;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.HostVideo:
                if (section.imageUrl.length > 0) {
                    let media: MediaProps = {
                        mediaType: MediaType.Video,
                        url: section.imageUrl,
                        title: `Welcome Video`
                    };
                    showMedia(media);
                }
                break;
            default:
                break;
        }
    };

    const announcementsList = announcements.map((announcement: Announcement, index: number) => {
        return (
            <div key={`text-${index++}`} className="announcements-item">
                {announcement.title}
            </div>
        );
    });

    const announcementList: any = () => {
        return <div className="announcements-list">
            <div className="title">Announcements</div>
            <div className="announcements-items">
                {announcementsList}
            </div>
        </div>;
    }

    const getLobby = () => {
        let lobbyBackground: VirtualBoothSection = state.sections.find(section => {
            return section.imageType == "Lobby" || section.priority == 0;
        });

        let announcements: VirtualBoothSection = state.sections.find(section => {
            return section.imageType == "Announcements";
        });
        let announcementsContent = <React.Fragment />;
        if (announcements) {
            announcementsContent = <foreignObject onClick={() => menuItemOnClick(announcements)}
                className="node"
                x={announcements.imageX}
                y={announcements.imageY}
                width={150}
                height={150}>
                {announcementList()}
            </foreignObject>;
        }

        let spots = state.sections.filter((section: VirtualBoothSection) => {
            return section.imageType != "Lobby" && section.imageType != "Announcements";
        });


        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200">
            <image href={lobbyBackground.imagePath}
                xlinkHref={lobbyBackground.imagePath}
                width="100%" />
            {spots.map((section: VirtualBoothSection, index: number) => {
                const css = section.imageType == "NoAction" ? "" : "link-button";
                return <image
                    id={`${section.id}`}
                    key={index}
                    href={section.imagePath}
                    xlinkHref={section.imagePath}
                    className={css}
                    onClick={() => menuItemOnClick(section)}
                    x={section.imageX}
                    y={section.imageY} />;
            })}

            {announcementsContent}

        </svg>;
    }

    const contentStyle = () => {
        if (height < 768) {
            return {
                height: 560
            };
        } else {
            return null;
        }
    }

    return (
        <div className="page-content">
            <div className="lobby" style={contentStyle()} >
                {state.sections.length > 0 && getLobby()}
            </div>
        </div>
    );
};

export default LobbyPage;

//{ hotLinks() }

//<foreignObject onClick={() => menuItemOnClick(MenuType.Announcements)}
//    className="node"
//    x={770}
//    y={550}
//    width={180}
//    height={150}>
//    {announcementsContent()}
//</foreignObject>