import React, { useState, useRef } from 'react';
import {
    User,
    Customer,
    CustomerContract,
    UserRole
} from 'model/index';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import axios from 'axios';
import _ from "lodash";
import { DefaultGuid } from 'utility/utility';
import {
    selectLocations,
    selectLocationGroups,
    selectCustomer,
    hideAllActiveModals
} from 'store/order';
import {
    Button,
    Input,
    Tree,
    Modal
} from 'antd';
const { TextArea } = Input;
import type { InputRef } from 'antd';

import { selectUser, showMessage } from 'store/user';

import SignatureCanvas from 'react-signature-canvas';
import clsx from 'clsx';
import * as log from 'loglevel';
import { TextAreaRef } from 'antd/es/input/TextArea';


interface Props {
    catalogId: string,
    title: string
}

export const Contract: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch();

    const user: User = useAppSelector(selectUser);

    const locations = useAppSelector(selectLocations);

    const locationGroups = useAppSelector(selectLocationGroups);

    const customer: Customer = useAppSelector(selectCustomer);

    const signaturePad = useRef(null);

    //const signee = useRef<InputRef>(null);
    //const notes = useRef<TextAreaRef>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [customerContract, setCustomerContract] = useState<CustomerContract>({
        catalogId: props.catalogId,
        customerId: customer.customerId,
        signee: '',
        customerSignature: '',
        customerSignatureIsEmpty: true,
        notes: '',
        customerLocations: []
    });

    const contractUrl = `https://www.tradeshoweasy.com/documents/${globalThis.showId}/CONTRACT-${props.catalogId}.pdf`;

    const contractDisabled = user.role != UserRole.Customer;

    React.useEffect(() => {
        setLoading(true);

        axios.post<CustomerContract>("/order/customercontract", customerContract).then(result => {
            if (result.data) {
                //Log.info(result.data);

                const currentContract: CustomerContract = result.data;

                setCustomerContract(currentContract);

                setupContract(currentContract);
            }
        });


    }, [props.catalogId]);

    const [rows, setRows] = React.useState([]);

    const [referenceKeys, setReferenceKeys] = React.useState([]);

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

    const [treeDisabled, setTreeDisabled] = useState<boolean>(false);

    //const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    //const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

    //const onExpand = expandedKeys => {
    //    console.log('onExpand', expandedKeys);
    //    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    //    // or, you can remove all expanded children keys.
    //    //setExpandedKeys(expandedKeys);
    //    //setAutoExpandParent(false);
    //};

    const onCheck = checkedKeys => {
        //console.log('onCheck', checkedKeys);
        let keys: any = [];

        checkedKeys.forEach(key => {
            //log.debug(key);
            if (!key.startsWith("group")) {
                keys.push(key);
            }
        });
        setCheckedKeys(keys);
    };

    const signatureWidth = () => {
        let contractInputs = document.getElementsByClassName('contract-inputs')[0];
        if (contractInputs) {
            //console.log(contractInputs.clientWidth);
            return contractInputs.clientWidth;
        }
        return 500;
    };

    const setupContract = (currentContract: CustomerContract) => {

        log.debug(currentContract);

        //if (notes.current) {
        //    log.debug(currentContract.notes);
        //    notes.current.resizableTextArea.textArea.value = currentContract.notes;
        //}
        //if (signee.current) {
        //    log.debug(currentContract.signee);
        //    //log.debug(signee.current);
        //    signee.current.input.value = currentContract.signee;
        //}

        if (currentContract.customerSignature.length > 0) {
            signaturePad.current.fromDataURL(currentContract.customerSignature);
        }

        let customerReference: any = [];

        let keysToExpand: any = [];

        let keysToCheck: any = [];

        let treeData: any = {
            title: "ALL",
            key: "group_all",
            children: []
        };

        let index: number = 0;

        if (locations.length > 0) {
            keysToExpand.push(treeData.key);

            ////Select keys
            //currentContract.customerLocations.forEach(location => {
            //    keysToCheck.push(location);
            //});

            //Customer has groups
            if (locationGroups.length > 0) {
                locationGroups.forEach(group => {
                    let groupData: any = {
                        title: group,
                        key: `group-${group}`,
                        children: []
                    };
                    const groupLocations = locations.filter(customer => customer.groups.includes(group));

                    groupLocations.forEach(location => {

                        let locationRow: any = {
                            key: `item${index}`,
                            title: `${location.customerName} [${location.customerAccountId}]`,
                            id: location.customerId
                        };

                        //Add to selected keys
                        if (currentContract.customerLocations.includes(location.customerId)) {
                            keysToCheck.push(locationRow.key);
                        }

                        customerReference.push(locationRow);

                        if (group == "000000") {
                            treeData.children.push(locationRow);
                        } else {
                            groupData.children.push(locationRow);
                        }
                        index++;
                    });

                    if (groupData.children.length > 0) {
                        treeData.children.push(groupData);
                        keysToExpand.push(groupData.key);
                    }

                });
            } else {
                locations.forEach(location => {
                    let locationRow: any = {
                        key: `item${index}`,
                        title: `${location.customerName} [${location.customerAccountId}]`,
                        id: location.customerId
                    };
                    customerReference.push(locationRow);

                    //Add to selected keys
                    if (currentContract.customerLocations.includes(location.customerId)) {
                        keysToCheck.push(locationRow.key);
                    }

                    treeData.children.push(locationRow);
                    keysToExpand.push(location.customerId);

                    index++;
                });
            }
        } else {

            let itemKey: string = `item${index}`;
            treeData = {
                key: itemKey,
                id: customer.customerId,
                title: `${customer.customerName} [${customer.customerAccountId}]`,
                children: []
            };

            let singleCustomer: any = {
                key: itemKey,
                title: `${customer.customerName} [${customer.customerAccountId}]`,
                id: customer.customerId
            };
            customerReference.push(singleCustomer);

            keysToCheck.push(itemKey);

            setTreeDisabled(true);
        }

        setReferenceKeys(customerReference);

        let data: any = [treeData];

        setRows(data);

        setExpandedKeys(keysToExpand);

        setCheckedKeys(keysToCheck);

        setLoading(false);
    }

    const clearSignature = () => {
        signaturePad.current.clear();
        //this.sigPad.getTrimmedCanvas()
    };

    const treeClass = clsx({
        'hidden': treeDisabled,
        'contract-stores': true
    });

    const treeTitleClass = clsx({
        'hidden': treeDisabled,
    });

    const updateContract = (newContract: CustomerContract) => {
        axios.post<CustomerContract>("/order/CustomerContractUpdate", newContract).then(result => {
            if (result.data) {

                //signaturePad.current.clear(); 

                dispatch(hideAllActiveModals(true));

                if (!newContract.customerSignatureIsEmpty && newContract.customerLocations.length > 0) {
                    dispatch(showMessage(`Contract ${props.title} submitted.`));
                }

                //Contract removed
                if (!customerContract.customerSignatureIsEmpty && newContract.customerSignatureIsEmpty) {
                    //dispatch(refreshOrderGrid());
                    dispatch(showMessage(`Contract ${props.title} removed.`));
                }
            }
        });
    }

    const submitContract = () => {
        //let signature = signaturePad.current.toDataURL();
        //console.log("Signature:", signature);
        //log.debug("Locations:", checkedKeys);
        //log.debug("Rows:", rows);
        //log.debug("ReferenceKeys:", referenceKeys);

        let customerLocations: string[] = [];

        if (checkedKeys.length > 0) {
            checkedKeys.forEach(key => {
                //log.debug(key)
                let customer = referenceKeys.find((reference) => reference.key === key);
                //log.debug(customer);
                if (!customerLocations.includes(customer.id)) {
                    customerLocations.push(customer.id);
                }
            });
        }

        //log.debug("customerLocations:", customerLocations);

        let newContract: CustomerContract = {
            catalogId: customerContract.catalogId,
            customerId: customerContract.customerId,
            signee: customerContract.signee,
            customerSignature: signaturePad.current.toDataURL(),
            customerSignatureIsEmpty: signaturePad.current.isEmpty(),
            notes: customerContract.notes,
            customerLocations: customerLocations
        };

        //log.debug("newContract:", newContract);

        //SINGLE LOCATION
        if (locations.length === 0) {

            if (newContract.customerSignatureIsEmpty &&
                newContract.signee.length === 0
            ) {
                //Clear contract
                log.debug("clear contact");

                newContract.customerLocations = [];
                updateContract(newContract);
            } else {

                if (newContract.customerSignatureIsEmpty) {
                    log.debug("Signature is empty");
                    return Modal.warn({
                        title: 'Error',
                        content: "Signature is required."
                    });

                } else if (newContract.signee.length === 0) {
                    log.debug("Authorized Signee is required");

                    return Modal.warn({
                        title: 'Error',
                        content: "Authorized Signee is required."
                    });
                } else {
                    updateContract(newContract);
                }

            }

        } else {
            if (newContract.customerSignatureIsEmpty &&
                newContract.customerLocations.length === 0 &&
                newContract.signee.length === 0
            ) {
                //Clear contract
                updateContract(newContract);
            } else {

                if (newContract.customerSignatureIsEmpty) {
                    return Modal.warn({
                        title: 'Error',
                        content: "Signature is required."
                    });
                } else if (newContract.customerLocations.length === 0) {
                    return Modal.warn({
                        title: 'Error',
                        content: "Store selection is required."
                    });
                } else if (newContract.signee.length === 0) {
                    return Modal.warn({
                        title: 'Error',
                        content: "Authorized Signee is required."
                    });
                } else {
                    updateContract(newContract);
                }
            }
        }
    };

    const contractFileCss = clsx({
        'contract-file': true,
        'w-100p': customer.customerId == DefaultGuid,
        'w-70p': customer.customerId != DefaultGuid
    });

    const contractControlsCss = clsx({
        'contract-controls': true,
        'hidden': customer.customerId == DefaultGuid,
        'w-30p': customer.customerId != DefaultGuid
    });

    const contractEnabledCss = clsx({
        'hidden': contractDisabled
    });

    const contractStores = () => {
        if (contractDisabled) {
            if (checkedKeys.length > 0 && locations.length > 0) {
                return (<ul className="">
                    {locations.filter((customer: Customer) => {
                        return checkedKeys.indexOf(customer.customerId) >= 0;
                    })
                        .map((customer: Customer) => {
                            return <li key={customer.customerId}>
                                {customer.customerName} [{customer.customerAccountId}]
                            </li>;
                        })}
                </ul>);
            } else {
                return <React.Fragment />;
            }
        } else {
            return (<Tree
                disabled={contractDisabled}
                checkable={true}
                selectable={false}
                expandedKeys={expandedKeys}
                checkedKeys={checkedKeys}
                onCheck={onCheck}
                treeData={rows}
            />)
        }
    };

    return (
        <React.Fragment>
            <div className="contract-content">
                <div className={contractFileCss}>
                    <iframe src={contractUrl} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
                <div className={contractControlsCss}>
                    {!contractDisabled &&
                        <div className="contract-submit-top">
                            <Button block type="primary"
                                className={contractEnabledCss}
                                onClick={submitContract}
                                style={{ width: signatureWidth() }}>
                                Submit
                            </Button>
                        </div>
                    }
                    <div className="contract-inputs" style={{}}>
                        <div className="contract-signature">
                            <div className="signature-title">
                                <div>
                                    <h2>Signature</h2> Use mouse or touch screen to sign.
                                </div>
                                <Button
                                    className={contractEnabledCss}
                                    onClick={clearSignature}
                                >Clear Signature</Button>
                            </div>
                            <div>
                                <SignatureCanvas
                                    ref={signaturePad}
                                    canvasProps={{
                                        width: signatureWidth(),
                                        height: 200,
                                        className: 'signature-canvas'
                                    }} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2>Authorized Signee</h2> Enter name
                            </div>
                            <div>
                                {/*<input type="text"*/}
                                {/*    className="ant-input"*/}
                                {/*    disabled={contractDisabled}*/}
                                {/*    ref={signee} />*/}
                                <Input
                                    disabled={contractDisabled}
                                    value={customerContract.signee}
                                    onChange={(e) => {
                                        setCustomerContract({
                                            ...customerContract,
                                            signee: e.target.value
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={treeTitleClass}>
                            <h2>Stores</h2> Select stores.
                        </div>
                        <div className={treeClass}>
                            {contractStores()}
                        </div>
                        <div className="contract-notes">
                            <h2>Notes & Contract Levels</h2>  Enter notes or contract levels.
                            {/*<textarea rows={4}*/}
                            {/*    className="ant-input"*/}
                            {/*    disabled={contractDisabled}*/}
                            {/*    ref={notes} ></textarea>*/}
                            <TextArea rows={4}
                                disabled={contractDisabled}
                                value={customerContract.notes}
                                onChange={(e) => {
                                    setCustomerContract({
                                        ...customerContract,
                                        notes: e.target.value
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {!contractDisabled &&
                        <div className="contract-submit-bottom">
                            <Button block type="primary"
                                className={contractEnabledCss}
                                onClick={submitContract}
                                style={{ width: signatureWidth() }}>
                                Submit
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};
