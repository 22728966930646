import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectUser
} from 'store/user';
import {
    User
} from 'model/index';
import { Redirect } from 'react-router-dom';
import LobbyPage from './Lobby';
const Lobby: React.FC = () => {
    const user: User = useAppSelector(selectUser);
    React.useEffect(() => {
        document.title = `Lobby | ${globalThis.showTitle}`;
    }, []);
    if (user.page && user.page.length > 0) {
        switch (user.page) {
            case "lobby":
                return <LobbyPage />;
            case "order":
                return <Redirect to={{ pathname: '/order' }} />;
            case "instructions":
                return <Redirect to={{ pathname: '/instructions' }} />;
            case "show-info":
                return <Redirect to={{ pathname: '/show-info' }} />;
            default:
                return <LobbyPage />;
        }
    } else {
        return (
            <LobbyPage />
        );
    }
};

export default Lobby;