import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import history from 'store/history';
import {
    filterVendor,
    filterBroker,
    selectVendors,
    selectBrokers,
    selectOrderOptions,
    selectVirtualBooths
} from 'store/order';

//import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Lazy } from 'swiper';
SwiperCore.use([Autoplay, Lazy]);

import {
    Vendor,
    Broker,
    Show,
    OrderOption,
    VirtualBooth
} from 'model';
import { Button, Spin, Empty } from 'antd';

const BoothsPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const vendors: Vendor[] = useAppSelector(selectVendors);

    const brokers: Broker[] = useAppSelector(selectBrokers);

    const virtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);


    React.useEffect(() => {
        document.title = `Booths | ${globalThis.showTitle}`;
    }, []);

    const boothOnClick = (booth: VirtualBooth) => {
        if (booth.vendorCount > 0) {
            console.log("Broker");
            const broker = brokers.find((broker: Broker) => {
                return broker.brokerId == booth.entityId;
            });
            dispatch(filterBroker(broker));
            history.push(`/booth/${booth.entityId}`);
        } else {
            //console.log("Vendor");
            const vendor = vendors.find((vendor: Vendor) => {
                return vendor.vendorId == booth.entityId;
            });
            dispatch(filterVendor(vendor));
            history.push(`/booth/${booth.entityId}`);
        }
    };

    const getVirtualBooths = () => {
        if (virtualBooths && virtualBooths.length > 0) {
            return virtualBooths.map((booth: VirtualBooth, index: number) => {
                if (booth.entityId == orderOptions.hostVendorId) {
                    return null;
                }
                return <div key={index} className="booth" onClick={() => boothOnClick(booth)}>
                    {(booth.logo && booth.logo.length > 0) &&
                        <img src={booth.logo} title={booth.name} />
                    }
                    <div className="title">
                        {booth.name}
                    </div>
                </div>
            });
        } else {
            return <div className="center-content"> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>;
        }
    }

    return (
        <div className="page-content">
            <h1>
                {orderOptions.virtualBoothTitle} Booths
            </h1>
            <div className="content-html">
                <div className="booths">
                    {getVirtualBooths()}
                </div>
            </div>
        </div>
    );
};
export default BoothsPage;
