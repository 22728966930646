import React from 'react';
import { CatalogItemDeliverySchedule, MinMaxQuantityType } from 'model/index';
interface Props {
    data: CatalogItemDeliverySchedule
}
export const QuantityWarning: React.FC<Props> = (props) => {
    if (props.data.MinimumQuantity || props.data.MaxQuantity) {
        let minQuantity = null;
        let maxQuantity = null;
        if (props.data.MaxQuantity) {
            let maxQuantityStatus = `Maximum quantity for this item is ${props.data.MaxQuantity} (total of all delivery periods), orders more than ${props.data.MaxQuantity} will not be saved.`;
            if (props.data.MaxQuantityType == MinMaxQuantityType.Weekly) {
                maxQuantityStatus = `Maximum quantity for this item is ${props.data.MaxQuantity} (per delivery period), orders more than ${props.data.MaxQuantity} will not be saved.`;
            }
            maxQuantity = <div className="min-max-warning">{maxQuantityStatus}</div>;
        }
        if (props.data.MinimumQuantity) {
            let minQuantityStatus = `Minimum quantity for this item is ${props.data.MinimumQuantity} (per delivery period), orders less than ${props.data.MinimumQuantity} will not be saved.`;
            if (props.data.MinimumQuantityType == MinMaxQuantityType.Total) {
                minQuantityStatus = `Minimum quantity for this item is ${props.data.MinimumQuantity} (total of all delivery periods), orders less than ${props.data.MinimumQuantity} will not be saved.`;
            }
            minQuantity = <div className="min-max-warning">{minQuantityStatus}</div>;
        }
        return (
            <div>
                {maxQuantity}
                {minQuantity}
            </div>
        );
    } else {
        return null;
    }
}
