import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as log from 'loglevel';
import { AppThunk, RootState } from 'store/store';
import {
    Message,
    MessengerSession,
    MessengerUserStatus,
    MessengerVideoSession,
    UserRole,
    Announcement,
    VideoStatus,
    SessionFilterType,
    ErrorStatus
} from 'model/index';
import axios from 'axios';
import _ from "lodash";

//import { Animated } from "react-animated-css";

import {
    HubConnectionState
} from '@microsoft/signalr';
import { message } from 'antd';

import { setError } from './user';


interface MessengerState {
    initialized: boolean;
    error: string;
    connectionStatus: HubConnectionState,
    messages: Message[],
    sessions: MessengerSession[],
    unreadSessionCount: number,
    activeSession: MessengerSession,
    refreshSessions: boolean,
    refreshMessages: boolean,
    windowVisible: boolean,
    windowExpanded: boolean,
    scrollToView: boolean,
    videoStatus: VideoStatus,
    videoSessionId: number,
    activeVideoSessionIds: number[],
    refreshTimer: number,
    sidebarCollapsed: boolean,
    sessionFilter: SessionFilterType
}

interface RecieveMessagePayload {
    messages: Message[],
    activeSession: MessengerSession
}

export const messengerSlice = createSlice({
    name: 'messenger',
    initialState: {
        initialized: false,
        connectionStatus: HubConnectionState.Disconnected,
        error: null,
        messages: [],
        sessions: [],
        unreadSessionCount: 0,
        activeSession: null,
        refreshSessions: false,
        refreshMessages: false,
        windowVisible: false,
        windowExpanded: false,
        scrollToView: false,
        videoStatus: VideoStatus.NotInit,
        videoSessionId: 0,
        activeVideoSessionIds: [],
        refreshTimer: 0,
        sidebarCollapsed: false,
        sessionFilter: SessionFilterType.All
    },
    reducers: {
        init: (state) => {
            state.initialized = true;
        },
        heartbeat: (state) => {
            //EMPTY ACTION TO INVOCE HUB ACTION
        },
        status: (state, action: PayloadAction<HubConnectionState>) => {
            state.connectionStatus = action.payload;
        },
        sendMessage: (state, action: PayloadAction<Message>) => {
            //EMPTY ACTION TO INVOCE HUB ACTION
        },
        recieveMessage: (state, action: PayloadAction<Message>) => {
            state.refreshTimer = new Date().getTime();
            //Active session
            if (state.activeSession == null) {
                state.refreshSessions = true;
            } else {
                if (state.activeSession.sessionId === action.payload.sessionId) {
                    //console.log("Refresh Messages");
                    if (action.payload.isDeleted) {
                        state.messages = state.messages.map((message: Message) => {
                            if (message.messageId === action.payload.messageId) {
                                message.isDeleted = true;
                            }
                            return message;
                        });
                    } else {
                        state.messages.push(action.payload);
                    }
                    state.refreshSessions = false;
                    //Messenger window is hidden, update badge count
                    if (!state.windowVisible) {
                        let foundSession = state.sessions.find(messengerSession => {
                            return messengerSession.sessionId === action.payload.sessionId;
                        });
                        if (foundSession) {
                            foundSession.hasUnread = true;
                        }
                    }
                } else {
                    //Session is not active
                    //console.log("SESSION IS NOT ACTIVE");
                    let foundSession = state.sessions.find(messengerSession => {
                        return messengerSession.sessionId === action.payload.sessionId;
                    });
                    if (foundSession) {
                        //console.log("SESSION USER EXISTS, ADD BADGE");
                        foundSession.hasUnread = true;
                        state.refreshSessions = false;
                    } else {
                        //console.log("SESSION DOES NOT EXISTS");
                        //TRIGGER SESSION LIST UPDATE
                        state.refreshSessions = true;
                    }
                }
            }
            state.unreadSessionCount = _.filter(state.sessions, function (session) {
                return session.hasUnread;
            }).length;
        },
        recieveSessions: (state, action: PayloadAction<MessengerSession[]>) => {
            //console.log("recieveSessions:", action.payload);
            state.sessions = action.payload;
            //reset refresh
            state.refreshSessions = false;
            //Set the first session as default for intial load
            if (state.activeSession == null && state.sessions.length > 0) {
                state.activeSession = state.sessions[0];
            }
            state.unreadSessionCount = _.filter(state.sessions, function (session) {
                return session.hasUnread;
            }).length;
        },
        recieveMessages: (state, action: PayloadAction<RecieveMessagePayload>) => {
            //console.log("recieveMessages:", action.payload);
            //state.messages = action.payload;
            //state.scrollToView = true;
            //state.windowVisible = true;
            let session = state.sessions.find(messengerSession => {
                return messengerSession.sessionId == action.payload.activeSession.sessionId;
            });
            if (session) {
                session.hasUnread = false;
            }
            state.messages = action.payload.messages;
            state.activeSession = action.payload.activeSession;
            state.refreshMessages = false;

            state.unreadSessionCount = _.filter(state.sessions, function (session) {
                return session.hasUnread;
            }).length;
        },
        updateUserStatus: (state, action: PayloadAction<MessengerUserStatus>) => {
            if (state.sessions.length > 0) {
                switch (action.payload.role) {
                    case UserRole.Customer:
                        _.forEach(state.sessions, function (messengerSession: MessengerSession) {
                            if (messengerSession.vendorId == action.payload.userId || messengerSession.brokerId == action.payload.userId) {
                                messengerSession.isOnline = action.payload.isOnline;
                            }
                        });
                        break;
                    case UserRole.Vendor:
                    case UserRole.Broker:
                        _.forEach(state.sessions, function (messengerSession: MessengerSession) {
                            if (messengerSession.customerId == action.payload.userId) {
                                messengerSession.isOnline = action.payload.isOnline;
                            }
                        });
                        break;
                    default:
                        break;
                }
            }
        },
        updateSession: (state, action: PayloadAction<MessengerSession>) => {
            //EMPTY ACTION TO INVOCE HUB ACTION
        },
        updateWindowVisibleStatus: (state, action: PayloadAction<boolean>) => {
            state.windowVisible = action.payload;
            //if (!action.payload) {
            //}
        },
        updateWindowExpandedStatus: (state, action: PayloadAction<boolean>) => {
            state.windowExpanded = action.payload;
        },
        createSession: (state, action: PayloadAction<MessengerSession[]>) => {
            state.sessions = action.payload;
            state.activeSession = action.payload.find(messengerSession => {
                return messengerSession.isActive;
            });
            state.refreshMessages = true;
            //state.messages = state.activeSession.messages;
            state.windowVisible = true;
            //state.scrollToView = true;
        },
        enableVideo: (state) => {
            state.videoStatus = VideoStatus.Connected;
        },
        disableVideo: (state) => {
            state.videoStatus = VideoStatus.Disconnected;
        },
        videoConnected: (state, action: PayloadAction<MessengerVideoSession>) => {
            //console.log("NOTIFY CONNECT", action.payload);

            state.videoSessionId = action.payload.sessionId;
        },
        videoDisconnected: (state, action: PayloadAction<MessengerVideoSession>) => {
            //console.log("NOTIFY DISCONNECT", action.payload);
            state.videoSessionId = action.payload.sessionId;
            state.videoStatus = VideoStatus.Disconnected;
        },
        updateVideoStatus: (state, action: PayloadAction<MessengerVideoSession>) => {
            //console.log("UPDATE VIDEO STATUS", action.payload);
            let updateValues = state.activeVideoSessionIds;
            if (action.payload.connected) {
                if (!updateValues.includes(action.payload.sessionId)) {
                    updateValues.push(action.payload.sessionId);
                }
                //state.activeVideoSessionIds.add(action.payload.sessionId);
            } else {
                updateValues = _.filter(updateValues, function (sessionId) {
                    return sessionId !== action.payload.sessionId;
                });
            }
            state.activeVideoSessionIds = updateValues;
        },
        setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
            state.sidebarCollapsed = action.payload;
        },
        setSessionFilter: (state, action: PayloadAction<SessionFilterType>) => {
            state.sessionFilter = action.payload;
        }
    },
});

export const {
    init,
    heartbeat,
    status,
    sendMessage,
    recieveMessage,
    recieveMessages,
    recieveSessions,
    updateUserStatus,
    updateSession,
    updateWindowVisibleStatus,
    updateWindowExpandedStatus,
    createSession,
    enableVideo,
    disableVideo,
    videoConnected,
    videoDisconnected,
    updateVideoStatus,
    setSidebarCollapsed,
    setSessionFilter
} = messengerSlice.actions;

export const selectSidebarCollapsed = (state: RootState) => state.messenger.sidebarCollapsed;

export const selectSessionFilter = (state: RootState) => state.messenger.sessionFilter;

export const selectMessages = (state: RootState) => state.messenger.messages;

export const selectSessions = (state: RootState) => state.messenger.sessions;

export const selectConnectionStatus = (state: RootState) => state.messenger.connectionStatus;

export const selectInitialized = (state: RootState) => state.messenger.initialized;

export const selectActiveSession = (state: RootState) => state.messenger.activeSession;

export const selectRefreshSessions = (state: RootState) => state.messenger.refreshSessions;

export const selectRefreshMessages = (state: RootState) => state.messenger.refreshMessages;

export const selectWindowVisibleStatus = (state: RootState) => state.messenger.windowVisible;

export const selectWindowExpandadStatus = (state: RootState) => state.messenger.windowExpanded;

export const selectUnreadSessionCount = (state: RootState) => state.messenger.unreadSessionCount;

export const selectScrollToView = (state: RootState) => state.messenger.scrollToView;

export const selectVideoStatus = (state: RootState) => state.messenger.videoStatus;

export const selectActiveVideoSessionIds = (state: RootState) => state.messenger.activeVideoSessionIds;

export const selectRefreshTimer = (state: RootState) => state.messenger.refreshTimer;

export const getMessagesAsync = (messengerSession: MessengerSession): AppThunk => (dispatch, getState) => {
    //dispatch(setActiveSession(messengerSession));
    axios.post<Message[]>("/messenger/messages", messengerSession).then(result => {
        if (result.data) {
            const recieveMessagePayload: RecieveMessagePayload = {
                messages: result.data,
                activeSession: messengerSession
            }
            dispatch(recieveMessages(recieveMessagePayload));
        }
    });
};
export const getSessionsAsync = (): AppThunk => (dispatch, getState) => {
    const messengerSession: MessengerSession = {
        sessionId: 0
    }
    axios.post<MessengerSession[]>("/messenger/sessions", messengerSession).then(result => {
        if (result.data) {
            //let token = jwt_decode(result.data.token);
            dispatch(recieveSessions(result.data));
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getSessionsAsync"
        };
        dispatch(setError(errorStatus));
    });
};

//let lastRun: number = 0;
//let lastRunError: number = 0;

export const createSessionAsync = (role: UserRole, messengerSession: MessengerSession): AppThunk => (dispatch, getState) => {
    //console.log("messengerSession:", messengerSession);
    //const now: number = Date.now();
    //if (role == UserRole.Customer) {
    //    lastRun = 0;
    //}
    //if (lastRun + 3000 < now) {
    //log.debug("RUN");

    //Creating session
    axios.post<MessengerSession[]>("/messenger/session", messengerSession)
        .then(result => {
            if (result.data) {
                //lastRun = Date.now();
                dispatch(createSession(result.data));
            }
        }).catch(error => {
            console.log(error)
            const errorStatus: ErrorStatus = {
                error: "createSessionAsync",
                message: "Rate limit is reached, please try again later."
            };
            dispatch(setError(errorStatus));
        });
    //} else {
    //    lastRunError++;
    //    const errorStatus: ErrorStatus = {
    //        error: "createSessionAsync",
    //        message: "Error."
    //    };
    //    dispatch(setError(errorStatus));
    //    log.error(lastRunError, errorStatus);
    //    if (lastRunError === 3) {
    //        lastRun = now + 15000;
    //        lastRunError = 0;
    //    }
    //}
};

export const updateUserStatusAsync = (messengerUserStatus: MessengerUserStatus): AppThunk => (dispatch, getState) => {
    const appState = getState();
    //console.log(appState.user.currentUser);
    messengerUserStatus.role = appState.user.currentUser.role;
    dispatch(updateUserStatus(messengerUserStatus));
};

export const getAnnouncementsAsync = (): AppThunk => (dispatch, getState) => {
    axios.post<Announcement[]>("/messenger/announcements").then(result => {
        if (result.data) {
            console.log(result.data);
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getAnnouncementsAsync"
        };
        dispatch(setError(errorStatus));
    });
};


export default messengerSlice.reducer;
