import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as log from 'loglevel';
import {
    InputNumber,
    Button
} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import {
    OrderOption,
    OrderItem,
    Customer,
    DeliverySchedule,
    CatalogOrder,
    CatalogItem,
} from 'model/index';
import {
    selectOrderOptions,
    selectReadOnly,
    selectCustomer,
    selectDeliverySchedule
} from 'store/order';
import styles from './OrderRow.module.css';
import { QuantityWarning } from './QuantityWarning';
import { DefaultGuid, checkMinMaxQuantity } from 'utility/utility';
import { useAppState } from 'components/layout/AppStateProvider';

interface Props {
    item: any,
    onDoneClick(): void,
    submitOrder(orderItems: CatalogOrder[]): void,
    orderItem: OrderItem
}

interface OrderState {
    focusedWeekNo: number,
    clear: boolean;
    duplicate: boolean;
}


export const OrderRow: React.FC<Props> = (props) => {


    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const readOnly: boolean = useAppSelector(selectReadOnly);

    const deliverySchedule: DeliverySchedule[] = useAppSelector(selectDeliverySchedule);

    const customer: Customer = useAppSelector(selectCustomer);

    const { showNotes } = useAppState();

    const [state, setState] = useState<OrderItem>(props.orderItem);

    const [orderState, setOrderState] = useState<OrderState>({
        focusedWeekNo: 0,
        clear: false,
        duplicate: false
    });

    const duplicate = () => {
        if (orderState.focusedWeekNo > 0) {
            const title: string = `W${orderState.focusedWeekNo}`;
            let quantityRow: CatalogOrder = state.quantity.length > 0 ? { ...state.quantity[0] } : { CustomerId: customer.customerId };
            if (quantityRow && quantityRow[title as keyof CatalogOrder] != undefined) {
                let value = quantityRow[title as keyof CatalogOrder];
                if (value && value > 0) {
                    deliverySchedule.map((delivery: DeliverySchedule) => {
                        const title: string = `W${delivery.weekNo.toString()}`;
                        const status: boolean = state.status[title as keyof CatalogItem];
                        const isDisabled: boolean = delivery.disabled || readOnly;
                        if (!isDisabled && status) {
                            quantityRow[title] = value;
                        }
                    });
                    submitOrderRow(quantityRow);
                }
            }
        }
    }

    const clearAll = () => {
        //console.log("clearAll");
        //console.log(state);

        let quantityRow: CatalogOrder = { ...state.quantity[0] };

        deliverySchedule.map((delivery: DeliverySchedule) => {
            const title: string = `W${delivery.weekNo.toString()}`;
            const status: boolean = state.status[title as keyof CatalogItem];
            const isDisabled: boolean = delivery.disabled || readOnly;
            if (!isDisabled) {
                quantityRow[title] = 0;
            }
        });

        submitOrderRow(quantityRow);

        setState((prevState) => {
            return {
                ...prevState,
                focusedWeekNo: 0
            };
        });
    }

    const submitOrderRow = (quantityRow: CatalogOrder) => {
        let proceed: boolean = checkMinMaxQuantity(props.orderItem.status, deliverySchedule, quantityRow);

        if (proceed) {
            props.submitOrder([quantityRow]);
            setState((prevState) => {
                return {
                    ...prevState,
                    quantity: [quantityRow]
                };
            });
        }
    }

    const inputOnChange = (value: any, weekNo: number) => {
        const title: string = `W${weekNo}`;
        let quantityRow: CatalogOrder = state.quantity.length > 0 ? { ...state.quantity[0] } : { CustomerId: customer.customerId };
        quantityRow[title] = Number.isNaN(value) ? 0 : Number(value);
        submitOrderRow(quantityRow);
    }

    const onFocus = (event: any, weekNo: number) => {
        setOrderState((prevState) => {
            return {
                ...prevState,
                focusedWeekNo: weekNo
            };
        });
    }

    const onNotesClick = () => {
        showNotes(props.orderItem.catalogId, customer.customerId);
    }

    let orderControls = (): any => {
        let buttons = null;
        if (customer.customerId != DefaultGuid) {
            if (!readOnly) {
                buttons =
                    <React.Fragment>
                        <Button type="default" onClick={duplicate}>
                            Duplicate
                        </Button>
                        <Button type="default" onClick={clearAll}>
                            Clear All
                        </Button>
                    </React.Fragment >;
            }
        } else {
            buttons = <div className={styles.info}>Delivery weeks shown. Select a customer to see order details.</div>;
        }

        let notes = null;

        if (orderOptions.notesEnabled && customer.customerId != DefaultGuid && !readOnly) {
            notes = <Button type="link" onClick={onNotesClick}><FormOutlined /> Notes</Button>;
        }

        return (
            <div className={styles.options}>
                {buttons}
                <Button type="primary" onClick={props.onDoneClick}>
                    Done
                </Button>
                {notes}
            </div>
        );
    };

    let itemFocused: boolean = false;

    const orderRows = (): any => {
        const showQuantity: boolean = state && state.quantity.length > 0;
        let quantityRow: CatalogOrder = { CustomerId: customer.customerId };
        if (showQuantity) {
            quantityRow = state.quantity[0];
        }

        return deliverySchedule.map((delivery: DeliverySchedule) => {
            if (state) {
                const title: string = `W${delivery.weekNo.toString()}`;
                const status: boolean = state.status[title as keyof CatalogItem];
                let defaultValue: number = null;
                if (showQuantity) {
                    if (quantityRow && quantityRow[title as keyof CatalogOrder] != undefined) {
                        let value = quantityRow[title as keyof CatalogOrder].toString();
                        if (value === "0") {
                            defaultValue = null;
                        } else {
                            defaultValue = Number(value);
                        }
                    }
                }
                if (status) {

                    const isDisabled: boolean = delivery.disabled || readOnly;
                    let autoFocus: boolean = false;
                    if (!isDisabled && !itemFocused) {
                        autoFocus = itemFocused = true;
                    }
                    return (
                        <div key={delivery.weekNo}
                            className={styles.quantiybox}>
                            <div className={styles.title}>{delivery.title}</div>
                            {customer.customerId != DefaultGuid &&
                                <InputNumber
                                    style={{ width: 60 }}
                                    size="large"
                                    width={60}
                                    min={0}
                                    max={9999}
                                    maxLength={4}
                                    value={defaultValue}
                                    disabled={isDisabled}
                                    autoFocus={autoFocus}
                                    onFocus={(e) => onFocus(e, delivery.weekNo)}
                                    onChange={(value) => inputOnChange(value, delivery.weekNo)}
                                />
                            }
                        </div>
                    );
                }
            }
            return null;
        });
    };

    useEffect(() => {

        setState(props.orderItem);

    }, [props.orderItem]);

    return <div key={`order-${props.item.CatalogId}`} className={styles.order}>
        {state &&
            <div className={styles.full}>
                <QuantityWarning data={state.status} />
                <div className={styles.quantiyrow}>
                    {orderRows()}
                </div>
                {orderControls()}
            </div>
        }
    </div>;
}

