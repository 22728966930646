import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store/store';
import axios from 'axios';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { User, UserSettings, LoginResponse, LoginRequest, AppConfig, UserRole, Announcement, ErrorStatus } from 'model/index';
import { getTimeZoneLabel } from 'utility/utility';
import { notification, message } from 'antd';
import * as log from 'loglevel';

interface UserState {
    currentUser: User;
    authenticated: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    redirect: boolean;
    error: ErrorStatus;
    settings: UserSettings;
    announcementNotification: number;
    announcements: Announcement[];
}

axios.defaults.baseURL = globalThis.app.baseUrl;

const UNAUTHORIZED = 401;

const isDev = process.env.NODE_ENV === 'development';

axios.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
            window.location.href = isDev ? "/login" : "https://www.tradeshoweasy.com/";
        }
        return Promise.reject(error);
    }
);

interface AppJwtPayload extends JwtPayload {
    role: UserRole,
    accountNo: string,
    company: string,
    unique_name: string,
    showId: number,
    page: string
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        authenticated: false,
        isLoaded: false,
        isLoading: false,
        redirect: false,
        error: null,
        settings: {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneLabel: ''
        },
        announcementNotification: 0,
        announcements: []
    },
    reducers: {
        authenticate: (state, action: PayloadAction<User>) => {

            let timeZone = localStorage.getItem("timeZone");
            if (timeZone) {
                state.settings.timeZone = timeZone;
            } else {
                state.settings.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }
            globalThis.userTimeZone = state.settings.timeZone;
            state.settings.timeZoneLabel = getTimeZoneLabel(state.settings.timeZone);

            state.currentUser = action.payload;
            state.authenticated = true;
            state.redirect = true;
        },
        logout: (state) => {
            //console.log("logout");
            axios.defaults.headers.common.authorization = null;
            localStorage.removeItem(globalThis.app.tokenName);
            state.currentUser = null;
            //window.location.href = "https://www.tradeshoweasy.com/";
            window.location.href = isDev ? "/login" : "https://www.tradeshoweasy.com/";
        },
        getUser: (state) => {
            //console.log("GET USER");
            let token = localStorage.getItem(globalThis.app.tokenName);
            if (token) {
                let userToken = jwtDecode<AppJwtPayload>(localStorage.getItem(globalThis.app.tokenName));
                //console.log(userToken);
                if (userToken) {
                    let currentTime = new Date().getTime() / 1000;
                    if (currentTime > userToken.exp) {
                        //TOKEN EXPIRED
                        localStorage.removeItem(globalThis.app.tokenName);
                    } else {
                        let user: User = {
                            page: userToken.page,
                            role: userToken.role,
                            accountNo: userToken.accountNo,
                            company: userToken.company,
                            id: userToken.unique_name,
                            showId: Number(userToken.showId),
                            exp: userToken.exp
                        };
                        state.currentUser = user;
                        state.authenticated = true;
                        //AXIOS DEFAULTS
                        axios.defaults.headers.common.authorization = `Bearer ${token}`;

                        let timeZone = localStorage.getItem("timeZone");
                        if (timeZone) {
                            state.settings.timeZone = timeZone;
                        } else {
                            state.settings.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        }
                        state.settings.timeZoneLabel = getTimeZoneLabel(state.settings.timeZone);
                        globalThis.userTimeZone = state.settings.timeZone;
                    }
                }
            }
        },
        updateSettings: (state, action: PayloadAction<UserSettings>) => {
            state.settings = action.payload;
            localStorage.setItem("timeZone", action.payload.timeZone);
            globalThis.userTimeZone = state.settings.timeZone;
            state.settings.timeZoneLabel = getTimeZoneLabel(state.settings.timeZone);
        },
        showNotification: (state, action: PayloadAction<string>) => {
            //console.log("SHOW MESSAGE", action.payload);
            //const key = `open${Date.now()}`;
            //const desciption = <div>{action.payload}</div>;
            notification.info({
                duration: 0,
                message: 'Notification',
                description: action.payload
            });
            //linkifyStr(action.payload),
        },
        setAnnouncements: (state, action: PayloadAction<Announcement[]>) => {
            state.announcements = action.payload;
            //console.log("announcements", action.payload);
        },
        setAnnouncementsWithNotificaiton: (state, action: PayloadAction<Announcement[]>) => {
            state.announcements = action.payload;
            state.announcementNotification = new Date().getTime();
            //console.log("announcements and notify", action.payload, state.announcementNotification);
        },
        setRedirect: (state) => {
            state.redirect = false;
        },
        showMessage: (state, action: PayloadAction<string>) => {
            message.success(action.payload);
        },
        setError: (state, action: PayloadAction<ErrorStatus>) => {
            state.error = action.payload;

            let errorMessage: string = "Something went wrong.";
            //log.debug(action.payload.message);

            if (action.payload && action.payload.message) {
                errorMessage = action.payload.message;
            }

            message.error(errorMessage);

        },
        reloadApp: (state) => {

            notification.info({
                duration: 0,
                message: 'Notification',
                description: "Application will restart in 3 seconds to install a new version."
            });

            setTimeout(() => {
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) {
                            caches.delete(name);
                        }
                    });
                }
                // delete browser cache and hard reload
                window.location.reload();
            }, 3000)
        }
    }
});

export const {
    setError,
    showMessage,
    getUser,
    authenticate,
    logout,
    updateSettings,
    showNotification,
    setAnnouncements,
    setAnnouncementsWithNotificaiton,
    setRedirect,
    reloadApp
} = userSlice.actions;

export const selectRedirect = (state: RootState) => state.user.redirect;

export const selectUser = (state: RootState) => state.user.currentUser;

export const selectUserAuthenticated = (state: RootState) => state.user.authenticated;

export const selectError = (state: RootState) => state.user.error;

export const selectSettings = (state: RootState) => state.user.settings;

export const selectAnnouncements = (state: RootState) => state.user.announcements;

export const selectAnnouncementNotification = (state: RootState) => state.user.announcementNotification;

export const loginAsync = (loginRequest: LoginRequest): AppThunk => (dispatch, getState) => {
    axios.post<LoginResponse>("/auth/login", loginRequest).then(result => {
        //iss: The issuer of the token
        //sub: The subject of the token
        //aud: The audience of the token
        //exp: This will probably be the registered claim most often used.This will define the expiration in NumericDate value.The expiration MUST be after the current date / time.
        //nbf: Defines the time before which the JWT MUST NOT be accepted for processing
        //iat: The time the JWT was issued.Can be used to determine the age of the JWT
        //jti: Unique identifier for the JWT.Can be used to prevent the JWT from being replayed.This is helpful for a one time use token.
        if (result.data) {
            if (result.data.token != null) {
                let userToken = jwtDecode<AppJwtPayload>(result.data.token);
                let currentTime = new Date().getTime() / 1000;
                if (currentTime > userToken.exp) {
                    const errorStatus: ErrorStatus = {
                        error: "Login",
                        message: "Login expired."
                    };
                    dispatch(setError(errorStatus));
                } else {
                    localStorage.setItem(globalThis.app.tokenName, result.data.token);
                    //AXIOS DEFAULTS
                    axios.defaults.headers.common.authorization = `Bearer ${result.data.token}`;
                    let user: User = {
                        page: userToken.page,
                        role: userToken.role,
                        accountNo: userToken.accountNo,
                        company: userToken.company,
                        id: userToken.unique_name,
                        showId: Number(userToken.showId),
                        exp: userToken.exp
                    };
                    dispatch(authenticate(user));
                }
            } else {
                if (result.data.message) {
                    const errorStatus: ErrorStatus = {
                        error: "getAnnouncementsAsync",
                        message: result.data.message
                    };
                    dispatch(setError(errorStatus));
                }
            }
        } else {
            const errorStatus: ErrorStatus = {
                error: "Login",
                message: "Login error."
            };
            dispatch(setError(errorStatus));
        }
    });
};

export const getAnnouncementsAsync = (showNotification: boolean): AppThunk => (dispatch, getState) => {

    axios.post<Announcement[]>("/messenger/announcements").then(response => {
        //console.log("announcements:", response.data, showNotification);
        if (showNotification) {
            dispatch(setAnnouncementsWithNotificaiton(response.data));
        } else {
            dispatch(setAnnouncements(response.data));
        }
    }).catch(error => {
        //console.log(error.toJSON());
        const errorStatus: ErrorStatus = {
            error: "getAnnouncementsAsync",
            message: "Error"
        };
        dispatch(setError(errorStatus));
    });
};


export default userSlice.reducer;


