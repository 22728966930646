import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import clsx from 'clsx';
import styles from './Messenger.module.css';
import {
    updateWindowVisibleStatus,
    selectWindowVisibleStatus,
} from 'store/messenger';
import SessionCount from './SessionCount';
import { MessengerWindow } from './MessengerWindow';
import { MessengerSession } from 'model/index';
import { HubConnectionState } from '@microsoft/signalr';
import { MessageTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import {
    selectActiveSession,
    selectConnectionStatus,
    getSessionsAsync,
    selectUnreadSessionCount,
    selectRefreshSessions,
    selectRefreshTimer
} from 'store/messenger';

import useSound from 'use-sound';
const incomingMessageSound = require('asset/sounds/notification.mp3');

export const Messenger: React.FC = () => {

    const dispatch = useAppDispatch();

    const activeSession: MessengerSession = useAppSelector(selectActiveSession);

    const connectionStatus = useAppSelector(selectConnectionStatus);

    const unreadSessionCount = useAppSelector(selectUnreadSessionCount);

    const windowVisible = useAppSelector(selectWindowVisibleStatus);

    const refreshTimer = useAppSelector(selectRefreshTimer);

    const refreshSessions = useAppSelector(selectRefreshSessions);

    const [play] = useSound(incomingMessageSound);

    React.useEffect(() => {
        if (connectionStatus === HubConnectionState.Connected) {
            if (activeSession == null) {
                dispatch(getSessionsAsync());
            }
        }
        return function cleanup() {
        };

    }, [connectionStatus]);

    React.useEffect(() => {
        //console.log("refreshSessions:", refreshTimer, " unreadSessionCount:", unreadSessionCount);
        if (refreshTimer > 0 && unreadSessionCount > 0) {
            play();
        }
    }, [refreshTimer, unreadSessionCount]);

    React.useEffect(() => {
        //Incoming message session does not exist, triger session list update
        if (refreshSessions) {
            dispatch(getSessionsAsync());
        }
    }, [refreshTimer, refreshSessions]);

    const toggleWindow = () => {
        dispatch(updateWindowVisibleStatus(!windowVisible));
    }

    const toggleCss = clsx({
        [styles.toggle]: true,
        [styles.opened]: windowVisible
    });

    const windowCss = clsx({
        "hidden": !windowVisible,
    });

    if (connectionStatus === HubConnectionState.Connected) {
        return (
            <React.Fragment>
                <div className={toggleCss} title="Messages"
                    onClick={toggleWindow}>
                    <SessionCount />
                    <CloseCircleTwoTone className={styles.open} />
                    <MessageTwoTone className={styles.close} />
                </div>
                <div className={windowCss}>
                    <MessengerWindow />
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment />
        );
    }
}
