import { createSlice } from '@reduxjs/toolkit';
import { MeetingScheduleDay, Vendor, ErrorStatus } from 'model/index';
import axios from 'axios';
import _ from "lodash";

import { AppThunk, RootState } from 'store/store';

import { setError } from './user';

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState: {
        initialized: false,
        mySchedule: [],
        availableSpots: []
    },
    reducers: {
        init: (state) => {
            state.initialized = true;
        }
    }
});

export const {
    init,

} = meetingSlice.actions;

export const getScheduleAsync = (vendor: Vendor): AppThunk => (dispatch, getState) => {
    //dispatch(setActiveSession(messengerSession));
    //axios.post<Message[]>("/messenger/messages", messengerSession).then(result => {
    //    if (result.data) {
    //        const recieveMessagePayload: RecieveMessagePayload = {
    //            messages: result.data,
    //            activeSession: messengerSession
    //        }
    //        dispatch(recieveMessages(recieveMessagePayload));
    //    }
    //});
    axios.post<MeetingScheduleDay[]>("/meeting/availabilty", vendor).then(response => {
        if (response.data.length > 0) {
            //console.log(response.data);
            //setAvailableSpots(response.data);
            //setLoading(false);
        }
    }).catch(error => {
        const errorStatus: ErrorStatus = {
            error: "getScheduleAsync"
        };
        dispatch(setError(errorStatus));
    });
};


export default meetingSlice.reducer;
