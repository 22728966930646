import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    selectSettings
} from 'store/user';
import {
    UserSettings
} from 'model/index';
import { format as formatDate, parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

interface Props {
    startDate: string,
    endDate: string,
    showDate: boolean
}

export const MeetingTime: React.FC<Props> = (props) => {
    const userSettings: UserSettings = useAppSelector(selectSettings);
    //React.useEffect(() => {
    //    console.log(globalThis.userTimeZone);
    //}, [globalThis.userTimeZone]);

    const serverStartDateTime = zonedTimeToUtc(parseISO(props.startDate), globalThis.timeZone);
    const userStartDateTime = utcToZonedTime(serverStartDateTime, globalThis.userTimeZone);
    const serverEndDateTime = zonedTimeToUtc(parseISO(props.endDate), globalThis.timeZone);
    const userEndDateTime = utcToZonedTime(serverEndDateTime, globalThis.userTimeZone);
    const dateLabel = props.showDate ? `${formatDate(userStartDateTime, 'M/dd/yyyy')}` : "";
    const timeLabel = `${formatDate(userStartDateTime, 'h:mm a')} - ${formatDate(userEndDateTime, 'h:mm a')}`;
    return (
        <React.Fragment>
            {dateLabel} {timeLabel}
        </React.Fragment>
    );
};
