import React, { useState } from 'react';
import {
    Company,
    User,
    EmailAttachement,
    UserRole,
    AppEmail, ErrorStatus
} from 'model/index';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import _ from "lodash";

import {
    Form,
    Button,
    Upload,
    Input,
    Layout,
    Progress,
} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { RcFile, UploadProps } from 'antd/lib/upload';

import { selectUser, setError, showMessage } from 'store/user';
import { DefaultGuid } from 'utility/utility';

const { Header, Content, Sider, Footer } = Layout;
import * as log from 'loglevel';

interface Props {
    sender: Company,
    recipient: Company,
    onCancel: any
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};
const fullLayout = {
    wrapperCol: { span: 24 },
};
const normFile = e => {
    //console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export const Email: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch();

    const user: User = useAppSelector(selectUser);

    const [form] = Form.useForm();

    React.useEffect(() => {
        form.resetFields();
    });

    const onFinish = (values) => {

        let email: AppEmail = {
            senderId: props.sender.id,
            recipientId: props.recipient.id,
            message: values.message,
            attachments: []
        };

        ////console.log("email:", email);

        if (Array.isArray(values.attachments) && values.attachments.length > 0) {
            _.forEach(values.attachments, function (attachment: any) {
                //console.log('attachment:', attachment.originFileObj.name, ' url:', attachment.response.url);
                let file: EmailAttachement = {
                    fileName: attachment.originFileObj.name,
                    fileUrl: attachment.response.url
                };
                email.attachments.push(file);
            });
        }
        //Sending email
        axios.post<boolean>("/order/email", email).then(response => {
            dispatch(showMessage("Email sent."));
        }).catch(error => {
            log.debug(error);
            const errorStatus: ErrorStatus = {
                error: "Email",
                message: "Rate limit is reached, please try again later."
            };
            dispatch(setError(errorStatus));
        });

        props.onCancel();
    };

    const onFinishFailed = errorInfo => {
        //console.log('Failed:', errorInfo);
    };

    //const uploadUrl: string = "https://media.tradeshoweasy.com/api/document/upload";

    const uploadUrl: string = "/order/document";
    const uploadProps: any = {
        action: uploadUrl,
        multiple: false,
        beforeUpload: (file: RcFile, FileList: RcFile[]) => {
            return true;
        },
        //onError(err) {
        //    //console.log('onError', err);
        //},
        customRequest({
            action,
            data,
            file,
            filename,
            onError,
            onProgress,
            onSuccess
        }) {
            const formData = new FormData();
            if (data) {
                Object.keys(data).forEach(key => {
                    formData.append(key, data[key]);
                });
            }
            formData.append(filename, file);

            const config: AxiosRequestConfig = {
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    const percent = Number(Math.round((progressEvent.loaded / progressEvent.total) * 100).toFixed(2));
                    onProgress({ percent: percent });
                },
                headers: undefined
            }

            axios.post(action, formData, config).then(({ data: response }) => {
                onSuccess(response);
            }).catch(onError);
            return {
                abort() {
                    //console.log('upload progress is aborted.');
                },
            };
        },
    };

    return (
        <div>
            <div className="modal-content">
                <Form
                    {...layout}
                    form={form}
                    name="emailWindow"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    {user.role == UserRole.Customer &&
                        <Form.Item {...fullLayout}>
                            <h3 className="ant-form-text">
                                Please enter your message to the vendor regarding their DEALS only. Ordering question or issues should be directed to your Wholesaler sales person.
                            </h3>
                        </Form.Item>
                    }
                    <Form.Item label="Recipient">
                        <span className="ant-form-text">
                            {props.recipient.name}
                        </span>
                    </Form.Item>
                    <Form.Item
                        label="Message"
                        name="message"
                        rules={[{ required: true, message: 'Please enter a message' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Attachment"
                        name="attachments"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload  {...uploadProps} >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button onClick={props.onCancel} style={{ margin: '0 8px' }} >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};
