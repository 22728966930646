import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import clsx from 'clsx';
import styles from './Item.module.css';
import { Customer } from 'model/index';
import { Communication } from 'components/customer/Communication';
import * as log from 'loglevel';

interface Props {
    customer: Customer,
    index: number
}

export const CustomerItem: React.FC<Props> = (props) => {
    const css = clsx({
        [styles.item]: true,
        [styles.even]: props.index % 2 === 0
    });
    const address = () => {
        return <span> {props.customer.city} {props.customer.state} {props.customer.zip ? ` , ${props.customer.zip}` : ''}</span>
    };
    return (
        <div className={css}>
            <div className={styles.meta} >
                <div className={styles.title}>
                    <div className={styles.customer}>
                        <div className={styles.customername}>
                            <i className={props.customer.locationCount > 0 ? styles.bold : ''}> {props.customer.customerName}</i>  <span className={styles.accountno}> {props.customer.customerAccountId}  </span>
                        </div>
                        <div className={styles.locations}>
                            {props.customer.locationCount > 0 &&
                                <span>{props.customer.locationCount} stores</span>
                            }
                            {props.customer.salesRep &&
                                <span className={styles.salesrep}>Sales Rep: {props.customer.salesRep}</span>
                            }
                            {address()}
                        </div>
                    </div>

                    {props.customer.chainName &&
                        <div className={styles.chain}>{props.customer.chainName}</div>
                    }
                </div>
                <Communication customer={props.customer} />
            </div>
        </div>
    );
};

