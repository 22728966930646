import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import axios from 'axios';
import { Layout } from 'antd';
import { Vendor, MessengerSession } from 'model';
const { Content, Sider } = Layout;
import { MySchedule } from 'components/meetingschedule/MySchedule';
import { AvailableSpots } from './AvailableSpots';
import { AvailableVendors } from './AvailableVendors';
import {
    selectVendors,
    filterVendor,
    selectVendor,
    selectBooth
} from 'store/order';
import {
    getSessionsAsync
} from 'store/messenger';
import { setError, showMessage } from 'store/user';

import {
    MeetingScheduleSpot,
    MeetingScheduleDay,
    ReserveScheduleSpot,
    ErrorStatus,
    VirtualBooth
} from 'model/index';
import { DefaultGuid } from 'utility/utility';

import { TimeZone } from 'components/common/TimeZone';

export const Schedule: React.FC = () => {
    const dispatch = useAppDispatch();

    const selectedBooth: VirtualBooth = useAppSelector(selectBooth);

    const vendors = useAppSelector(selectVendors);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [availableSpots, setAvailableSpots] = React.useState<MeetingScheduleDay[]>([]);

    //React.useEffect(() => {
    //    //if (selectedVendor.vendorId == DefaultGuid) {
    //    //    const vendor: Vendor = vendors.find((vendor: Vendor) => {
    //    //        return vendor.primeVendor;
    //    //    });
    //    //    //console.log(vendor);
    //    //    if (vendor) {
    //    //        dispatch(filterVendor(vendor));
    //    //    }
    //    //}
    //}, []);

    React.useEffect(() => {
        getSchedule();
    }, [selectedBooth]);

    const getSchedule = () => {
        setLoading(true);
        //if (selectedVendor == null) {
        //    const vendor = vendors.find((vendor: Vendor) => {
        //        return vendor.primeVendor;
        //    });
        //    dispatch(filterVendor(vendor));
        //}
        //if (selectedVendor.vendorId != DefaultGuid) {
        //}
        if (selectedBooth.entityId != DefaultGuid) {
            const company: any = {
                id: selectedBooth.entityId
            };
            axios.post<MeetingScheduleDay[]>("/meeting/availabilty", company).then(response => {
                if (response.data.length > 0) {
                    //console.log(response.data);
                    setAvailableSpots(response.data);
                    setLoading(false);
                } else {
                    setAvailableSpots([]);
                    setLoading(false);
                }
            });
        }
    };

    const updateSchedule = (meetingScheduleSpot: MeetingScheduleSpot) => {
        setLoading(true);

        let url = "/meeting/reserve";
        if (meetingScheduleSpot.isSelected) {
            url = "/meeting/remove";
        }

        //console.log("meetingScheduleSpot:", meetingScheduleSpot);

        let reserveScheduleSpot: ReserveScheduleSpot = {
            vendorId: selectedBooth.entityId,
            scheduleStart: meetingScheduleSpot.scheduleStart
        };

        //console.log("reserveScheduleSpot:", reserveScheduleSpot);

        axios.post<boolean>(url, reserveScheduleSpot).then(response => {
            if (response.data) {
                dispatch(showMessage(`Schedule is updated.`));
            } else {
                const errorStatus: ErrorStatus = {
                    error: "Schedule",
                    message: `Sorry, that time slot is no longer available. Please select another spot.`
                };
                dispatch(setError(errorStatus));
            }
            dispatch(getSessionsAsync());
            getSchedule();
        }).catch(error => {
            setLoading(false);
            const errorStatus: ErrorStatus = {
                error: "Schedule",
                message: `Sorry, that time slot is no longer available. Please select another spot.`
            };
            dispatch(setError(errorStatus));
        });
    };

    const removeSchedule = (session: MessengerSession) => {
        //console.log("session:", session);
        axios.post<boolean>("/meeting/remove-session", session).then(response => {
            if (response.data) {

                dispatch(showMessage(`Schedule is updated.`));

                dispatch(getSessionsAsync());

                getSchedule();
            }
        });
    };

    return (
        <div className="meetings-page">
            <Layout className="full-height" >
                <Sider width={280} style={{ overflow: 'auto' }} theme="light">
                    <div className="my-schedule-title" style={{ width: 253 }}>
                        <h2>
                            My Schedule
                        </h2>
                        <div className="center-content">
                            <TimeZone />
                        </div>
                    </div>
                    <div style={{ padding: 10, marginTop: 60 }}>
                        <MySchedule
                            removeSchedule={removeSchedule}
                            showRemoveButton={true} />
                    </div>
                </Sider>
                <Content>
                    <Layout className="full-height">
                        <Sider width={200} style={{ overflow: 'auto' }} theme="light">
                            <h2 className="fixed-title" style={{ width: 183 }}>
                                Vendors
                            </h2>
                            <div className="available-vendors">
                                <AvailableVendors />
                            </div>
                        </Sider>
                        <Content style={{ overflow: 'auto' }}>
                            <AvailableSpots
                                loadding={loading}
                                availableSpots={availableSpots}
                                updateSchedule={updateSchedule}
                            />
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        </div>
    );
};

