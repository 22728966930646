import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MessengerSession, OrderOption, VideoStatus } from 'model/index';
import {
    selectActiveSession,
    disableVideo,
    selectVideoStatus,
} from 'store/messenger';
import {
    selectOptions
} from 'store/order';
import useMounted from 'components/hooks/useMounted';

import { parseISO, isWithinInterval, isAfter, differenceInMinutes, differenceInSeconds } from 'date-fns';

export const MeetingStatus: React.FC = () => {

    const dispatch = useAppDispatch();

    const activeSession: MessengerSession = useAppSelector(selectActiveSession);

    const orderOptions: OrderOption = useAppSelector(selectOptions);

    const videoStatus: VideoStatus = useAppSelector(selectVideoStatus);

    const isMounted = useMounted();

    const currentMeetingStatus = () => {
        let style = {
            color: "green",
        };
        let status = "";
        if (videoStatus == VideoStatus.Connected && activeSession.scheduleStart && activeSession.scheduleEnd && activeSession.scheduleStart != "0001-01-01T00:00:00" && activeSession.scheduleEnd != "0001-01-01T00:00:00") {
            let now = new Date();
            let startDate = parseISO(activeSession.scheduleStart);//new Date(2014, 6, 2, 12, 20, 0)
            let endDate = parseISO(activeSession.scheduleEnd);//new Date(2014, 6, 2, 12, 7, 59)
            //let startDate = new Date(2020, 11, 22, 16, 50, 0);
            //let endDate = new Date(2020, 11, 22, 17, 1, 0);
            //console.log("Now:", now, " Start:", startDate, " End:", endDate);
            let isBetween: boolean = isWithinInterval(
                now,
                {
                    start: startDate,
                    end: endDate
                }
            );
            status = "Meeting in progress.";
            if (isBetween) {
                let timeLeftInSeconds = differenceInSeconds(
                    endDate,
                    now
                );
                let timeLeftInMinutes = differenceInMinutes(
                    endDate,
                    now
                );
                //console.log("Minutes:", timeLeftInMinutes, " Seconds:", timeLeftInSeconds);

                if (timeLeftInSeconds > 120) {
                    status = `${timeLeftInMinutes} minutes left.`;
                } else {
                    style.color = "red";
                    status = `Meeting is ending in ${timeLeftInSeconds} seconds.`;
                }
                if (timeLeftInSeconds <= 2) {
                    if (orderOptions.enforceMeetingSchedule) {
                        setTimeout(() => {
                            dispatch(disableVideo());
                        }, 1000);
                    }
                    status = "Scheduled meeting is ended.";
                }
            } else {
                //console.log("NOTE in range");
            }
            if (isAfter(now, endDate)) {
                style.color = "red";
                status = "Scheduled meeting is ended.";
            }
        }
        return <div className="time-left" style={style}>{status}</div>;
    };

    const [meetingStatus, setMeetingStatus] = useState(null);

    React.useEffect(() => {
        //console.log("MeetingStatus RENDER");
        let timer = null;
        if (isMounted.current && videoStatus == VideoStatus.Connected) {
            timer = setTimeout(() => {
                setMeetingStatus(currentMeetingStatus());
            }, 1000);
        }
        return () => {
            clearTimeout(timer);
        };
    });
    return <React.Fragment>{meetingStatus}</React.Fragment>;

}
