import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import history from 'store/history';
import {
    selectOrderOptions,
    selectVendors,
    selectBrokers,
    filterVendor,
    filterBroker,
    selectCustomer,
    selectVirtualBooths,
    filterBooth,
    filterTypeOnly
} from 'store/order';
import {
    Vendor,
    Broker,
    Company,
    Show,
    OrderOption,
    VirtualBooth,
    VirtualBoothSection,
    MenuType,
    OrderFilter,
    OrderFilterType,
    UserRole,
    User,
    MediaProps,
    MediaType,
    MessengerSession,
    Customer
} from 'model';
import axios from 'axios';
import {
    createSessionAsync,
    updateWindowVisibleStatus
} from 'store/messenger';

import {
    filterType
} from 'store/order';
import { useAppState } from 'components/layout/AppStateProvider';

import {
    selectUser
} from 'store/user';
import { Button, Spin, Empty } from 'antd';
import { DefaultGuid } from '../../utility';

const HostBoothPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const { showMedia, email } = useAppState();

    const user: User = useAppSelector(selectUser);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const vendors: Vendor[] = useAppSelector(selectVendors);

    const brokers: Broker[] = useAppSelector(selectBrokers);

    const customer: Customer = useAppSelector(selectCustomer);

    const virtualBooths: VirtualBooth[] = useAppSelector(selectVirtualBooths);

    const [hotSpots, setHotSpots] = React.useState<VirtualBoothSection[]>([]);

    React.useEffect(() => {
        document.title = `Host Booth | ${globalThis.showTitle}`;
        getBoothData();
    }, []);

    const getBoothData = () => {

        const booth: VirtualBooth = {
            entityId: DefaultGuid,
            virtualBoothTypeId: 2
        };

        axios.post<VirtualBoothSection[]>("/booth", booth).then(response => {
            if (response.data && response.data.length > 0) {
                setHotSpots(response.data);
            } else {
                const background: VirtualBoothSection = {
                    id: 0,
                    imageH: 0,
                    imagePath: "https://tradeshoweasy.s3.us-east-2.amazonaws.com/asset/booth/booth-background.jpg",
                    imageType: "Background",
                    imageUrl: null,
                    imageW: 0,
                    imageX: 0,
                    imageY: 0,
                    priority: 0
                };
                setHotSpots([background]);
            }
        });
    };


    const hotLinks: any = () => {
        return hotSpots.map((spot: VirtualBoothSection, index: number) => {
            if (spot.imagePath && spot.imagePath.length > 0) {
                if (spot.imageType == "HostBooth") {
                    return <image
                        id={`${spot.id}`}
                        key={`button-${index++}`}
                        width="100%"
                        href={spot.imagePath}
                        xlinkHref={spot.imagePath}
                    />;
                } else {
                    return <image
                        id={`${spot.id}`}
                        className="link-button"
                        key={`button-${index++}`}
                        x={spot.imageX}
                        y={spot.imageY}
                        onClick={() => menuItemOnClick(spot)}
                        href={spot.imagePath}
                        xlinkHref={spot.imagePath}
                    />;
                }
            } else {
                return null;
            }
        });
    };

    const menuItemOnClick = (section: VirtualBoothSection) => {
        //console.log("section:", section);

        if (orderOptions.hostVendorId != DefaultGuid) {
            const vendor = vendors.find((vendor: Vendor) => {
                return vendor.vendorId === orderOptions.hostVendorId;
            });
            if (vendor) {
                dispatch(filterVendor(vendor));
            } else {
                const broker = brokers.find((broker: Broker) => {
                    return broker.brokerId === orderOptions.hostVendorId;
                });
                if (broker) {
                    dispatch(filterBroker(broker));
                }
            }
        }

        let filter: OrderFilter = {
            filterType: OrderFilterType.All,
            query: ""
        };

        switch (section.imageType) {
            case MenuType.HostBooth:
                history.push('/host-booth');
                break;
            case MenuType.Raffles:
            case MenuType.Link:
                if (section.imageUrl.length > 0) {
                    //local link
                    if (section.imageUrl.startsWith('/')) {
                        history.push(section.imageUrl);
                    } else {
                        window.open(section.imageUrl);
                    }
                }
                break;
            case MenuType.Order:
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.HostOrder:
                dispatch(filterTypeOnly(filter));
                history.push('/order');
                break;
            case MenuType.OrderSummary:
                history.push('/order-summary');
                break;
            case MenuType.MessageCenter:
                history.push('/message-center');
                break;
            case MenuType.MyMeetingSchedule:
                history.push('/meeting-schedule');
                break;
            case MenuType.CheckMessages:
                if (orderOptions.hostVendorId != DefaultGuid) {
                    if (user.role == UserRole.Customer) {
                        let messengerSession: MessengerSession = {
                            customerId: customer.customerId,
                            vendorId: orderOptions.hostVendorId
                        };
                        dispatch(createSessionAsync(user.role, messengerSession));
                    } else {
                        dispatch(updateWindowVisibleStatus(true));
                    }
                } else {
                    dispatch(updateWindowVisibleStatus(true));
                }
                break;
            case MenuType.ScheduleMeeting:
                if (user.role == UserRole.Customer) {
                    if (orderOptions.hostVendorId != DefaultGuid) {
                        const booth: VirtualBooth = virtualBooths.find((booth: VirtualBooth) => {
                            return booth.entityId == orderOptions.hostVendorId;
                        });

                        if (booth) {
                            dispatch(filterBooth(booth));
                        }
                    }

                    history.push('/schedule');
                } else {
                    history.push('/meeting-schedule');
                }
                break;
            case MenuType.ShowInfo:
                history.push('/show-info');
                break;
            case MenuType.Instructions:
                history.push('/instructions');
                break;
            case MenuType.Support:
                history.push('/support');
                break;
            case MenuType.Announcements:
                history.push('/announcements');
                break;
            case MenuType.Booths:
                history.push('/booths');
                break;
            case MenuType.PrimeDeals:
                filter.filterType = OrderFilterType.Prime;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.NewItems:
                filter.filterType = OrderFilterType.New;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.Videos:
                filter.filterType = OrderFilterType.Videos;
                dispatch(filterType(filter));
                history.push('/order');
                break;
            case MenuType.Contracts:
                filter.filterType = OrderFilterType.Contracts;
                dispatch(filterType(filter));
                history.push('/order');
                break;

            case MenuType.Email:

                dispatch(filterType(filter));

                const sender: Company = {
                    id: user.id,
                    name: user.company
                };
                let recipient: Company = {};

                if (orderOptions.hostVendorId != DefaultGuid) {
                    recipient = {
                        id: orderOptions.hostVendorId,
                        name: "Host"
                    }
                    email(sender, recipient);
                }

                break;
            case MenuType.HostVideo:
                if (section.imageUrl.length > 0) {
                    let media: MediaProps = {
                        mediaType: MediaType.Video,
                        url: section.imageUrl,
                        title: `Welcome Video`
                    };
                    showMedia(media);
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className="page-content">
            <h1>
                {globalThis.showTitle}
            </h1>
            <div className="booth-page">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 733">
                    {hotLinks()}
                </svg>
            </div>
        </div>
    );
};

export default HostBoothPage;