import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Spin } from 'antd';
import {
    selectItemCount,
    selectLoading
} from 'store/order';

export const ItemCount: React.FC = () => {
    //const dispatch = useAppDispatch();

    const itemCount: number = useAppSelector(selectItemCount);

    const loading: boolean = useAppSelector(selectLoading);

    const itemCountLabel = () => {
        if (loading) {
            return <React.Fragment> <Spin /> Loading...</React.Fragment>;
        }
        switch (itemCount) {
            case 0:
                return <React.Fragment>No items found</React.Fragment>;
            case 1:
                return <React.Fragment>1 item.</React.Fragment>;
            default:
                return <React.Fragment>{`${itemCount} items.`}</React.Fragment>;
        }
    }
    return (
        <React.Fragment>
            {itemCountLabel()}
        </React.Fragment>
    );
}


