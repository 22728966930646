import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    Button,
    Badge,
    notification,
    Menu,
    Dropdown
} from 'antd';
import {
    BellTwoTone,
    SettingTwoTone
} from '@ant-design/icons';

import history from 'store/history';
import {
    selectIsLoaded,
    selectShow,
    selectOptions,
} from 'store/order';
import {
    logout,
    selectUser,
    selectAnnouncements,
    selectAnnouncementNotification
} from 'store/user';
import { Link, useLocation } from 'react-router-dom';
import {
    OrderOption,
    User,
    Announcement,
} from 'model/index';
import useSound from 'use-sound';

import useWindowDimensions from 'components/hooks/useWindowDimensions';

import { useAppState } from 'components/layout/AppStateProvider';

const incomingMessageSound = require('asset/sounds/notification.mp3');

const HeaderComponent: React.FC = () => {

    const dispatch = useAppDispatch();


    const isLoaded = useAppSelector(selectIsLoaded);

    const show = useAppSelector(selectShow);

    const user: User = useAppSelector(selectUser);

    const [play] = useSound(incomingMessageSound);

    const orderOptions: OrderOption = useAppSelector(selectOptions);

    const location = useLocation();

    //const userSettings: UserSettings = useAppSelector(selectSettings);

    const { height, width } = useWindowDimensions();

    const logoutOnClick = () => {
        console.log("LOGOUT");
        dispatch(logout());
        history.push('/');
    };

    const { showSettings } = useAppState();

    const announcementNotification: number = useAppSelector(selectAnnouncementNotification);

    const announcements: Announcement[] = useAppSelector(selectAnnouncements);

    React.useEffect(() => {
        if (location.pathname != "/announcements") {
            if (announcementNotification > 0) {
                play();
                const key = `open${Date.now()}`;
                notification.open({
                    key: key,
                    message: "New Announcement",
                    type: "info",
                    icon: <BellTwoTone />,
                    description: <React.Fragment>
                        <Button type="link" onClick={() => {
                            history.push('/announcements');
                            notification.destroy(key);
                        }
                        } style={{ fontSize: 16, padding: 0 }}>
                            Click here to view
                        </Button>
                    </React.Fragment>
                });
            }
        }
    }, [announcementNotification]);

    const announcementsOnClick = () => {
        history.push('/announcements');
    };


    const settingsOnClick = () => {
        showSettings();
    };

    const userName = () => {
        let title: string = process.env.NODE_ENV === 'development' ? user.id : user.company;
        let companyName: string = user.company;
        if (width < 1100) {
            companyName = user.company.length < 15 ? user.company : user.company.substr(0, 15) + "...";
        }
        return <React.Fragment><span id="customer-name" title={title}> {companyName} [{user.accountNo}]</span><span className="separator"> | </span> </React.Fragment>;
    };

    const showTitle = () => {
        let title: string = show.title;
        if (width < 1100) {
            title = show.title < 50 ? show.title : show.title.substr(0, 50) + "...";
        }
        return <span>{title}</span>;
    };



    return (
        <div id="order-show-info">
            <div className="logo">
                {isLoaded &&
                    <div id="company-logo">
                        <Link to="/order">
                            <img src={show.logo} alt={show.title} />
                            {showTitle()}
                        </Link>
                    </div>
                }
            </div>
            <div className="info">
                {(isLoaded && user) &&
                    userName()
                }
                {orderOptions.announcements &&
                    <React.Fragment>
                        <Badge count={announcements.length} offset={[-8, 5]}>
                            <Button type="link"
                                onClick={announcementsOnClick}
                                icon={<BellTwoTone />}
                                title="Announcements">
                            </Button>
                        </Badge>
                        <span className="separator"> | </span>
                    </React.Fragment>
                }
                <Button type="link"
                    onClick={settingsOnClick}
                    icon={<SettingTwoTone />}
                    title="Settings">
                </Button>
                <span className="separator"> | </span>
                <Button type="link" onClick={logoutOnClick}>
                    Logout
                </Button>
            </div>
        </div >
    );
}

export const Header = React.memo(HeaderComponent);
