import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Layout, Button } from 'antd';
const { Header, Content } = Layout;
import { Link } from 'react-router-dom';
import {
    selectLocations,
    selectCustomer,
    selectShow,
    selectOptions,
    resetAllFilters
} from 'store/order';
import {
    Customer,
    Show,
    OrderOption
} from 'model/index';
import history from 'store/history';


const OrderSummaryPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const customer: Customer = useAppSelector(selectCustomer);

    const locations: Customer[] = useAppSelector(selectLocations);

    const show: Show = useAppSelector(selectShow);

    const [html, setHtml] = React.useState("");

    const printSummaryUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/view-summary/${customer.customerId}`;

    const printDetailsUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/print-details/${customer.customerId}`;

    const printDetailsByLocationUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/print-by-location/${customer.customerId}`;

    const exportOrderUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/export/${customer.customerId}`;

    const entryStatusUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/entry/${customer.customerId}`;

    const orderOptions: OrderOption = useAppSelector(selectOptions);

    const createMarkup = (content: string) => {
        return { __html: content };
    }

    React.useEffect(() => {
        document.title = "Order Summary";
        fetch(printSummaryUrl)
            .then((response) => response.text())
            .then((data) => setHtml(data));
    }, []);

    const orderOnClick = () => {
        dispatch(resetAllFilters());
        history.push('/order');
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', minHeight: 40, padding: 0, }}>
                <h1 className="page-header">
                    Order Summary
                </h1>
            </Header>
            <Content style={{ marginTop: 60, overflowY: 'auto' }}>
                <div style={{ padding: 10 }}>
                    <div>
                        <Button onClick={orderOnClick} className="ant-btn">
                            Edit Order
                        </Button>
                        <Button
                            target="_blank"
                            href={printSummaryUrl}>
                            Print Summary
                        </Button>
                        <Button target="_blank"
                            href={printDetailsUrl}>
                            Print Order
                        </Button>
                        {locations.length > 0 && <Button target="_blank" href={printDetailsByLocationUrl}>Print Order By Location</Button>}
                        <Button target="_blank"
                            href={exportOrderUrl}>
                            Export Order (xlsx)
                        </Button>
                        {orderOptions.entryStatus && <Button target="_blank" href={entryStatusUrl}>Entry Status</Button>}
                    </div>
                    <div dangerouslySetInnerHTML={createMarkup(html)}></div>
                </div>
            </Content>
        </Layout>
    );
}


export default OrderSummaryPage;