import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Vendor } from 'model';
import {
    filterVendor,
} from 'store/order';
import { Communication } from 'components/vendor/Communication';
import history from 'store/history';
import styles from './Item.module.css';
interface Props {
    vendor: Vendor
}

export const VendorItem: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const image = props.vendor.logo ? <img src={props.vendor.logo} loading="lazy" /> : <span />;
    const orderOnClick = () => {
        dispatch(filterVendor(props.vendor));
        history.push('/order');
    };
    return (
        <div className={styles.item}>
            <div className={styles.logo} >
                {image}
            </div>
            <div className={styles.meta} >
                <div className={styles.title}>
                    <div className={styles.vendor} onClick={orderOnClick}>
                        {props.vendor.vendorName}
                        {props.vendor.category &&
                            <div className={styles.category}>{props.vendor.category}</div>
                        }
                    </div>
                    {props.vendor.brokerName &&
                        <div className={styles.broker}>{props.vendor.brokerName}</div>
                    }
                </div>
                <Communication manufacturer={props.vendor} showInfo={false} />
            </div>
        </div>
    );
};

