import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button, Spin, Empty } from 'antd';
import {
    selectBooth,
    selectVendors,
    selectBrokers
} from 'store/order';
import {
    MeetingScheduleSpot,
    MeetingScheduleDay,
    VirtualBooth,
    Vendor,
    Broker
} from 'model/index';
import {
    ClockCircleOutlined,
} from '@ant-design/icons';
import { Communication } from 'components/vendor/Communication';

interface Props {
    loadding: boolean,
    availableSpots: MeetingScheduleDay[],
    updateSchedule: any
}

import { MeetingTime } from 'components/common/MeetingTime';
import { DefaultGuid } from '../../utility';

import { BrokerVendors } from 'components/vendor/BrokerVendors';


export const AvailableSpots: React.FC<Props> = (props) => {

    const selectedBooth: VirtualBooth = useAppSelector(selectBooth);

    const vendors: Vendor[] = useAppSelector(selectVendors);

    const brokers: Broker[] = useAppSelector(selectBrokers);

    const meetingScheduleSpotOnClick = (meetingScheduleSpot: MeetingScheduleSpot) => {
        props.updateSchedule(meetingScheduleSpot);
    };

    const availableSpotsRender = (meetingScheduleDay: MeetingScheduleDay) => {
        let index: number = 0;
        return meetingScheduleDay.availableSpots.map((meetingScheduleSpot: MeetingScheduleSpot) => {
            let key: string = `${meetingScheduleDay.index}-${index}`;
            index++
            let disabled: boolean = !meetingScheduleSpot.isAvailable;
            let buttonType: any = meetingScheduleSpot.isSelected ? "primary" : "default";
            if (meetingScheduleSpot.isSelected) {
                disabled = false;
            }
            return (
                <Button key={key}
                    icon={<ClockCircleOutlined />}
                    type={buttonType}
                    shape="round"
                    size="large"
                    style={{ width: 210, marginBottom: 15, marginRight: 15 }}
                    disabled={disabled}
                    onClick={() => meetingScheduleSpotOnClick(meetingScheduleSpot)}
                >
                    <MeetingTime
                        startDate={meetingScheduleSpot.scheduleStart}
                        endDate={meetingScheduleSpot.scheduleEnd}
                        showDate={false}
                    />
                </Button>
            );
        });
    };

    const availableDaysRender = props.availableSpots.map((meetingScheduleDay: MeetingScheduleDay) => {
        return (
            <div key={meetingScheduleDay.index} className="day">
                <h4> {meetingScheduleDay.title}</h4>
                <div className="list">
                    {availableSpotsRender(meetingScheduleDay)}
                </div>
            </div>
        );
    });

    const displaySchedule = () => {
        if (props.availableSpots.length == 0) {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
        } else {
            return <Spin spinning={props.loadding}>
                {availableDaysRender}
            </Spin>;
        }
    };

    const getCommunication = () => {
        if (selectedBooth.entityId == DefaultGuid) {
            return < React.Fragment />;
        }
        const vendor = vendors.find((vendor: Vendor) => {
            return vendor.vendorId === selectedBooth.entityId;
        });
        //console.log("Vendor:", vendor);
        if (vendor) {
            return <Communication manufacturer={vendor} showInfo={false} />;
        } else {
            const broker = brokers.find((broker: Broker) => {
                return broker.brokerId === selectedBooth.entityId;
            });
            if (broker) {
                return <React.Fragment>
                    <Communication manufacturer={broker} showInfo={false} />
                    {broker.vendors &&
                        <BrokerVendors vendors={broker.vendors} />
                    }
                </React.Fragment>;
            }
        }
        return < React.Fragment />;
    };

    return (
        <div className="available-times">
            <div className="header">
                {(selectedBooth.logo != null && selectedBooth.logo.length > 0) &&
                    <img className="vendor-logo" src={selectedBooth.logo} />
                }
                <h2>{selectedBooth.name}</h2>
                <div>
                    {getCommunication()}
                </div>
            </div>
            <h3>Available Meeting Times </h3>
            {displaySchedule()}
        </div>
    );
};
